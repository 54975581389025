import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// Type
import {
  ProfileTabType,
  ProfileTabIndividual,
  ProfileTabCorporate,
} from 'components/organisms/tabBar/TabBarProfile/ProfileTabType.type';

export interface SwitchProfileContentLayoutProps {
  children: React.ReactElement[];
  tab: ProfileTabType;
}

const SwitchProfileContentLayout: FC<SwitchProfileContentLayoutProps> = ({
  children,
  tab,
}: SwitchProfileContentLayoutProps) => {
  const elements = [
    'basicInformation',
    'personalInformation',
    'corporateInformation',
  ];
  const [
    basicInformation,
    personalInformation,
    corporateInformation,
  ] = getElementsFromKeys(children, elements);

  return (
    <>
      {tab === ProfileTabIndividual.basicInformation && basicInformation}
      {tab === ProfileTabIndividual.personalInformation && personalInformation}
      {tab === ProfileTabCorporate.corporateInformation && corporateInformation}
    </>
  );
};
export default SwitchProfileContentLayout;
