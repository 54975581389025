import color from 'components/atoms/utils/const/color';
import fontWeight from 'components/atoms/utils/const/fontWeight';

export const TitleStyle = {
  fontSize: 27,
  color: color.text.primary,
  fontWeight: fontWeight.medium,
};

export const TitleBoxStyle = {
  backgroundColor: color.background.ghostWhite,
};

export const LabeledTextBoxStyle = {
  borderBottom: `${1 / 16}rem solid ${color.border.lightBlue}`,
};

export const LabelTextStyle = {
  fontSize: 22,
  color: color.text.primary,
  fontWeight: fontWeight.bold,
};

export const LabeledTextStyle = {
  label: LabelTextStyle,
  value: {
    fontSize: 22,
    color: color.text.zambezi,
    fontWeight: fontWeight.book,
  },
  labelWidth: `${4000 / 16}rem`,
};

export const LinkTextStyle = {
  fontSize: 22,
  color: color.text.summerSky,
  fontWeight: fontWeight.regular,
  textDecoration: 'underline',
};
