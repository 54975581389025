export enum SettingMenuType {
  profile = 'profile',
  twoFactorAuthAppEdit = 'twoFactorAuthAppEdit',
  emailEdit = 'emailEdit',
  passwordEdit = 'passwordEdit',
  shippingAddressEdit = 'shippingAddressEdit',
  consentForm = 'consentForm',
  corporateAddressEdit = 'corporateAddressEdit',
  addressEdit = 'addressEdit',
}

export const VerifiedUserSettingMenuType = [
  SettingMenuType.profile,
  SettingMenuType.twoFactorAuthAppEdit,
  SettingMenuType.emailEdit,
  SettingMenuType.passwordEdit,
  SettingMenuType.consentForm,
  SettingMenuType.addressEdit,
];

export const VerifiedCorporateUserSettingMenuType = [
  SettingMenuType.profile,
  SettingMenuType.twoFactorAuthAppEdit,
  SettingMenuType.emailEdit,
  SettingMenuType.passwordEdit,
  SettingMenuType.consentForm,
  SettingMenuType.corporateAddressEdit,
];

export const UnverifiedUserSettingMenuType = [
  SettingMenuType.emailEdit,
  SettingMenuType.passwordEdit,
];
