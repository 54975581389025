import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Type
import ReduxActionType, { AppActionType } from '../actions/action.type';
import RootActionType from '../constants/root.constant';

// Reducer
import counterReducer from './counterReducer';
import loginUserReducer from './loginUserReducer';
import debitCardV2sReducer from './debitCardV2sReducer';
import debitCardRenewalRequestsReducer from './debitCardRenewalRequestsReducer';
import debitCardRenewalRequestCherryVisa01sReducer from './debitCardRenewalRequestCherryVisa01sReducer';
import debitCardRenewalCancelRequestsReducer from './debitCardRenewalCancelRequestsReducer';
import registeredAddressEditRequestsReducer from './registeredAddressEditRequestsReducer';
import debitCardRenewalCancelRequestCherryVisa01sReducer from './debitCardRenewalCancelRequestCherryVisa01sReducer';
import manualsReducer from './manualsReducer';
import savingsAccountsReducer from './savingsAccountsReducer';
import signInUserReducer from './signInUserReducer';
import tmpLoginUserReducer from './tmpLoginUserReducer';
import testReducer from './testReducer';

const persistConfig = {
  key: 'root',
  storage,
};

const appReducer = combineReducers({
  counterStore: counterReducer,
  debitCardV2sStore: debitCardV2sReducer,
  debitCardRenewalRequestsStore: debitCardRenewalRequestsReducer,
  debitCardRenewalRequestCherryVisa01sStore: debitCardRenewalRequestCherryVisa01sReducer,
  debitCardRenewalCancelRequestsStore: debitCardRenewalCancelRequestsReducer,
  debitCardRenewalCancelRequestCherryVisa01sStore: debitCardRenewalCancelRequestCherryVisa01sReducer,
  registeredAddressEditRequestsStore: registeredAddressEditRequestsReducer,
  savingsAccountsStore: savingsAccountsReducer,
  manualsStore: manualsReducer,
  loginUserStore: loginUserReducer,
  signInUserStore: signInUserReducer,
  testStore: testReducer,
  tmpLoginUserStore: tmpLoginUserReducer,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: ReduxActionType) => {
  return appReducer(
    action.type === RootActionType.INITIALIZE_STATE ? undefined : state,

    action as AppActionType,
  );
};

export default persistReducer(persistConfig, rootReducer);
