import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Style
import { NavigationTextStyle } from 'components/organisms/header/HeaderContentTitle/settings/HeaderRegisteredAddressEditPending/style';

// Component
import { PageTitle } from 'components/atoms/headLine/HeadLine';
import Breadcrumb from 'components/molecules/link/Breadcrumb';
import HeaderContentTitleLayout from 'components/organisms/header/HeaderContentTitle/HeaderContentTitleLayout';

const ORGANISM_ID = `organisms.header.userSettings.HeaderRegisteredAddressEditPending`;

const HeaderRegisteredAddressEditPending: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <HeaderContentTitleLayout>
      <PageTitle key="left">{t(`${ORGANISM_ID}.title`)}</PageTitle>
      <Breadcrumb
        key="right"
        onClick={() => {
          history.go(0);
        }}
        iconSize={10}
        marginBetween={13}
        text={t(`atoms.link.back`)}
        theme={{ text: NavigationTextStyle }}
      />
    </HeaderContentTitleLayout>
  );
};

export default HeaderRegisteredAddressEditPending;
