// Type
import { ProofOfResidencyProps } from 'value/verification/kycRequest/KycRequestContent/ProofOfResidency/ProofOfResidency.type';
import { RegisteredAddressResidentialEditRequestProps } from 'domain/verification/registeredAddressEditRequest/RegisteredAddressResidentialEditRequest/RegisteredAddressResidentialEditRequest.type';

// DomainObject
import RegisteredAddressEditRequest from 'domain/verification/registeredAddressEditRequest/RegisteredAddressEditRequest/RegisteredAddressEditRequest';

// ValueObject
import ProofOfResidency from 'value/verification/kycRequest/KycRequestContent/ProofOfResidency/ProofOfResidency';
import ProofOfResidencyFactory from 'value/verification/kycRequest/KycRequestContent/ProofOfResidency/ProofOfResidencyFactory';

class RegisteredAddressResidentialEditRequest extends RegisteredAddressEditRequest {
  private proofOfResidency: ProofOfResidency;

  constructor(id: string, props: RegisteredAddressResidentialEditRequestProps) {
    super(id, props);
    this.proofOfResidency = ProofOfResidencyFactory.create({
      country: props.country,
      addressLine1: props.addressLine1,
      addressLine2: props.addressLine2,
      city: props.city,
      state: props.state,
      postalCode: props.postalCode,
      status: props.status,
      document: props.document,
    } as ProofOfResidencyProps);
  }

  public getProofOfResidency = () => this.proofOfResidency;
}
export default RegisteredAddressResidentialEditRequest;
