import React, { FC } from 'react';

// Component
import PropertyListCorporateInformation from 'components/organisms/propertyList/user/PropertyListCorporateInformation';
import PropertyListCorporateRegistry from 'components/organisms/propertyList/user/PropertyListCorporateRegistry';
import PropertyListCorporateAddressProof from 'components/organisms/propertyList/user/PropertyListCorporateAddressProof';
import ProfilePersonalInformationTemplateLayout from './ProfileCorporateInformationTemplateLayout';

const ProfileCorporateInformationTemplate: FC = () => {
  return (
    <ProfilePersonalInformationTemplateLayout>
      <PropertyListCorporateInformation key="corporateInformation" />
      <PropertyListCorporateRegistry key="corporateRegistry" />
      <PropertyListCorporateAddressProof key="corporateAddress" />
    </ProfilePersonalInformationTemplateLayout>
  );
};
export default ProfileCorporateInformationTemplate;
