// PREFIX
export const ROUTE_PREFIX_ASSET = '/assets';
export const ROUTE_PREFIX_CRYPTO_CURRENCIES = '/cryptocurrencies';
export const ROUTE_PREFIX_SAVINGS_ACCOUNT = '/savings-account';
export const ROUTE_PREFIX_CONTRACT_TIME_DEPOSIT = '/contract-time-deposit';
export const ROUTE_PREFIX_CONTRACT_TIME_DEPOSIT_RENEWAL_RESERVATION = `${ROUTE_PREFIX_CONTRACT_TIME_DEPOSIT}/reservation`;
export const ROUTE_PREFIX_DEBIT_CARD = '/debit-card';
export const ROUTE_PREFIX_TRANSFER = '/transfer';
export const ROUTE_PREFIX_JOINT_ACCOUNT = '/joint-account';
export const ROUTE_PREFIX_INVITATION = '/invitation';
export const ROUTE_PREFIX_VERIFICATION = '/verification';
export const ROUTE_PREFIX_DEBIT_CARD_REQUEST = '/debit-card-request';
export const ROUTE_PREFIX_DEBIT_CARD_REQUEST_CHERRY_VISA_01 =
  '/debit-card-request-cherry-visa-01';
export const ROUTE_PREFIX_DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01 =
  '/debit-card-renewal-request-cherry-visa-01';
export const ROUTE_PREFIX_DEBIT_CARD_RENEWAL_CANCEL_REQUEST_CHERRY_VISA_01 =
  '/debit-card-renewal-cancel-request-cherry-visa-01';
export const ROUTE_PREFIX_SETTINGS = '/settings';
export const ROUTE_PREFIX_MANUAL = '/manual';
export const ROUTE_PREFIX_ERROR = '/error';
export const ROUTE_PREFIX_HOME = '/';

// FRONT (Home)
export const ROUTE_ABOUT = '/about';
export const ROUTE_HOME = '/';
export const ROUTE_AUTH_ERROR = `${ROUTE_PREFIX_ERROR}/auth-error`;
export const ROUTE_MAINTENANCE = `/maintenance`;
export const ROUTE_RESET_AUTHENTICATOR = '/reset/authenticator';

// FRONT (AUTHENTICATION)
export const ROUTE_FORGOT_PASSWORD = '/forgotPassword';
export const ROUTE_RESET_PASSWORD = '/resetPassword';
export const ROUTE_REGISTER = '/register';
export const ROUTE_SIGN_IN = '/signin';
export const ROUTE_SIGN_UP = '/signup';

// FRONT (RE_REGISTRATION)
export const ROUTE_REREGISTRATION = '/reregistration';
export const ROUTE_RE_SIGN_UP = '/re-signup';

// FRONT (PDF FILES)
export const ROUTE_BROCHURE = '/brochure';
export const ROUTE_PRIVACY_POLICY = '/privacy-policy';
export const ROUTE_TERMS_OF_USE = '/terms-of-use';

// ASSET_SUMMARY
export const ROUTE_ASSET_SUMMARY = `${ROUTE_PREFIX_ASSET}`;

// CRYPT_CURRENCY
export const ROUTE_CRYPTO_CURRENCY_WALLET = `${ROUTE_PREFIX_CRYPTO_CURRENCIES}/wallet`;
export const ROUTE_CRYPTO_CURRENCY_TRADE = `${ROUTE_PREFIX_CRYPTO_CURRENCIES}/trade`;
export const ROUTE_CRYPTO_CURRENCY_DEPOSITS_WITHDRAWALS = `${ROUTE_PREFIX_CRYPTO_CURRENCIES}/deposits-withdrawals`;

// DASHBOARD
export const ROUTE_DASHBOARD = '/dashboard';

// DEBIT_CARD
export const ROUTE_DEBIT_CARD = `${ROUTE_PREFIX_DEBIT_CARD}`;
export const ROUTE_DEBIT_CARD_HISTORY = `${ROUTE_PREFIX_DEBIT_CARD}/history`;

// DEBIT_CARD_REQUEST
export const ROUTE_DEBIT_CARD_REQUEST = ROUTE_PREFIX_DEBIT_CARD_REQUEST;
export const ROUTE_DEBIT_CARD_REQUEST_CHERRY_VISA_01_STATUS = `${ROUTE_PREFIX_DEBIT_CARD_REQUEST_CHERRY_VISA_01}/status`;
export const ROUTE_CHERRY_VISA_01_PROOF_OF_IDENTITY = `${ROUTE_PREFIX_DEBIT_CARD_REQUEST_CHERRY_VISA_01}/proof-of-identity`;
export const ROUTE_CHERRY_VISA_01_PROOF_OF_RECEIPT = `${ROUTE_PREFIX_DEBIT_CARD_REQUEST_CHERRY_VISA_01}/proof-of-receipt`;

// DEBIT_CARD_RENEWAL_REQUEST
export const ROUTE_DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_STATUS = `${ROUTE_PREFIX_DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01}/status/:id`;
export const ROUTE_DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_PROOF_OF_RECEIPT = `${ROUTE_PREFIX_DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01}/proof-of-receipt/:id`;

// DEBIT_CARD_RENEWAL_CANCEL_REQUEST
export const ROUTE_DEBIT_CARD_RENEWAL_CANCEL_REQUEST_CHERRY_VISA_01_STATUS = `${ROUTE_PREFIX_DEBIT_CARD_RENEWAL_CANCEL_REQUEST_CHERRY_VISA_01}/status/:id`;

// KYC.kyc
export const ROUTE_KYC_REQUEST = ROUTE_PREFIX_VERIFICATION;
export const ROUTE_SELECT_USER_TYPE = `${ROUTE_PREFIX_VERIFICATION}/select-user-type`;
export const ROUTE_KYC_STATUS = `${ROUTE_KYC_REQUEST}/kyc-status`;
export const ROUTE_CORPORATE_INFORMATION = `${ROUTE_KYC_REQUEST}/corporate`;
export const ROUTE_CORPORATE_ADDRESS = `${ROUTE_KYC_REQUEST}/corporate-address`;
export const ROUTE_PERSONAL_INFORMATION = `${ROUTE_KYC_REQUEST}/personal-information`;
export const ROUTE_PROOF_OF_RESIDENCY = `${ROUTE_KYC_REQUEST}/proof-of-residency`;

// KYC.consentForm
export const ROUTE_CONSENT_FORM = `${ROUTE_KYC_REQUEST}/consent-form`;
export const ROUTE_TWO_FACTOR_AUTH_APP_SETTINGS = `${ROUTE_KYC_REQUEST}/two-factor-auth-app-settings`;
export const ROUTE_GOOGLE_AUTHENTICATOR_GOOGLE_PLAY = `${ROUTE_KYC_REQUEST}/google-authenticator-google-play`;
export const ROUTE_GOOGLE_AUTHENTICATOR_APP_STORE = `${ROUTE_KYC_REQUEST}/google-authenticator-app-store`;

// SAVINGS_ACCOUNT
export const ROUTE_SAVINGS_ACCOUNT = `${ROUTE_PREFIX_SAVINGS_ACCOUNT}`;
export const ROUTE_SAVINGS_ACCOUNT_HISTORY = `${ROUTE_PREFIX_SAVINGS_ACCOUNT}/history`;

// Invitation Codes
export const ROUTE_INVITATION_CODES = `${ROUTE_PREFIX_INVITATION}/codes`;
export const ROUTE_INVITATIONS = `${ROUTE_PREFIX_INVITATION}/invitations`;
export const ROUTE_REFERRAL_RESULTS = `${ROUTE_PREFIX_INVITATION}/referrals`;

// JOINT ACCOUNT
export const ROUTE_JOINT_ACCOUNT = `${ROUTE_PREFIX_JOINT_ACCOUNT}`;
export const ROUTE_JOINT_ACCOUNT_ADD = `${ROUTE_PREFIX_JOINT_ACCOUNT}/create`;
export const ROUTE_JOINT_ACCOUNT_CONFIRMATION_REQUEST = `${ROUTE_PREFIX_JOINT_ACCOUNT}/confirmation`;

// TIME_DEPOSIT
export const ROUTE_TIME_DEPOSIT = `${ROUTE_PREFIX_CONTRACT_TIME_DEPOSIT}`;
export const ROUTE_TIME_DEPOSIT_ADD = `${ROUTE_PREFIX_CONTRACT_TIME_DEPOSIT}/create`;
export const ROUTE_TIME_DEPOSIT_RENEWAL_RESERVATION_ADD = `${ROUTE_PREFIX_CONTRACT_TIME_DEPOSIT_RENEWAL_RESERVATION}/create`;
export const ROUTE_TIME_DEPOSIT_RENEWAL_RESERVATION_EDIT = `${ROUTE_PREFIX_CONTRACT_TIME_DEPOSIT_RENEWAL_RESERVATION}/update`;
export const ROUTE_TIME_DEPOSIT_RENEWAL_RESERVATION_DELETE = `${ROUTE_PREFIX_CONTRACT_TIME_DEPOSIT_RENEWAL_RESERVATION}/delete`;

// TRANSFER
export const ROUTE_TRANSFER_SAVING_ACCOUNT = `${ROUTE_PREFIX_TRANSFER}/saving-account`;
export const ROUTE_TRANSFER_DEBIT_CARD = `${ROUTE_PREFIX_TRANSFER}/debit-card`;

// Settings
export const ROUTE_SETTINGS = '/settings';
export const ROUTE_SETTINGS_PROFILE = `${ROUTE_PREFIX_SETTINGS}/profile`;
export const ROUTE_SETTINGS_TWO_FACTOR_AUTH_APP_EDIT = `${ROUTE_PREFIX_SETTINGS}/two-factor-auth-app`;
export const ROUTE_SETTINGS_EMAIL_EDIT = `${ROUTE_PREFIX_SETTINGS}/email`;
export const ROUTE_SETTINGS_PASSWORD_EDIT = `${ROUTE_PREFIX_SETTINGS}/password`;
export const ROUTE_SETTINGS_SHIPPING_ADDRESS_EDIT = `${ROUTE_PREFIX_SETTINGS}/shipping-address`;
export const ROUTE_SETTINGS_CONSENT_FORM = `${ROUTE_PREFIX_SETTINGS}/consent-form`;
export const ROUTE_SETTINGS_ADDRESS_EDIT = `${ROUTE_PREFIX_SETTINGS}/address-edit`;
export const ROUTE_SETTINGS_CORPORATE_ADDRESS_EDIT = `${ROUTE_PREFIX_SETTINGS}/corporate-address-edit`;

// Manual
export const ROUTE_MANUAL = `${ROUTE_PREFIX_MANUAL}`;

// External
export const URL_GOOGLE_AUTHENTICATOR_GOOGLE_PLAY =
  ' https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2';
export const URL_GOOGLE_AUTHENTICATOR_APP_STORE =
  'https://apps.apple.com/app/google-authenticator/id388497605';
export const PATH_CONSENT_FORM =
  'document/Terms & Conditions Governing Electronic Services (14Sep2021)(E)Ver1.3.pdf';
export const PATH_TERMS_AND_CONDITIONS =
  'https://storage.googleapis.com/forbes-private-bank-secondsys/front/bank_transaction_terms_and_conditions_fpbm.pdf';
export const PATH_COMPANY_BROCHURE =
  'https://storage.googleapis.com/forbes-private-bank-secondsys/front/company_brochure.pdf';
export const PATH_PRIVACY_POLICY =
  'https://storage.googleapis.com/forbes-private-bank-secondsys/front/privacy_policy_fpbm.pdf';
export const PATH_DEBIT_CARD_FEE_FILE =
  'document/VISA_Debit_Metal_Card_related_fees_and_othersRenewal.pdf';

// TOP_PAGE
export const ROUTE_TOP_PAGE_UNVERIFIED_USER = ROUTE_KYC_REQUEST;
export const ROUTE_TOP_PAGE_VERIFIED_USER = ROUTE_ASSET_SUMMARY;
