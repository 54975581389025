import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// Style
import { TitleBoxStyle } from 'components/organisms/propertyList/user/PropertyListCustomerDetail/style';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from 'components/atoms/utils/layout/Box/FlexBox';
import Margin from 'components/atoms/utils/layout/Margin';

// Common
import {
  commonPropsLabeledTextBox,
  HALF_WIDTH_PROPERTY_LABELED_TEXT,
} from 'components/organisms/propertyList/user/PropertyListCustomerDetail/PropertyListCustomerDetail.type';

export interface PropertyListCorporateAddressProofLayoutProps {
  children: React.ReactElement[];
}

const PropertyListCorporateAddressProofLayout: FC<PropertyListCorporateAddressProofLayoutProps> = ({
  children,
}: PropertyListCorporateAddressProofLayoutProps) => {
  const elements = ['title', 'documentType', 'proofOfAddress'];
  const [title, documentType, proofOfAddress] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxColumn height="auto">
      <FlexBoxRow
        alignItems="center"
        paddingLeft="15"
        height={50}
        theme={TitleBoxStyle}
      >
        {title}
      </FlexBoxRow>
      <FlexBoxRow
        {...commonPropsLabeledTextBox}
        width={HALF_WIDTH_PROPERTY_LABELED_TEXT}
      >
        {documentType}
      </FlexBoxRow>
      <Margin top={30} />
      {proofOfAddress}
    </FlexBoxColumn>
  );
};
export default PropertyListCorporateAddressProofLayout;
