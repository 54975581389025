// React
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import ModalTransferDebitCardTransactionCancelContext from 'components/organisms/modal/DebitCardRenewalRequestCherryVisa01CancelConfirmModal/DebitCardRenewalRequestCherryVisa01CancelConfirmModalContext';
import DebitCardRenewalRequestCherryVisa01CancelConfirmContext from 'enhancers/useCase/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01CancelConfirmProvider/DebitCardRenewalRequestCherryVisa01CancelConfirmContext';

// Constant
import { IS_LOADING } from 'config/constants/requestState';
import { ROUTE_DEBIT_CARD_RENEWAL_CANCEL_REQUEST_CHERRY_VISA_01_STATUS } from 'config/constants/routing';

// Style
import {
  ButtonCancelCardStyle,
  CancelButtonTextStyle,
  contentStyle,
  MessageStyle,
  overlayStyle,
  TitleStyle,
} from 'components/organisms/modal/DebitCardRenewalRequestCherryVisa01CancelConfirmModal/style';

// Components
import { TextPrimary, TextSecondary } from 'components/atoms/text2/Text2';
import {
  CancelButton,
  DefaultButtonTextStyle,
} from 'components/atoms/button/Button2';
import DangerButton from 'components/atoms/button/Button2/DangerButton';
import ModalTransferDebitCardTransactionCancelLayout from 'components/organisms/modal/DebitCardRenewalRequestCherryVisa01CancelConfirmModal/DebitCardRenewalRequestCherryVisa01CancelConfirmModalLayout';

const ORGANISM_ID = `organisms.modal.DebitCardRenewalRequestCherryVisa01CancelConfirmModal`;

interface DebitCardRenewalRequestCherryVisa01CancelConfirmModalProps {
  // eslint-disable-next-line
  history: any;
}

const DebitCardRenewalRequestCherryVisa01CancelConfirmModal = ({
  history,
}: DebitCardRenewalRequestCherryVisa01CancelConfirmModalProps) => {
  const { t } = useTranslation();
  const { hideModal } = useContext(
    ModalTransferDebitCardTransactionCancelContext,
  );

  const { state, cancel } = useContext(
    DebitCardRenewalRequestCherryVisa01CancelConfirmContext,
  );

  return (
    <ReactModal
      isOpen={true}
      style={{ content: contentStyle, overlay: overlayStyle }}
      ariaHideApp={false}
    >
      <ModalTransferDebitCardTransactionCancelLayout>
        <TextPrimary key="title" theme={TitleStyle}>
          {t(`${ORGANISM_ID}.title`)}
        </TextPrimary>
        <TextSecondary key="message" theme={MessageStyle}>
          {t(`${ORGANISM_ID}.message`)}
        </TextSecondary>
        <DangerButton
          key="submitButton"
          isLoading={state === IS_LOADING}
          theme={ButtonCancelCardStyle}
          height={60}
          width={200}
          sizeProgress={20}
          onClick={() => {
            cancel((debitCardRenewalCancelRequest) => {
              hideModal();
              history.push(
                ROUTE_DEBIT_CARD_RENEWAL_CANCEL_REQUEST_CHERRY_VISA_01_STATUS.replace(
                  ':id',
                  debitCardRenewalCancelRequest?.getId() ?? '',
                ),
              );
            });
          }}
        >
          <TextPrimary theme={DefaultButtonTextStyle}>
            {t(`atoms.button.cancelCard`)}
          </TextPrimary>
        </DangerButton>
        <CancelButton
          disabled={state === IS_LOADING}
          key="cancelButton"
          theme={{ borderRadius: 30 }}
          height={60}
          width={200}
          onClick={hideModal}
        >
          <TextPrimary theme={CancelButtonTextStyle}>
            {t(`atoms.button.Close`)}
          </TextPrimary>
        </CancelButton>
      </ModalTransferDebitCardTransactionCancelLayout>
    </ReactModal>
  );
};

export default DebitCardRenewalRequestCherryVisa01CancelConfirmModal;
