import React, { FC, useCallback, useContext, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  isValid,
  getDefaultValues,
} from 'components/organisms/form/settings/FormRegisteredAddressEditRequestAdd/FormCorporateAddress/helper';
import RegisteredAddressEditRequestCreateContext from 'enhancers/useCase/setting/registeredAddressEditRequest/RegisteredAddressEditRequestCreateProvider/RegisteredAddressEditRequestCreateContext';
import UserAuthorizedContext from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import CorporateAddressFileContext from 'enhancers/fileHandler/verification/kycRequest/CorporateAddressFileProvider/CorporateAddressFileContext';

// Type
import { FormInputCorporateAddress } from 'components/organisms/form/settings/FormRegisteredAddressEditRequestAdd/FormCorporateAddress/FormCorporateAddress.type';
import { RegisteredAddressEditRequestType } from 'types/RegisteredAddressEditRequest.type';

// Constant
import { IS_LOADING } from 'config/constants/requestState';

// Style
import { ErrorMessageStyle } from 'components/organisms/form/settings/FormRegisteredAddressEditRequestAdd/FormCorporateAddress/style';

// Component
import { TextSecondary } from 'components/atoms/text2/Text2';
import {
  SubmitButton,
  DefaultButtonTextStyle,
} from 'components/atoms/button/Button2';
import LabelSendIcon from 'components/atoms/button/ButtonLabel/LabelSendIcon';
import FormCorporateAddressInput from 'components/organisms/form/settings/FormRegisteredAddressEditRequestAdd/FormCorporateAddress/FormCorporateAddressInput';
import SelectDocumentType from 'components/organisms/form/settings/FormRegisteredAddressEditRequestAdd/FormCorporateAddress/SelectDocumentType';
import FormCorporateAddressProofUpload from 'components/organisms/form/settings/FormRegisteredAddressEditRequestAdd/FormCorporateAddress/FormCorporateAddressProofUpload';
import FormCorporateAddressLayout from 'components/organisms/form/settings/FormRegisteredAddressEditRequestAdd/FormCorporateAddress/FormCorporateAddressLayout';

// ValueObject
import CorporateAddress from 'value/verification/kycRequest/KycRequestContent/CorporateAddress/CorporateAddress';

const FORM_ID = `organisms.form.settings.FormCorporateAddress`;

export interface FormCorporateAddressProps {
  corporateAddress?: CorporateAddress;
  isUnderReview: boolean;
}

const FormCorporateAddress: FC<FormCorporateAddressProps> = ({
  corporateAddress,
  isUnderReview,
}: FormCorporateAddressProps) => {
  const { t } = useTranslation();

  // DomainObject
  const { userAuthorized } = useContext(UserAuthorizedContext);

  // FileHandler
  const { proofOfDocument1 } = useContext(CorporateAddressFileContext);

  // Method
  const { state, executeCreate } = useContext(
    RegisteredAddressEditRequestCreateContext,
  );

  /* *
   *
   *  Form
   *
   * */
  const methods = useForm<FormInputCorporateAddress>({
    mode: 'onChange',
  });
  const { errors, handleSubmit, reset, setValue, watch } = methods;

  /* *
   *
   *  DefaultValues
   *
   * */

  // Set Default Value from CorporateInformation
  useEffect(() => {
    if (corporateAddress && userAuthorized) {
      const defaultValues = getDefaultValues(corporateAddress);

      reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [corporateAddress, setValue]);

  /* *
   *
   *  Event
   *
   * */

  const onSubmit = useCallback(
    async (input: FormInputCorporateAddress) => {
      executeCreate(
        input,
        RegisteredAddressEditRequestType.corporateAddress,
        proofOfDocument1,
      );
      // eslint-disable-next-line
    }, [executeCreate, proofOfDocument1]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <FormCorporateAddressLayout isUnderReview={isUnderReview}>
          <FormCorporateAddressInput
            key="address"
            isUnderReview={isUnderReview}
          />
          <SelectDocumentType
            key="documentType"
            isUnderReview={isUnderReview}
          />
          <FormCorporateAddressProofUpload
            key="documents"
            corporateAddress={corporateAddress}
          />
          <TextSecondary key="message" theme={ErrorMessageStyle}>
            {t(`${FORM_ID}.message.underReview`)}
          </TextSecondary>
          <SubmitButton
            key="button"
            disabled={isUnderReview || !isValid(errors, watch)}
            isLoading={state === IS_LOADING}
            height={75}
            width={'100%'}
          >
            <LabelSendIcon
              iconSize={26}
              iconStyle={{ color: 'white' }}
              label={t(`atoms.button.submit`)}
              marginBetween={8}
              textStyle={DefaultButtonTextStyle}
            />
          </SubmitButton>
        </FormCorporateAddressLayout>
      </FormProvider>
    </form>
  );
};

export default FormCorporateAddress;
