import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// Lib
import { convertRem } from 'components/atoms/utils/helper/helper';

// Style
import { ImageContainer, IconContainer } from './style';

export interface ImageWithMagnifiedIconLayoutProps {
  children: React.ReactElement[];
  layout: {
    image: {
      width: string | number;
      height: string | number;
    };
  };
}

const ImageWithMagnifiedIconLayout: FC<ImageWithMagnifiedIconLayoutProps> = ({
  children,
  layout,
}: ImageWithMagnifiedIconLayoutProps) => {
  const elements = ['icon', 'image'];
  const [icon, image] = getElementsFromKeys(children, elements);

  return (
    <ImageContainer
      style={{
        width: convertRem(layout.image.width),
        height: convertRem(layout.image.height),
      }}
    >
      {image}
      <IconContainer>{icon}</IconContainer>
    </ImageContainer>
  );
};
export default ImageWithMagnifiedIconLayout;
