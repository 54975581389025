export enum Gender {
  male = 'male',
  female = 'female',
}

export enum KycStatusType {
  initial = 'initial',
  disabled = 'disabled',
  submitted = 'submitted',
  reviewing = 'reviewing',
  approved = 'approved',
  rejected = 'rejected',
}

export enum ProofOfResidencyDocumentType {
  driversLicense = "Driver's license",
  utilityBill = 'Utility bill',
  bankStatements = 'Bank statement',
  cardStatement = 'Card statement',
  certificateOfResidence = 'Certificate of residence',
}

export enum CorporateAddressDocumentType {
  utilityBill = 'Utility bill',
  bankStatements = 'Bank statement',
  taxInvoice = 'Tax invoice',
  cardStatement = 'Card statement',
  certificateOfGoodStanding = 'Certificate of Good Standing',
  certificateOfIncumbency = 'Certificate of Incumbency',
}

export enum KycStepType {
  corporateInformation = 'corporateInformation',
  corporateAddress = 'corporateAddress',
  personalInformation = 'personalInformation',
  proofOfResidency = 'proofOfResidency',
  waitingReview = 'waitingReview',
  consentForm = 'consentForm',
  twoFactorAuthenticationAppSettings = 'twoFactorAuthenticationAppSettings',
  waitingConsentFormReview = 'waitingConsentFormReview',
}

export enum KycRequestUploadFileType {
  corporateRegistry = 'corporateRegistry',
  proofOfCorporateAddress = 'proofOfCorporateAddress',
  passport = 'passport',
  selfie = 'selfie',
  proofOfResidency1 = 'proofOfResidency1',
  proofOfResidency2 = 'proofOfResidency2',
  consentForm = 'consentForm',
}

export const KycRequestUploadFileSuffix: {
  [key: string]: string;
} = {
  [KycRequestUploadFileType.corporateRegistry]: 'corporate-registry',
  [KycRequestUploadFileType.proofOfCorporateAddress]: 'corporate-address',
  [KycRequestUploadFileType.passport]: 'passport',
  [KycRequestUploadFileType.selfie]: 'selfie',
  [KycRequestUploadFileType.proofOfResidency1]: 'proof-of-residency1',
  [KycRequestUploadFileType.proofOfResidency2]: 'proof-of-residency2',
  [KycRequestUploadFileType.consentForm]: 'consentForm',
};
