import React, { FC } from 'react';

// Component
import PropertyListPersonalInformation from 'components/organisms/propertyList/user/PropertyListPersonalInformation';
import PropertyListResidentialInformation from 'components/organisms/propertyList/user/PropertyListResidentialInformation';
import PropertyListPassportImage from 'components/organisms/propertyList/user/PropertyListPassportImage';
import PropertyListResidentialProof from 'components/organisms/propertyList/user/PropertyListResidentialProof';
import ProfilePersonalInformationTemplateLayout from './ProfilePersonalInformationTemplateLayout';

const ProfilePersonalInformationTemplate: FC = () => {
  return (
    <ProfilePersonalInformationTemplateLayout>
      <PropertyListPersonalInformation key="personalInformation" />
      <PropertyListResidentialInformation key="residentialInformation" />
      <PropertyListPassportImage key="passport" />
      <PropertyListResidentialProof key="residentialProof" />
    </ProfilePersonalInformationTemplateLayout>
  );
};
export default ProfilePersonalInformationTemplate;
