import React, { FC } from 'react';

// Style
import { LabelStyle } from './style';

// DomainObject
import { TextPrimary } from '../../../../../../atoms/text2/Text2';
import CountrySelectBox from '../../../../../selectBox/selectBox/verification/CountrySelectBox';
import FormInputCountryLayout from './FormInputCountryLayout';

interface FormInputCountryProps {
  label: string;
  inputWidth: string | number;
  name: string;
  isDisabled?: boolean;
}

const FormInputCountry: FC<FormInputCountryProps> = ({
  label,
  inputWidth,
  name,
  isDisabled,
}: FormInputCountryProps) => {
  return (
    <FormInputCountryLayout inputWidth={inputWidth}>
      <TextPrimary key="label" theme={LabelStyle}>
        {label}
      </TextPrimary>
      <CountrySelectBox key="input" name={name} isDisabled={isDisabled} />
    </FormInputCountryLayout>
  );
};

export default FormInputCountry;
