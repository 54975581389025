// Types
import { DebitCardDepositV2Props } from './DebitCardDepositV2.type';
import { DebitCardDepositType } from '../../../../../../types/DebitCardTransaction.type';
import { TypeOfT } from '../../../../../../types/typeof/Translation.type';

// DomainObject
import DebitCardSpotTransactionV2 from '../../DebitCardSpotTransactionV2/DebitCardSpotTransactionV2/DebitCardSpotTransactionV2';
import DebitCardTransactionId from '../../../../../idManager/transactionId/DebitCardTransactionId/DebitCardTransactionId';

// Error
import InvalidDataFoundError from '../../../../../../utils/errors/InvalidDataFoundError';

class DebitCardDepositV2 extends DebitCardSpotTransactionV2 {
  private type: DebitCardDepositType;

  constructor(
    debitCardTransactionId: DebitCardTransactionId,
    props: DebitCardDepositV2Props,
  ) {
    super(debitCardTransactionId, props);
    this.type = props.type;
  }

  public getProps = () => ({
    ...super.getProps(),
    type: this.type,
  });

  public getCommandParameter = () => ({
    ...super.getCommandParameter(),
    type: this.type,
  });

  public createDescription = (t: TypeOfT) => {
    const TRANSLATION_ID =
      'transaction.debitCardTransaction.description.debitCardDeposit';

    if (this.type === DebitCardDepositType.chargeFromSavingsAccount) {
      const param =
        this.descriptionParameter.indexOf('-') > -1
          ? this.descriptionParameter
          : `${this.descriptionParameter.substr(
              0,
              3,
            )}-${this.descriptionParameter.substr(3, 7)}`;

      return t(`${TRANSLATION_ID}.${this.type}`, {
        param,
      });
    }

    // For Cherry deposit types, try to use translation if available
    if (this.type.startsWith('cherryDeposit')) {
      const translationKey = `transaction.debitCardTransaction.type.debitCardDeposit.${this.type}`;
      const translation = t(translationKey);
      
      // If translation key exists and returns a proper translation (not the key itself)
      if (translation && translation !== translationKey) {
        return translation;
      }
    }
    
    if (Object.keys(DebitCardDepositType).includes(this.type))
      return this.descriptionParameter;

    throw new InvalidDataFoundError(
      `Unknown debitCardDepositType ${this.type} is found at DebitCardDeposit.createDescription`,
    );
  };

  public getTransactionType = () => this.type;
}

export default DebitCardDepositV2;
