// Lib
import { startsWithHttp } from 'utils/helpers/stringHelper';
import {
  fileToBuffer,
  getExtensionFromFilePath,
} from 'utils/helpers/fileHelper';

// Type
import { RegisteredAddressEditRequestType } from 'types/RegisteredAddressEditRequest.type';
import { AddressDocumentType } from 'types/AddressDocumentType.type';

// Service
import StorageService from 'service/storage/StorageService';

// IRepository
import { FormInputCorporateAddress } from 'components/organisms/form/settings/FormRegisteredAddressEditRequestAdd/FormCorporateAddress/FormCorporateAddress.type';
import { FormInputProofOfResidency } from 'components/organisms/form/settings/FormRegisteredAddressEditRequestAdd/FormProofOfResidency/FormProofOfResidency.type';
import IRegisteredAddressEditRequestRepository from 'domain/verification/registeredAddressEditRequest/IRegisteredAddressEditRequestRepository';
import IStorageHandler from 'storage/IStorageHandler';

class RegisteredAddressEditRequestCreateUseCase {
  private registeredAddressEditRequestRepository: IRegisteredAddressEditRequestRepository;

  private storageService: StorageService;

  constructor(
    registeredAddressEditRequestRepository: IRegisteredAddressEditRequestRepository,
    storageHandler: IStorageHandler,
  ) {
    this.registeredAddressEditRequestRepository = registeredAddressEditRequestRepository;
    this.storageService = new StorageService(storageHandler);
  }

  private downloadDocument = async (path: string) => {
    if (startsWithHttp(path)) return this.storageService.download(path);
    return this.storageService.downloadTmpFile(path);
  };

  // Submit address
  public create = async (
    formInput: FormInputCorporateAddress | FormInputProofOfResidency,
    addressEditRequestType: RegisteredAddressEditRequestType,
    proofOfDocument1?: File,
    proofOfDocument2?: File,
  ) => {
    // Get fileContent
    const proofOfDocument1BeforeConvert =
      proofOfDocument1 ||
      (await this.downloadDocument(formInput.proofOfDocumentUrl1));

    const isDocumentTypeDriverLicense =
      formInput.documentType === AddressDocumentType.driverLicense;
    const proofOfDocument2BeforeConvert = isDocumentTypeDriverLicense
      ? proofOfDocument2 ||
        (formInput.proofOfDocumentUrl2
          ? await this.downloadDocument(formInput.proofOfDocumentUrl2)
          : undefined)
      : undefined;

    const proofOfDocumentFile1 = await fileToBuffer(
      proofOfDocument1BeforeConvert,
    );
    const proofOfDocumentFile2 =
      proofOfDocument2BeforeConvert &&
      (await fileToBuffer(proofOfDocument2BeforeConvert));

    const {
      country,
      city,
      postalCode,
      state: stateOfAddress,
      addressLine1,
      addressLine2,
      documentType,
    } = formInput;

    const input = {
      country,
      city,
      postalCode,
      state: stateOfAddress,
      addressLine1,
      addressLine2,
      documentType,
      addressEditRequestType,
      proofOfDocumentFile1,
      proofOfDocumentFileType1: `${getExtensionFromFilePath(
        proofOfDocument1BeforeConvert?.name,
      )}`,
      proofOfDocumentFile2,
      proofOfDocumentFileType2:
        proofOfDocument2BeforeConvert &&
        `${getExtensionFromFilePath(proofOfDocument2BeforeConvert.name)}`,
    };

    // Submit
    const output = await this.registeredAddressEditRequestRepository.create(
      input,
    );

    return output;
  };
}

export default RegisteredAddressEditRequestCreateUseCase;
