import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from 'components/atoms/utils/layout/Box/FlexBox';

// Common
import {
  commonPropsLabeledTextBox,
  HALF_WIDTH_PROPERTY_LABELED_TEXT,
} from 'components/organisms/propertyList/user/PropertyListCustomerDetail/PropertyListCustomerDetail.type';

export interface PropertyListAddressLayoutProps {
  children: React.ReactElement[];
}

const PropertyListAddressLayout: FC<PropertyListAddressLayoutProps> = ({
  children,
}: PropertyListAddressLayoutProps) => {
  const elements = [
    'addressLine1',
    'addressLine2',
    'city',
    'state',
    'country',
    'postalCode',
  ];
  const [
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    postalCode,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn alignItems="center" height="auto">
      <FlexBoxRow {...commonPropsLabeledTextBox}>{addressLine1}</FlexBoxRow>
      <FlexBoxRow {...commonPropsLabeledTextBox}>{addressLine2}</FlexBoxRow>
      <FlexBoxRow height="auto" justifyContent="space-between">
        <FlexBoxRow
          {...commonPropsLabeledTextBox}
          width={HALF_WIDTH_PROPERTY_LABELED_TEXT}
        >
          {city}
        </FlexBoxRow>
        <FlexBoxRow
          {...commonPropsLabeledTextBox}
          width={HALF_WIDTH_PROPERTY_LABELED_TEXT}
        >
          {state}
        </FlexBoxRow>
      </FlexBoxRow>
      <FlexBoxRow height="auto" justifyContent="space-between">
        <FlexBoxRow
          {...commonPropsLabeledTextBox}
          width={HALF_WIDTH_PROPERTY_LABELED_TEXT}
        >
          {country}
        </FlexBoxRow>
        <FlexBoxRow
          {...commonPropsLabeledTextBox}
          width={HALF_WIDTH_PROPERTY_LABELED_TEXT}
        >
          {postalCode}
        </FlexBoxRow>
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};
export default PropertyListAddressLayout;
