import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// Style
import { ContentBoxStyle } from 'components/templates/settings/ProfileTemplate/style';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from 'components/atoms/utils/layout/Box/FlexBox';
import Margin from 'components/atoms/utils/layout/Margin';

export interface ProfileTemplateLayoutProps {
  children: React.ReactElement[];
}

const ProfileTemplateLayout: FC<ProfileTemplateLayoutProps> = ({
  children,
}: ProfileTemplateLayoutProps) => {
  const elements = ['header', 'tab', 'content'];
  const [header, tab, content] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn
      width="1360"
      padding="85 80 0 80"
      height="auto"
      style={{ minWidth: `${1200 / 16}rem` }}
    >
      {header}
      <Margin top={60} />
      {tab}
      <FlexBoxRow padding="60" theme={ContentBoxStyle}>
        {content}
      </FlexBoxRow>
      <Margin top={150} />
    </FlexBoxColumn>
  );
};

export default ProfileTemplateLayout;
