import React, { FC, useCallback, useContext, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RegisteredAddressEditRequestCreateContext from 'enhancers/useCase/setting/registeredAddressEditRequest/RegisteredAddressEditRequestCreateProvider/RegisteredAddressEditRequestCreateContext';
import ProofOfResidencyFileContext from 'enhancers/fileHandler/verification/kycRequest/ProofOfResidencyFileProvider/ProofOfResidencyFileContext';

// Lib
import {
  isValid,
  getDefaultValues,
} from 'components/organisms/form/settings/FormRegisteredAddressEditRequestAdd/FormProofOfResidency/helper';

// Type
import { UserType } from 'types/User.type';
import { FormInputProofOfResidency } from 'components/organisms/form/settings/FormRegisteredAddressEditRequestAdd/FormProofOfResidency/FormProofOfResidency.type';
import { RegisteredAddressEditRequestType } from 'types/RegisteredAddressEditRequest.type';

// Constant
import { IS_LOADING } from 'config/constants/requestState';

// Style
import { ErrorMessageStyle } from 'components/organisms/form/settings/FormRegisteredAddressEditRequestAdd/FormProofOfResidency/style';

// Component
import {
  SubmitButton,
  DefaultButtonTextStyle,
} from 'components/atoms/button/Button2';
import { TextSecondary } from 'components/atoms/text2/Text2';
import LabelSendIcon from 'components/atoms/button/ButtonLabel/LabelSendIcon';
import FormResidentialAddressInput from 'components/organisms/form/settings/FormRegisteredAddressEditRequestAdd/FormProofOfResidency/FormResidentialAddressInput';
import SelectDocumentType from 'components/organisms/form/settings/FormRegisteredAddressEditRequestAdd/FormProofOfResidency/SelectDocumentType';
import FormResidencyProofUpload from 'components/organisms/form/settings/FormRegisteredAddressEditRequestAdd/FormProofOfResidency/FormResidencyProofUpload';
import FormProofOfResidencyLayout from 'components/organisms/form/settings/FormRegisteredAddressEditRequestAdd/FormProofOfResidency/FormProofOfResidencyLayout';

// ValueObject
import ProofOfResidency from 'value/verification/kycRequest/KycRequestContent/ProofOfResidency/ProofOfResidency';

const FORM_ID = `organisms.form.settings.FormProofOfResidency`;

export interface FormProofOfResidencyProps {
  proofOfResidency?: ProofOfResidency;
  isUnderReview: boolean;
  userType?: UserType;
}

const FormProofOfResidency: FC<FormProofOfResidencyProps> = ({
  proofOfResidency,
  isUnderReview,
  userType = UserType.corporate,
}: FormProofOfResidencyProps) => {
  const { t } = useTranslation();

  // FileHandler
  const { proofOfDocument1, proofOfDocument2 } = useContext(
    ProofOfResidencyFileContext,
  );

  // Method
  const { state, executeCreate } = useContext(
    RegisteredAddressEditRequestCreateContext,
  );

  /* *
   *
   *  Form
   *
   * */
  const methods = useForm<FormInputProofOfResidency>({
    mode: 'onChange',
  });
  const { errors, handleSubmit, reset, setValue, watch } = methods;

  /* *
   *
   *  DefaultValues
   *
   * */
  // Set Default Value from ProofOfResidency
  useEffect(() => {
    if (proofOfResidency) {
      const defaultValues = getDefaultValues(proofOfResidency);

      reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [proofOfResidency, setValue]);

  /* *
   *
   *  Event
   *
   * */

  const onSubmit = useCallback(
    async (input: FormInputProofOfResidency) => {
      executeCreate(
        input,
        RegisteredAddressEditRequestType.proofOfResidency,
        proofOfDocument1,
        proofOfDocument2,
      );
    },
    // eslint-disable-next-line
    [executeCreate, proofOfDocument1, proofOfDocument2],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <FormProofOfResidencyLayout isUnderReview={isUnderReview}>
          <FormResidentialAddressInput
            key="residentialAddress"
            isUnderReview={isUnderReview}
          />
          <SelectDocumentType
            key="documentType"
            isUnderReview={isUnderReview}
            userType={userType}
          />
          <FormResidencyProofUpload
            key="documents"
            proofOfResidency={proofOfResidency}
          />
          <TextSecondary key="message" theme={ErrorMessageStyle}>
            {t(`${FORM_ID}.message.underReview`)}
          </TextSecondary>
          <SubmitButton
            key="button"
            disabled={isUnderReview || !isValid(errors, watch)}
            isLoading={state === IS_LOADING}
            height={75}
            width={'100%'}
          >
            <LabelSendIcon
              iconSize={26}
              iconStyle={{ color: 'white' }}
              label={t(`atoms.button.submit`)}
              marginBetween={8}
              textStyle={DefaultButtonTextStyle}
            />
          </SubmitButton>
        </FormProofOfResidencyLayout>
      </FormProvider>
    </form>
  );
};

export default FormProofOfResidency;
