import React, { FC, useMemo } from 'react';

// Type
import { MenuType } from 'config/menu/main/Menu.type';
import {
  VerifiedUserSettingMenuType,
  VerifiedCorporateUserSettingMenuType,
  UnverifiedUserSettingMenuType,
} from 'config/menu/sub/SettingMenu.type';
import { UserType, VerifiedUserStatusType } from 'types/User.type';

// Component
import User from 'domain/public/user/User/User/User';
import MenuItemWithSub from '../MenuItemWithSub';

// DomainObject

export interface SettingsMenuProps {
  user: User;
}

const MAIN_MENU_TYPE = MenuType.settings;

const SettingsMenu: FC<SettingsMenuProps> = ({ user }: SettingsMenuProps) => {
  const { status } = user.getProps();

  const isVerified = Object.keys(VerifiedUserStatusType).includes(status);

  const settingsMenuItems = useMemo(() => {
    if (!isVerified) return UnverifiedUserSettingMenuType;
    return user.isUserType(UserType.individual)
      ? VerifiedUserSettingMenuType
      : VerifiedCorporateUserSettingMenuType;
  }, [isVerified, user]);

  return (
    <MenuItemWithSub
      key={MAIN_MENU_TYPE}
      type={MAIN_MENU_TYPE}
      menuItems={settingsMenuItems}
    />
  );
};

export default SettingsMenu;
