import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import KycRequestContext from 'domain/verification/kycRequest/KycRequest/KycRequest/KycRequestContext';

// Style
import { TitleStyle } from 'components/organisms/propertyList/user/PropertyListCustomerDetail/style';

// Component
import { TextSecondary } from 'components/atoms/text2/Text2';
import PropertyListAddress from 'components/organisms/propertyList/common/PropertyListAddress';
import PropertyListResidentialInformationLayout from './PropertyListResidentialInformationLayout';

const ORGANISM_ID = `organisms.propertyList.user.PropertyListResidentialInformation`;

const PropertyListResidentialInformation: FC = () => {
  const { t } = useTranslation();
  const { kycRequest } = useContext(KycRequestContext);

  const proofOfResidency = kycRequest?.getProofOfResidency();

  const address = proofOfResidency?.getAddress();

  return (
    <PropertyListResidentialInformationLayout>
      <TextSecondary key="title" theme={TitleStyle}>
        {t(`${ORGANISM_ID}.title`)}
      </TextSecondary>
      <PropertyListAddress key="address" address={address} />
    </PropertyListResidentialInformationLayout>
  );
};
export default PropertyListResidentialInformation;
