import React, { FC, useContext } from 'react';
import { useRegisteredAddressCorporateEditRequestCreateUseCase } from 'enhancers/useCase/setting/registeredAddressEditRequest/RegisteredAddressEditRequestCreateProvider/RegisteredAddressCorporateEditRequestCreateProvider/useRegisteredAddressCorporateEditRequestCreateUseCase';
import RegisteredAddressEditRequestCreateContext from 'enhancers/useCase/setting/registeredAddressEditRequest/RegisteredAddressEditRequestCreateProvider/RegisteredAddressEditRequestCreateContext';
import UserAuthorizedContext from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Error
import SystemErrorHandler from 'enhancers/errorHandler/SystemErrorHandler';

export type RegisteredAddressCorporateEditRequestCreateProviderProps = {
  children: React.ReactNode;
};

const RegisteredAddressCorporateEditRequestCreateProvider: FC<RegisteredAddressCorporateEditRequestCreateProviderProps> = ({
  children,
}: RegisteredAddressCorporateEditRequestCreateProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const {
    state,
    setState,
    executeCreate,
  } = useRegisteredAddressCorporateEditRequestCreateUseCase(userAuthorized);

  return (
    <RegisteredAddressEditRequestCreateContext.Provider
      value={{
        state,
        executeCreate,
      }}
    >
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </RegisteredAddressEditRequestCreateContext.Provider>
  );
};
export default RegisteredAddressCorporateEditRequestCreateProvider;
