import React, { FC, useContext } from 'react';
import RegisteredAddressTabTypeOptionContext from 'components/organisms/tabBar/TabBarRegisteredAddress/RegisteredAddressTabTypeOptionContext';

// Type
import { RegisteredAddressTab } from 'components/organisms/tabBar/TabBarRegisteredAddress/RegisteredAddressTabType.type';

// Component
import TabBarSquareItems from 'components/molecules/tabBar/TabBarSquareItems';

const TabBarRegisteredAddress: FC = () => {
  const {
    registeredAddressTabTypeOption,
    setRegisteredAddressTabTypeOption,
  } = useContext(RegisteredAddressTabTypeOptionContext);
  const registeredAddressTabItems = Object.keys(RegisteredAddressTab);

  return (
    <TabBarSquareItems
      items={registeredAddressTabItems}
      itemHeight={76}
      itemWidth={188.66}
      marginBetween={10.34}
      selected={registeredAddressTabTypeOption as string}
      onClickItem={setRegisteredAddressTabTypeOption as (type: string) => void}
    />
  );
};

export default TabBarRegisteredAddress;
