import React, { FC, useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import ProofOfResidencyFileContext from 'enhancers/fileHandler/verification/kycRequest/ProofOfResidencyFileProvider/ProofOfResidencyFileContext';

// CloudStorage
import { useStorageImage } from 'infrastructure/firebase/cloudStorage/useStorageImage';

// Type
import { AddressDocumentType } from 'types/AddressDocumentType.type';
import { FileType } from 'types/File.type';

// Style
import { DescriptionStyle } from 'components/organisms/form/settings/FormRegisteredAddressEditRequestAdd/FormProofOfResidency/FormResidencyProofUpload/style';

// Component
import { TextSecondary } from 'components/atoms/text2/Text2';
import CheckPointList from 'components/organisms/list/CheckPointList';
import DropzoneArea from 'components/molecules/dropzone2/DropzoneArea';
import KycFormContainer from 'components/organisms/form/common/KycFormContainer';
import FormResidencyProofUploadLayout from 'components/organisms/form/settings/FormRegisteredAddressEditRequestAdd/FormProofOfResidency/FormResidencyProofUpload/FormResidencyProofUploadLayout';

// ValueObject
import ProofOfResidency from 'value/verification/kycRequest/KycRequestContent/ProofOfResidency/ProofOfResidency';

const FORM_ID = `organisms.form.settings.FormProofOfResidency.residencyProofUpload`;

export interface FormResidencyProofUploadProps {
  proofOfResidency?: ProofOfResidency;
}

const FormResidencyProofUpload: FC<FormResidencyProofUploadProps> = ({
  proofOfResidency,
}: FormResidencyProofUploadProps) => {
  const { t } = useTranslation();

  // Form
  const { setValue, watch } = useFormContext();

  // FileHandler
  const {
    proofOfDocument1,
    setProofOfDocument1,
    proofOfDocument2,
    setProofOfDocument2,
  } = useContext(ProofOfResidencyFileContext);

  // Storage
  const proofOfDocumentStorageUrl1 = useStorageImage(
    proofOfResidency?.getProps().document.documentUrl1,
  ).storageUrl;

  const proofOfDocumentStorageUrl2 = useStorageImage(
    proofOfResidency?.getProps().document.documentUrl2,
  ).storageUrl;

  const proofOfDocumentUrl1 = useMemo(() => {
    if (proofOfDocumentStorageUrl1) return proofOfDocumentStorageUrl1;
    return proofOfDocument1 && URL.createObjectURL(proofOfDocument1);
  }, [proofOfDocument1, proofOfDocumentStorageUrl1]);

  const proofOfDocumentUrl2 = useMemo(() => {
    if (proofOfDocumentStorageUrl2) return proofOfDocumentStorageUrl2;
    return proofOfDocument2 && URL.createObjectURL(proofOfDocument2);
  }, [proofOfDocument2, proofOfDocumentStorageUrl2]);

  /* *
   *
   *  UseEffect
   *
   * */

  // Set proofOfDocumentUrl1 when File is uploaded
  useEffect(() => {
    if (proofOfDocumentUrl1) {
      setValue('proofOfDocumentUrl1', proofOfDocumentUrl1);
    }
    // eslint-disable-next-line
  }, [
    proofOfDocumentUrl1]);

  // Set proofOfDocumentUrl2 when File is uploaded
  useEffect(() => {
    if (proofOfDocumentUrl2) {
      setValue('proofOfDocumentUrl2', proofOfDocumentUrl2);
    }
    // eslint-disable-next-line
  }, [
    proofOfDocumentUrl2
  ]);

  return (
    <KycFormContainer order={3} formId={FORM_ID}>
      <FormResidencyProofUploadLayout
        isDisplaySecondDropzone={
          watch('documentType') === AddressDocumentType.driverLicense
        }
      >
        <TextSecondary key="description" theme={DescriptionStyle}>
          {t(`${FORM_ID}.description`)}
        </TextSecondary>
        <DropzoneArea
          key="dropzone1"
          name="proofOfDocument1"
          acceptFileTypes={[FileType.png, FileType.jpeg]}
          defaultFilePath={proofOfDocumentUrl1 || ''}
          maxFileSize={2}
          setFile={setProofOfDocument1}
          width={476}
        />
        <DropzoneArea
          key="dropzone2"
          name="proofOfDocument2"
          acceptFileTypes={[FileType.png, FileType.jpeg]}
          defaultFilePath={proofOfDocumentUrl2 || ''}
          maxFileSize={2}
          setFile={setProofOfDocument2}
          width={476}
        />
        <CheckPointList
          key="checkPoint"
          title={t(`organisms.CheckPointList.title`)}
          checkPoints={[
            t(`organisms.CheckPointList.text.complete`),
            t(`organisms.CheckPointList.text.clear`),
            t(`organisms.CheckPointList.text.valid`),
          ]}
        />
      </FormResidencyProofUploadLayout>
    </KycFormContainer>
  );
};

export default FormResidencyProofUpload;
