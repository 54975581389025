/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

// Constant
import { INITIAL } from 'config/constants/requestState';

// DomainObject
import DebitCardRenewalCancelRequestCherryVisa01 from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01';

export interface DebitCardRenewalRequestCherryVisa01CancelConfirmContextProps {
  state: string;
  cancel: (
    callback?: (
      debitCardRenewalCancelRequest: DebitCardRenewalCancelRequestCherryVisa01,
    ) => void,
  ) => void;
}

const DebitCardRenewalRequestCherryVisa01CancelConfirmContext = React.createContext<
  DebitCardRenewalRequestCherryVisa01CancelConfirmContextProps
>({
  state: INITIAL,
  cancel: (
    callback?: (
      debitCardRenewalCancelRequest: DebitCardRenewalCancelRequestCherryVisa01,
    ) => void,
  ) => undefined,
});

export default DebitCardRenewalRequestCherryVisa01CancelConfirmContext;
