import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// Style
import { TitleBoxStyle } from 'components/organisms/propertyList/user/PropertyListCustomerDetail/style';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from 'components/atoms/utils/layout/Box/FlexBox';

// Common
import {
  commonPropsLabeledTextBox,
  HALF_WIDTH_PROPERTY_LABELED_TEXT,
} from 'components/organisms/propertyList/user/PropertyListCustomerDetail/PropertyListCustomerDetail.type';

export interface PropertyListPersonalInformationLayoutProps {
  children: React.ReactElement[];
}

const PropertyListPersonalInformationLayout: FC<PropertyListPersonalInformationLayoutProps> = ({
  children,
}: PropertyListPersonalInformationLayoutProps) => {
  const elements = [
    'title',
    'contractName',
    'gender',
    'dateOfBirth',
    'passportNo',
    'passportExpiredAt',
  ];
  const [
    title,
    contractName,
    gender,
    dateOfBirth,
    passportNo,
    passportExpiredAt,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn alignItems="center" height="auto">
      <FlexBoxRow
        alignItems="center"
        paddingLeft="15"
        height={50}
        theme={TitleBoxStyle}
      >
        {title}
      </FlexBoxRow>
      <FlexBoxRow {...commonPropsLabeledTextBox}>{contractName}</FlexBoxRow>
      <FlexBoxRow height="auto" justifyContent="space-between">
        <FlexBoxRow
          {...commonPropsLabeledTextBox}
          width={HALF_WIDTH_PROPERTY_LABELED_TEXT}
        >
          {gender}
        </FlexBoxRow>
        <FlexBoxRow
          {...commonPropsLabeledTextBox}
          width={HALF_WIDTH_PROPERTY_LABELED_TEXT}
        >
          {dateOfBirth}
        </FlexBoxRow>
      </FlexBoxRow>
      <FlexBoxRow height="auto" justifyContent="space-between">
        <FlexBoxRow
          {...commonPropsLabeledTextBox}
          width={HALF_WIDTH_PROPERTY_LABELED_TEXT}
        >
          {passportNo}
        </FlexBoxRow>
        <FlexBoxRow
          {...commonPropsLabeledTextBox}
          width={HALF_WIDTH_PROPERTY_LABELED_TEXT}
        >
          {passportExpiredAt}
        </FlexBoxRow>
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};
export default PropertyListPersonalInformationLayout;
