import React, { FC, useContext } from 'react';
import { useProofOfResidencySubmitUseCase } from 'enhancers/useCase/setting/registeredAddressEditRequest/RegisteredAddressEditRequestCreateProvider/ProofOfResidencySubmitProvider/useProofOfResidencySubmitUseCase';
import RegisteredAddressEditRequestCreateContext from 'enhancers/useCase/setting/registeredAddressEditRequest/RegisteredAddressEditRequestCreateProvider/RegisteredAddressEditRequestCreateContext';
import UserAuthorizedContext from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Error
import SystemErrorHandler from 'enhancers/errorHandler/SystemErrorHandler';

export type ProofOfResidencySubmitProviderProps = {
  children: React.ReactNode;
};

const ProofOfResidencySubmitProvider: FC<ProofOfResidencySubmitProviderProps> = ({
  children,
}: ProofOfResidencySubmitProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const { state, setState, executeCreate } = useProofOfResidencySubmitUseCase(
    userAuthorized,
  );

  return (
    <RegisteredAddressEditRequestCreateContext.Provider
      value={{
        state,
        executeCreate,
      }}
    >
      <SystemErrorHandler state={state} setState={setState}>
        {children}
      </SystemErrorHandler>
    </RegisteredAddressEditRequestCreateContext.Provider>
  );
};
export default ProofOfResidencySubmitProvider;
