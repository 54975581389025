import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from 'components/atoms/utils/layout/Box/FlexBox';
import Margin from 'components/atoms/utils/layout/Margin';

export interface FormCorporateAddressLayoutProps {
  children: React.ReactElement[];
  isUnderReview: boolean;
}

const FormCorporateAddressLayout: FC<FormCorporateAddressLayoutProps> = ({
  children,
  isUnderReview,
}: FormCorporateAddressLayoutProps) => {
  const elements = [
    'address',
    'documentType',
    'documents',
    'message',
    'button',
  ];
  const [
    address,
    documentType,
    documents,
    message,
    button,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn width={1200} height="auto">
      {address}
      <Margin top={60} />
      {documentType}
      <Margin top={60} />
      {documents}
      {isUnderReview && (
        <>
          <Margin top={30} />
          <FlexBoxRow justifyContent="center">{message}</FlexBoxRow>
        </>
      )}
      <Margin top={60} />
      {button}
      <Margin top={83} />
    </FlexBoxColumn>
  );
};
export default FormCorporateAddressLayout;
