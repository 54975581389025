// Type

// Service
import KycRequestQueryService from 'service/verification/kycRequest/KycRequestQueryService';

// IRepository
import IKycRequestRepository from 'domain/verification/kycRequest/KycRequest/IKycRequestRepository';

// DomainObject

class ProfileViewUseCase {
  private kycRequestQueryService: KycRequestQueryService;

  constructor(kycRequestRepository: IKycRequestRepository) {
    this.kycRequestQueryService = new KycRequestQueryService(
      kycRequestRepository,
    );
  }

  public open = async (id: string) => {
    const kycRequest = await this.kycRequestQueryService.findByIdOrUndef(id);

    return {
      kycRequest,
    };
  };
}
export default ProfileViewUseCase;
