import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from 'errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from 'errors/ErrorMessage/ErrorMessage';

// // Type
import {
  RegisteredAddressCorporateEditRequestProps,
  RegisteredAddressCorporateEditRequestPropsFormat,
} from 'domain/verification/registeredAddressEditRequest/RegisteredAddressCorporateEditRequest/RegisteredAddressCorporateEditRequest.type';

// // DomainObject
import RegisteredAddressCorporateEditRequest from './RegisteredAddressCorporateEditRequest';

class RegisteredAddressCorporateEditRequestFactory {
  static create = (
    id: string,
    props: RegisteredAddressCorporateEditRequestProps,
  ) => {
    const result = Joi.object(
      RegisteredAddressCorporateEditRequestPropsFormat,
    ).validate({
      ...props,
      id,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: RegisteredAddressCorporateEditRequest.name,
          reason: result.error.details[0].message,
        },
      );

    return new RegisteredAddressCorporateEditRequest(id, props);
  };
}

export default RegisteredAddressCorporateEditRequestFactory;
