import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// Style
import { TitleBoxStyle } from 'components/organisms/propertyList/user/PropertyListCustomerDetail/style';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from 'components/atoms/utils/layout/Box/FlexBox';

// Common
import { commonPropsLabeledTextBox } from 'components/organisms/propertyList/user/PropertyListCustomerDetail/PropertyListCustomerDetail.type';

export interface PropertyListCorporateInformationLayoutProps {
  children: React.ReactElement[];
}

const PropertyListCorporateInformationLayout: FC<PropertyListCorporateInformationLayoutProps> = ({
  children,
}: PropertyListCorporateInformationLayoutProps) => {
  const elements = ['title', 'corporateName', 'address'];
  const [title, corporateName, address] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxColumn alignItems="center" height="auto">
      <FlexBoxRow
        alignItems="center"
        paddingLeft="15"
        height={50}
        theme={TitleBoxStyle}
      >
        {title}
      </FlexBoxRow>
      <FlexBoxRow {...commonPropsLabeledTextBox}>{corporateName}</FlexBoxRow>
      {address}
    </FlexBoxColumn>
  );
};
export default PropertyListCorporateInformationLayout;
