import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// Component
import { FlexBoxColumn } from 'components/atoms/utils/layout/Box/FlexBox';
import Margin from 'components/atoms/utils/layout/Margin';
import ContentBox from 'components/atoms/utils/layout/Box/Box/ContentBox';

export interface NotificationPendingLayoutProps {
  children: React.ReactElement[];
}

const NotificationPendingLayout: FC<NotificationPendingLayoutProps> = ({
  children,
}: NotificationPendingLayoutProps) => {
  const elements = ['icon', 'title', 'description'];
  const [icon, title, description] = getElementsFromKeys(children, elements);

  return (
    <ContentBox padding="100" height="auto">
      <FlexBoxColumn alignItems="center" textAlign="center">
        {icon}
        <Margin top={30} />
        {title}
        <Margin top={20} />
        {description}
      </FlexBoxColumn>
    </ContentBox>
  );
};
export default NotificationPendingLayout;
