// Type
import { RegisteredAddressEditRequestType } from 'types/RegisteredAddressEditRequest.type';

// DomainObject
import DomainObjects from 'domain/interface/Iterator/DomainObjects';
import RegisteredAddressEditRequest from '../RegisteredAddressEditRequest/RegisteredAddressEditRequest';

class RegisteredAddressEditRequests extends DomainObjects<
  RegisteredAddressEditRequest
> {
  public filterByRequestType = (type: RegisteredAddressEditRequestType) => {
    const filtered = this.items.filter(
      (debitCardRenewalRequest) =>
        debitCardRenewalRequest.getProps().addressEditRequestType === type,
    );
    return new RegisteredAddressEditRequests(filtered);
  };
}

export default RegisteredAddressEditRequests;
