import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Component
import LabeledText from 'components/molecules/textGroup/LabeledText';
import PropertyListAddressLayout from 'components/organisms/propertyList/common/PropertyListAddress/PropertyListAddressLayout';

// Common
import { commonPropsLabeledText } from 'components/organisms/propertyList/user/PropertyListCustomerDetail/PropertyListCustomerDetail.type';

// ValueObject
import Address from 'value/personalInformation/address/Address/Address/Address';

const ORGANISM_ID = `organisms.propertyList.common.PropertyListAddress`;

export interface PropertyListAddressProps {
  address?: Address;
}

const PropertyListAddress: FC<PropertyListAddressProps> = ({
  address,
}: PropertyListAddressProps) => {
  const { t } = useTranslation();

  if (!address) return <></>;

  return (
    <PropertyListAddressLayout>
      <LabeledText
        key="addressLine1"
        {...commonPropsLabeledText}
        label={t(`${ORGANISM_ID}.label.addressLine1`)}
        value={address?.getProps().addressLine1 || ''}
      />
      <LabeledText
        key="addressLine2"
        {...commonPropsLabeledText}
        label={t(`${ORGANISM_ID}.label.addressLine2`)}
        value={address?.getProps().addressLine2 || ''}
      />
      <LabeledText
        key="city"
        {...commonPropsLabeledText}
        label={t(`${ORGANISM_ID}.label.city`)}
        value={address?.getProps().city || ''}
      />
      <LabeledText
        key="state"
        {...commonPropsLabeledText}
        label={t(`${ORGANISM_ID}.label.state`)}
        value={address?.getProps().state || ''}
      />
      <LabeledText
        key="country"
        {...commonPropsLabeledText}
        label={t(`${ORGANISM_ID}.label.country`)}
        value={address?.getProps().country || ''}
      />
      <LabeledText
        key="postalCode"
        {...commonPropsLabeledText}
        label={t(`${ORGANISM_ID}.label.postalCode`)}
        value={address?.getProps().postalCode || ''}
      />
    </PropertyListAddressLayout>
  );
};
export default PropertyListAddress;
