import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import KycRequestContext from 'domain/verification/kycRequest/KycRequest/KycRequest/KycRequestContext';

// Style
import { TitleStyle } from 'components/organisms/propertyList/user/PropertyListCustomerDetail/style';

// Component
import { TextSecondary } from 'components/atoms/text2/Text2';
import LabeledText from 'components/molecules/textGroup/LabeledText';
import ImageWithMagnifiedIcon from 'components/molecules/image/ImageWithMagnifiedIcon';

// DomainObject
import KycRequestCorporate from 'domain/verification/kycRequest/KycRequest/KycRequestCorporate/KycRequestCorporate';

// Common
import {
  commonPropsLabeledText,
  commonPropsImage,
} from 'components/organisms/propertyList/user/PropertyListCustomerDetail/PropertyListCustomerDetail.type';
import PropertyListCorporateAddressProofLayout from './PropertyListCorporateAddressProofLayout';

const ORGANISM_ID = `organisms.propertyList.user.PropertyListCorporateAddressProof`;

const PropertyListCorporateAddressProof: FC = () => {
  const { t } = useTranslation();
  const { kycRequest } = useContext(KycRequestContext);

  if (kycRequest && !(kycRequest instanceof KycRequestCorporate)) return <></>;

  const corporateAddress = kycRequest?.getCorporateAddress();

  return (
    <PropertyListCorporateAddressProofLayout>
      <TextSecondary key="title" theme={TitleStyle}>
        {t(`${ORGANISM_ID}.title`)}
      </TextSecondary>
      <LabeledText
        key="documentType"
        {...commonPropsLabeledText}
        label={t(`${ORGANISM_ID}.label.documentType`)}
        value={corporateAddress?.getProps().document.type || ''}
      />
      <ImageWithMagnifiedIcon
        key="proofOfAddress"
        {...commonPropsImage}
        src={corporateAddress?.getProps().document.documentUrl1 || ''}
      />
    </PropertyListCorporateAddressProofLayout>
  );
};
export default PropertyListCorporateAddressProof;
