import React, { FC } from 'react';
import RegisteredAddressCorporateEditViewProvider from 'enhancers/useCase/setting/registeredAddressEditRequest/RegisteredAddressCorporateEditViewProvider';
import RegisteredAddressCorporateEditRequestCreateProvider from 'enhancers/useCase/setting/registeredAddressEditRequest/RegisteredAddressEditRequestCreateProvider/RegisteredAddressCorporateEditRequestCreateProvider';
import RegisteredAddressCorporateEditPage from './RegisteredAddressCorporateEditPage';

const EnhancedRegisteredAddressCorporateEditPage: FC = () => {
  return (
    <RegisteredAddressCorporateEditViewProvider>
      <RegisteredAddressCorporateEditRequestCreateProvider>
        <RegisteredAddressCorporateEditPage />
      </RegisteredAddressCorporateEditRequestCreateProvider>
    </RegisteredAddressCorporateEditViewProvider>
  );
};

export default EnhancedRegisteredAddressCorporateEditPage;
