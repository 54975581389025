import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import {
  SelectedTextStyle,
  SelectedBoxStyle,
  UnSelectedTextStyle,
  UnSelectedBoxStyle,
} from 'components/molecules/item/TabItem/TabItemSquare/style';

// LayoutComponent
import { FlexBoxColumn } from 'components/atoms/utils/layout/Box/FlexBox';

// Component
import { TextSecondary } from 'components/atoms/text2/Text2';
import LineBreakReplaced from 'components/atoms/text2/LineBreakReplaced';

export type TabItemSquareProps = {
  height: number | string;
  item: string;
  onClick: (type: string) => void;
  selected: string;
  width: number | string;
};

const MOLECULE_ID = 'molecules.items.TabItemSquare';

const TabItemSquare: FC<TabItemSquareProps> = ({
  height,
  item,
  onClick,
  selected,
  width,
}: TabItemSquareProps) => {
  const { t } = useTranslation();

  const isSelected = useMemo(() => item === selected, [item, selected]);

  const boxStyle = useMemo(
    () => (isSelected ? SelectedBoxStyle : UnSelectedBoxStyle),
    [isSelected],
  );

  const textStyle = useMemo(
    () => (isSelected ? SelectedTextStyle : UnSelectedTextStyle),
    [isSelected],
  );

  return (
    <FlexBoxColumn
      alignItems="center"
      height={height}
      justifyContent="center"
      onClick={() => onClick(item)}
      padding="9 13.4"
      style={boxStyle}
      width={width}
    >
      <TextSecondary theme={textStyle}>
        <LineBreakReplaced text={t(`${MOLECULE_ID}.${item}`)} />
      </TextSecondary>
    </FlexBoxColumn>
  );
};
export default TabItemSquare;
