import { useState } from 'react';

// Type
import {
  RegisteredAddressTab,
  RegisteredAddressTabType,
} from './RegisteredAddressTabType.type';

export const useRegisteredAddressTabTypeOption = (
  type?: RegisteredAddressTabType,
) => {
  const [
    registeredAddressTabTypeOption,
    setRegisteredAddressTabTypeOption,
  ] = useState<RegisteredAddressTabType>(
    type || RegisteredAddressTab.corporateInformation,
  );

  return {
    registeredAddressTabTypeOption,
    setRegisteredAddressTabTypeOption,
  };
};
