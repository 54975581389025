// IRepository
import IStorageHandler from 'storage/IStorageHandler';

class StorageService {
  private storageHandler: IStorageHandler;

  constructor(storageHandler: IStorageHandler) {
    this.storageHandler = storageHandler;
  }

  public upload = async (path: string, file: File) => {
    await this.storageHandler.upload(path, file);
  };

  public delete = async (path: string) => {
    await this.storageHandler.delete(path);
  };

  public download = async (path: string) => {
    return this.storageHandler.download(path);
  };

  public downloadTmpFile = async (fileId: string) => {
    return this.storageHandler.downloadTmpFile(fileId);
  };
}

export default StorageService;
