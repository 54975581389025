// IRepository
import { IRegisteredAddressEditRequestQueryRepository } from 'domain/verification/registeredAddressEditRequest/IRegisteredAddressEditRequestRepository';

class RegisteredAddressEditRequestQueryService {
  private registeredAddressEditRequestQueryRepository: IRegisteredAddressEditRequestQueryRepository;

  constructor(
    registeredAddressEditRequestQueryRepository: IRegisteredAddressEditRequestQueryRepository,
  ) {
    this.registeredAddressEditRequestQueryRepository = registeredAddressEditRequestQueryRepository;
  }

  public findAllByUserId = async (userId: string) => {
    const registeredAddressEditRequests = await this.registeredAddressEditRequestQueryRepository.findAllPendingByUserId(
      userId,
    );

    return registeredAddressEditRequests;
  };
}

export default RegisteredAddressEditRequestQueryService;
