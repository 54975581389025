import React, { FC } from 'react';
import ReviewImage from 'assets/images/reviewing3x.png';
import Box from 'components/atoms/utils/layout/Box/Box';

export interface IconPendingProps {
  width: string | number;
  height: string | number;
}

const IconPending: FC<IconPendingProps> = ({
  width,
  height,
}: IconPendingProps) => {
  return (
    <Box width={width} height={height}>
      <img src={ReviewImage} alt="-" width='100%' height='100%' />
    </Box>
  );
};
export default IconPending;
