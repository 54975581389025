import React, { FC } from 'react';
import RegisteredAddressIndividualEditViewProvider from 'enhancers/useCase/setting/registeredAddressEditRequest/RegisteredAddressIndividualEditViewProvider';
import ProofOfResidencySubmitProvider from 'enhancers/useCase/setting/registeredAddressEditRequest/RegisteredAddressEditRequestCreateProvider/ProofOfResidencySubmitProvider';
import RegisteredAddressIndividualEditPage from './RegisteredAddressIndividualEditPage';

const EnhancedRegisteredAddressIndividualEditPage: FC = () => {
  return (
    <RegisteredAddressIndividualEditViewProvider>
      <ProofOfResidencySubmitProvider>
        <RegisteredAddressIndividualEditPage />
      </ProofOfResidencySubmitProvider>
    </RegisteredAddressIndividualEditViewProvider>
  );
};

export default EnhancedRegisteredAddressIndividualEditPage;
