import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// Type
import {
  commonPropsLabeledTextBox,
  HALF_WIDTH_PROPERTY_LABELED_TEXT,
} from 'components/organisms/propertyList/user/PropertyListCustomerDetail/PropertyListCustomerDetail.type';

// Style
import { TitleBoxStyle } from 'components/organisms/propertyList/user/PropertyListCustomerDetail/style';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from 'components/atoms/utils/layout/Box/FlexBox';

export interface PropertyListCustomerBasicInformationLayoutProps {
  children: React.ReactElement[];
}

const PropertyListCustomerBasicInformationLayout: FC<PropertyListCustomerBasicInformationLayoutProps> = ({
  children,
}: PropertyListCustomerBasicInformationLayoutProps) => {
  const elements = [
    'title',
    'contractName',
    'email',
    'userType',
    'userId',
    'rank',
    'username',
    'status',
  ];
  const [
    title,
    contractName,
    email,
    userType,
    userId,
    rank,
    username,
    status,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn alignItems="center" height="auto">
      <FlexBoxRow
        alignItems="center"
        paddingLeft="15"
        height={50}
        theme={TitleBoxStyle}
      >
        {title}
      </FlexBoxRow>
      <FlexBoxRow {...commonPropsLabeledTextBox}>{contractName}</FlexBoxRow>
      <FlexBoxRow {...commonPropsLabeledTextBox}>{email}</FlexBoxRow>
      <FlexBoxRow {...commonPropsLabeledTextBox}>{userId}</FlexBoxRow>
      <FlexBoxRow height="auto" justifyContent="space-between">
        <FlexBoxRow
          {...commonPropsLabeledTextBox}
          width={HALF_WIDTH_PROPERTY_LABELED_TEXT}
        >
          {username}
        </FlexBoxRow>
        <FlexBoxRow
          {...commonPropsLabeledTextBox}
          width={HALF_WIDTH_PROPERTY_LABELED_TEXT}
        >
          {userType}
        </FlexBoxRow>
      </FlexBoxRow>
      <FlexBoxRow height="auto" justifyContent="space-between">
        <FlexBoxRow
          {...commonPropsLabeledTextBox}
          width={HALF_WIDTH_PROPERTY_LABELED_TEXT}
        >
          {rank}
        </FlexBoxRow>
        <FlexBoxRow
          {...commonPropsLabeledTextBox}
          width={HALF_WIDTH_PROPERTY_LABELED_TEXT}
        >
          {status}
        </FlexBoxRow>
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};
export default PropertyListCustomerBasicInformationLayout;
