import React, { FC } from 'react';
import ProfileViewProvider from 'enhancers/useCase/setting/customer/ProfileViewProvider';
import ProfilePage from './ProfilePage';

const EnhancedProfilePage: FC = () => {
  return (
    <ProfileViewProvider>
      <ProfilePage />
    </ProfileViewProvider>
  );
};

export default EnhancedProfilePage;
