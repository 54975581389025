import React from 'react';

// Type
import { ProfileTabIndividual, ProfileTabType } from './ProfileTabType.type';

export interface ProfileTabTypeOptionContextProps {
  profileTabItems: ProfileTabType[];
  profileTabTypeOption: ProfileTabType;
  setProfileTabTypeOption: (type: ProfileTabType) => void;
}

const ProfileTabTypeOptionContext = React.createContext<
  ProfileTabTypeOptionContextProps
>({
  profileTabItems: [],
  profileTabTypeOption: ProfileTabIndividual.basicInformation,
  setProfileTabTypeOption: () => undefined,
});

export default ProfileTabTypeOptionContext;
