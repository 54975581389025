export type BankAccountTransactionType =
  | BankSpotTransactionType
  | BankFutureTransactionType;

export type BankSpotTransactionType = BankWithdrawalType | BankDepositType;
export type BankFutureTransactionType = BankFutureDepositType;

export enum BankWithdrawalType {
  additionalPostage = 'additionalPostage',
  cancelFeeCard = 'cancelFeeCard',
  cancellationFeeTimeDeposit = 'cancellationFeeTimeDeposit',
  changeCardShippingAddressFee = 'changeCardShippingAddressFee',
  chargeDebitCard = 'chargeDebitCard',
  chargeDebitCardNoActivation = 'chargeDebitCardNoActivation',
  chargeFeeDebitCard = 'chargeFeeDebitCard',
  createContractTimeDeposit = 'createContractTimeDeposit',
  cryptoCurrencyBuy = 'cryptoCurrencyBuy',
  documentIssuanceFee = 'documentIssuanceFee',
  fpbmMsModificationWithdrawal = 'fpbmMsModificationWithdrawal',
  fpbmMsWithdrawal = 'fpbmMsWithdrawal',
  issueDebitCard = 'issueDebitCard',
  issueDebitCardAgain = 'issueDebitCardAgain',
  issueDebitCardNoActivation = 'issueDebitCardNoActivation',
  issueDebitCardPIN = 'issueDebitCardPIN',
  renewFeeDebitCard = 'renewFeeDebitCard',
  reshippingDebitCard = 'reshippingDebitCard',
  savingsAccountMonthlyMaintenance = 'savingsAccountMonthlyMaintenance',
  sendFundsByTransfer = 'sendFundsByTransfer',
  sendFundsToECBByTransfer = 'sendFundsToECBByTransfer',
  sendFundsToECBByTransferFee = 'sendFundsToECBByTransferFee',
  transferFee = 'transferFee',
  withdrawFeeDebitCard = 'withdrawFeeDebitCard',
  withdrawFundsByReimbursement = 'withdrawFundsByReimbursement',
}

export enum BankDepositType {
  commissionTimeDeposit = 'commissionTimeDeposit',
  cryptoCurrencySell = 'cryptoCurrencySell',
  cryptoDirectDeposit = 'cryptoDirectDeposit',
  fpbmMsDeposit = 'fpbmMsDeposit',
  fpbmMsModificationDeposit = 'fpbmMsModificationDeposit',
  interestTimeDeposit = 'interestTimeDeposit',
  principalTimeDeposit = 'principalTimeDeposit',
  receiveFundsByTransfer = 'receiveFundsByTransfer',
  receiveFundsByTemporaryLoan = 'receiveFundsByTemporaryLoan',
  receiveFundsFromECBByTransfer = 'receiveFundsFromECBByTransfer',
  refundShippingFee = 'refundShippingFee',
  refundTransferSavingsToCard = 'refundTransferSavingsToCard',
  refundDebitCardTopUpFee = 'refundDebitCardTopUpFee',
  withdrawDebitCard = 'withdrawDebitCard',
}

export enum BankFutureDepositType {
  futureInterestTimeDeposit = 'futureInterestTimeDeposit',
  futureCommissionTimeDeposit = 'futureCommissionTimeDeposit',
}

export enum BankTransactionObjectType {
  bankWithdrawal = 'BankWithdrawal',
  bankDeposit = 'BankDeposit',
  bankFutureWithdrawal = 'BankFutureWithdrawal',
  bankFutureDeposit = 'BankFutureDeposit',
}

export enum BankFutureTransactionStatusType {
  paid = 'paid',
  waitingPaymentDate = 'waitingPaymentDate',
  cancelled = 'cancelled',
}

export const BankFutureTransactionMapper = {
  [BankFutureDepositType.futureInterestTimeDeposit]:
    BankDepositType.interestTimeDeposit,
  [BankFutureDepositType.futureCommissionTimeDeposit]:
    BankDepositType.commissionTimeDeposit,
};

export enum DebitCardDescriptionBankTransactionType {
  chargeDebitCard = BankWithdrawalType.chargeDebitCard,
  cancelFeeCard = BankWithdrawalType.cancelFeeCard,
  issueDebitCard = BankWithdrawalType.issueDebitCard,
  issueDebitCardAgain = BankWithdrawalType.issueDebitCardAgain,
  issueDebitCardPIN = BankWithdrawalType.issueDebitCardPIN,
  reshippingDebitCard = BankWithdrawalType.reshippingDebitCard,
  refundTransferSavingsToCard = BankDepositType.refundTransferSavingsToCard,
  refundDebitCardTopUpFee = BankDepositType.refundDebitCardTopUpFee,
  withdrawDebitCard = BankDepositType.withdrawDebitCard,
}
