import React, { FC, useContext, useMemo } from 'react';
import RegisteredAddressEditRequestCreateContext from 'enhancers/useCase/setting/registeredAddressEditRequest/RegisteredAddressEditRequestCreateProvider/RegisteredAddressEditRequestCreateContext';

// Constant
import { SUCCESS } from 'config/constants/requestState';

// Component
import DashboardTemplate from 'components/templates/common/DashboardTemplate';
import RegisteredIndividualAddressEditTemplate from 'components/templates/settings/RegisteredIndividualAddressEditTemplate';
import RegisteredAddressEditPendingTemplate from 'components/templates/settings/RegisteredAddressEditPendingTemplate';

const RegisteredAddressIndividualEditPage: FC = () => {
  const { state } = useContext(RegisteredAddressEditRequestCreateContext);

  const TemplateComponent = useMemo(() => {
    if (state === SUCCESS) return <RegisteredAddressEditPendingTemplate />;

    return <RegisteredIndividualAddressEditTemplate />;
  }, [state]);

  return <DashboardTemplate>{TemplateComponent}</DashboardTemplate>;
};

export default RegisteredAddressIndividualEditPage;
