import styled from 'styled-components';

export const Container = styled.div`
  width: ${62 / 16}rem;
  height: ${62 / 16}rem;
  border-radius: 50%;
  background-color: #212f41;
  display: flex;
  justify-content: center;
  align-items: center;
`;
