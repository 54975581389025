// React
import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CryptoCurrencyOptionContext from '../../../selectBox/option/option/CryptoCurrencyOption/CryptoCurrencyOptionContext';
import UserAuthorizedContext from '../../../../../domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import CryptoCurrencyWalletContext from '../../../../../domain/cryptoCurrency/cryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWallet/CryptoCurrencyWalletContext';
import CryptoCurrencyRateContext from '../../../../../domain/cryptoCurrency/cryptoCurrencyRate/CryptoCurrencyRate/CryptoCurrencyRate/CryptoCurrencyRateContext';

// Constant
import {
  ROUTE_CRYPTO_CURRENCY_TRADE,
  ROUTE_CRYPTO_CURRENCY_DEPOSITS_WITHDRAWALS,
} from '../../../../../config/constants/routing';

// Type
import { FiatCurrencyType } from '../../../../../types/FiatCurrency.type';

// Style
import {
  BalanceStyle,
  CurrencyStyle,
  LabelStyle,
  TradeButtonStyle,
  HolderTextStyle,
} from './style';

// Components
import {
  BankActionButton,
  DefaultButtonTextStyle,
} from '../../../../atoms/button/Button2';
import { TextPrimary } from '../../../../atoms/text2/Text2';
import CryptoCurrencyIcon from '../../../../atoms/icon/CryptoCurrencyIcon';
import LabeledText from '../../../textGroup/LabeledText';
import LabeledCurrencyAmount from '../../../textGroup/LabeledCurrencyAmount';
import CurrencyNameWithShortName from '../../../textGroup/CurrencyNameWithShortName';
import CurrencyExchangeRate from '../../../textGroup/CurrencyExchangeRate';
import CryptoCurrencyWalletCardLayout from './CryptoCurrencyWalletCardLayout';

const CryptoCurrencyWalletCard: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { cryptoCurrencyOption } = useContext(CryptoCurrencyOptionContext);
  const { cryptoCurrencyRate } = useContext(CryptoCurrencyRateContext);
  const { cryptoCurrencyWallet } = useContext(CryptoCurrencyWalletContext);
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const currency = cryptoCurrencyWallet?.getProps().currency;
  const balance = cryptoCurrencyWallet?.getProps().balance;
  const rate = cryptoCurrencyRate?.getProps().last;
  const contractName = userAuthorized?.getProps().contractName;

  return (
    <CryptoCurrencyWalletCardLayout>
      <CryptoCurrencyIcon
        key="currencyIcon"
        type={cryptoCurrencyOption}
        width={53}
        height={53}
      />
      <CurrencyNameWithShortName
        key="currencyName"
        currency={cryptoCurrencyOption}
      />
      <CurrencyExchangeRate
        key="rate"
        baseCurrency={FiatCurrencyType.USD}
        unitCurrency={currency}
        rate={rate}
      />
      <LabeledCurrencyAmount
        key="balance"
        amount={balance}
        currency={cryptoCurrencyOption}
        direction="column"
        label={t(`atoms.label.balance`)}
        marginBetween="column"
        theme={{
          amount: BalanceStyle,
          currency: CurrencyStyle,
          label: LabelStyle,
        }}
      />
      <LabeledText
        key="accountHolder"
        direction="column"
        label={t(`molecules.textGroup.LabeledName.accountHolder`)}
        marginBetween={10}
        theme={{ label: LabelStyle, value: HolderTextStyle }}
        value={contractName || ''}
      />
      <BankActionButton
        key="depositButton"
        width={245}
        height={50}
        disabled={true}
        onClick={() => {
          history.push(ROUTE_CRYPTO_CURRENCY_DEPOSITS_WITHDRAWALS);
        }}
      >
        <TextPrimary theme={DefaultButtonTextStyle}>
          {t(`atoms.button.deposit`)}
        </TextPrimary>
      </BankActionButton>
      <BankActionButton
        key="withdrawalButton"
        width={245}
        height={50}
        disabled={true}
        onClick={() => {
          history.push(ROUTE_CRYPTO_CURRENCY_DEPOSITS_WITHDRAWALS);
        }}
      >
        <TextPrimary theme={DefaultButtonTextStyle}>
          {t(`atoms.button.withdraw`)}
        </TextPrimary>
      </BankActionButton>
      <BankActionButton
        key="tradeButton"
        disabled={true}
        width={510}
        height={50}
        theme={TradeButtonStyle}
        onClick={() => {
          history.push(ROUTE_CRYPTO_CURRENCY_TRADE);
        }}
      >
        <TextPrimary theme={DefaultButtonTextStyle}>
          {t(`atoms.button.cryptoCurrencyTrade`)}
        </TextPrimary>
      </BankActionButton>
    </CryptoCurrencyWalletCardLayout>
  );
};

export default CryptoCurrencyWalletCard;
