import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// Type
import {
  RegisteredAddressTab,
  RegisteredAddressTabType,
} from 'components/organisms/tabBar/TabBarRegisteredAddress/RegisteredAddressTabType.type';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from 'components/atoms/utils/layout/Box/FlexBox';
import Margin from 'components/atoms/utils/layout/Margin';

export interface RegisteredCorporateAddressEditTemplateLayoutProps {
  children: React.ReactElement[];
  registeredAddressTabTypeOption: RegisteredAddressTabType;
}

const RegisteredCorporateAddressEditTemplateLayout: FC<RegisteredCorporateAddressEditTemplateLayoutProps> = ({
  children,
  registeredAddressTabTypeOption,
}: RegisteredCorporateAddressEditTemplateLayoutProps) => {
  const elements = ['title', 'tab', 'tabCorporateAddress', 'tabProofOfAddress'];
  const [
    title,
    tab,
    tabCorporateAddress,
    tabProofOfAddress,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn
      width="1360"
      padding="85 80 0 80"
      height="auto"
      style={{ minWidth: `${1200 / 16}rem` }}
    >
      {title}
      <Margin top={60} />
      {tab}
      <FlexBoxRow>
        {registeredAddressTabTypeOption ===
          RegisteredAddressTab.corporateInformation && tabCorporateAddress}
        {registeredAddressTabTypeOption ===
          RegisteredAddressTab.personalInformation && tabProofOfAddress}
      </FlexBoxRow>
      <Margin top={100} />
    </FlexBoxColumn>
  );
};

export default RegisteredCorporateAddressEditTemplateLayout;
