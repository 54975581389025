import React, { FC, useContext } from 'react';
import ProfileTabTypeOptionContext from 'components/organisms/tabBar/TabBarProfile/ProfileTabTypeOptionContext';

// Component
import ProfileBasicInformationTemplate from '../ProfileBasicInformationTemplate';
import ProfilePersonalInformationTemplate from '../ProfilePersonalInformationTemplate';
import ProfileCorporateInformationTemplate from '../ProfileCorporateInformationTemplate';
import SwitchProfileContentLayout from './SwitchProfileContentLayout';

const SwitchProfileContent: FC = () => {
  const { profileTabTypeOption } = useContext(ProfileTabTypeOptionContext);

  return (
    <SwitchProfileContentLayout tab={profileTabTypeOption}>
      <ProfileBasicInformationTemplate key="basicInformation" />
      <ProfilePersonalInformationTemplate key="personalInformation" />
      <ProfileCorporateInformationTemplate key="corporateInformation" />
    </SwitchProfileContentLayout>
  );
};
export default SwitchProfileContent;
