import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import KycRequestContext from 'domain/verification/kycRequest/KycRequest/KycRequest/KycRequestContext';

// Style
import { TitleStyle } from 'components/organisms/propertyList/user/PropertyListCustomerDetail/style';

// Component
import { TextSecondary } from 'components/atoms/text2/Text2';
import LabeledImageWithMagnifiedIcon from 'components/molecules/image/LabeledImageWithMagnifiedIcon';
import PropertyListPassportImageLayout from 'components/organisms/propertyList/user/PropertyListPassportImage/PropertyListPassportImageLayout';

// Common
import { commonPropsLabeledImage } from 'components/organisms/propertyList/user/PropertyListCustomerDetail/PropertyListCustomerDetail.type';

const ORGANISM_ID = `organisms.propertyList.user.PropertyListPassportImage`;

const PropertyListPassportImage: FC = () => {
  const { t } = useTranslation();
  const { kycRequest } = useContext(KycRequestContext);

  const personalInformation = kycRequest?.getPersonalInformation();

  return (
    <PropertyListPassportImageLayout>
      <TextSecondary key="title" theme={TitleStyle}>
        {t(`${ORGANISM_ID}.title`)}
      </TextSecondary>
      <LabeledImageWithMagnifiedIcon
        key="passport"
        {...commonPropsLabeledImage}
        label={t(`${ORGANISM_ID}.label.passport`)}
        src={personalInformation?.getProps().passport.passportUrl || ''}
      />
      <LabeledImageWithMagnifiedIcon
        key="selfie"
        {...commonPropsLabeledImage}
        label={t(`${ORGANISM_ID}.label.selfie`)}
        src={personalInformation?.getProps().selfieUrl || ''}
      />
    </PropertyListPassportImageLayout>
  );
};
export default PropertyListPassportImage;
