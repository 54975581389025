// Functions
import FirebasePrivateFunctions from 'infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';

// IRepository
import IDebitCardRenewalCancelRequestCherryVisa01Repository from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/IDebitCardRenewalCancelRequestCherryVisa01Repository';
import DebitCardRenewalCancelRequestCherryVisa01Factory from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01Factory';

// Constant
import {
  DEBIT_CARD_RENEWAL_CANCEL_REQUEST_CHERRY_VISA_01_CREATE,
  DEBIT_CARD_RENEWAL_CANCEL_REQUEST_CHERRY_VISA_01_GET_RAW_BY_ID,
  DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_PAY_CANCELLATION_FEE,
} from 'infrastructure/firebase/firebaseFunctions/endpoint';

// DomainObject
import DebitCardRenewalRequest from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequest';
import DebitCardRenewalCancelRequestCherryVisa01 from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01';

class DebitCardRenewalCancelRequestCherryVisa01Functions
  extends FirebasePrivateFunctions
  implements IDebitCardRenewalCancelRequestCherryVisa01Repository {
  public confirmCancel = async (
    debitCardRenewalRequest: DebitCardRenewalRequest,
  ) => {
    const endpoint = DEBIT_CARD_RENEWAL_CANCEL_REQUEST_CHERRY_VISA_01_CREATE;

    const response = await this.privatePOST(endpoint, {
      debitCardRenewalRequestId: debitCardRenewalRequest.getId(),
    });

    return DebitCardRenewalCancelRequestCherryVisa01Factory.createFromRawData(
      response.data.data,
    );
  };

  public findById = async (id: string) => {
    // Replace id
    const endpoint = DEBIT_CARD_RENEWAL_CANCEL_REQUEST_CHERRY_VISA_01_GET_RAW_BY_ID.replace(
      /:id/,
      id,
    );

    const response = await this.privateGET(endpoint);

    return response.data.data;
  };

  public payCancellationFee = async (
    debitCardRenewalCancelRequestCherryVisa01: DebitCardRenewalCancelRequestCherryVisa01,
  ) => {
    const id = debitCardRenewalCancelRequestCherryVisa01.getId();

    const endpoint = DEBIT_CARD_RENEWAL_REQUEST_CHERRY_VISA_01_PAY_CANCELLATION_FEE;

    await this.privatePOST(endpoint, { id });

    return debitCardRenewalCancelRequestCherryVisa01;
  };
}
export default DebitCardRenewalCancelRequestCherryVisa01Functions;
