import React, { FC } from 'react';
import MagnifyGlassIcon from './MagnifyGlassIcon';

export interface ImageMagnifyIconProps {
  onClick: () => void;
}

const ImageMagnifyIcon: FC<ImageMagnifyIconProps> = ({
  onClick,
}: ImageMagnifyIconProps) => {
  return (
    <MagnifyGlassIcon
      size={`${24 / 16}rem`}
      iconColor="#fff"
      backgroundColor="#212f41"
      onClick={onClick}
    />
  );
};
export default ImageMagnifyIcon;
