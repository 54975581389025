export enum DebitCardRenewalCancelRequestCherryVisa01StepStatusType {
  initial = 'initial',
  paid = 'paid',
  submitted = 'submitted',
  waitingFPBM = 'waitingFPBM',
  rejected = 'rejected',
}

export enum DebitCardRenewalCancelRequestCherryVisa01StatusType {
  initial = 'initial',
  cancellationFormSent = 'cancellationFormSent',
  completed = 'completed',
}

export enum DebitCardRenewalCancelRequestCherryVisa01StepType {
  cancellationForm = 'cancellationForm',
  cancellationFee = 'cancellationFee',
}
