import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

// Type
import { CryptoCurrencyMenuType } from '../../../../config/menu/sub/CryptoCurrencyMenu.type';
import { DebitCardMenuType } from '../../../../config/menu/sub/DebitCardMenu.type';
import { InvitationMenuType } from '../../../../config/menu/sub/InvitationsMenu.type';
import { JointAccountMenuType } from '../../../../config/menu/sub/JointAccountMenu.type';
import { SettingMenuType } from '../../../../config/menu/sub/SettingMenu.type';
import { SavingsAccountMenuType } from '../../../../config/menu/sub/SavingsAccountMenu.type';
import { TimeDepositMenuType } from '../../../../config/menu/sub/TimeDepositMenu.type';
import { TransferMenuType } from '../../../../config/menu/sub/TransferMenu.type';

// Constant
import {
  ROUTE_CRYPTO_CURRENCY_WALLET,
  // ROUTE_CRYPTO_CURRENCY_DEPOSITS_WITHDRAWALS,
  // ROUTE_CRYPTO_CURRENCY_TRADE,
  ROUTE_DEBIT_CARD,
  ROUTE_INVITATION_CODES,
  ROUTE_INVITATIONS,
  ROUTE_REFERRAL_RESULTS,
  ROUTE_TIME_DEPOSIT,
  ROUTE_TIME_DEPOSIT_ADD,
  ROUTE_TRANSFER_SAVING_ACCOUNT,
  ROUTE_TRANSFER_DEBIT_CARD,
  ROUTE_JOINT_ACCOUNT,
  ROUTE_JOINT_ACCOUNT_ADD,
  ROUTE_JOINT_ACCOUNT_CONFIRMATION_REQUEST,
  ROUTE_SAVINGS_ACCOUNT,
  ROUTE_SETTINGS_TWO_FACTOR_AUTH_APP_EDIT,
  ROUTE_SETTINGS_EMAIL_EDIT,
  ROUTE_SETTINGS_PASSWORD_EDIT,
  // ROUTE_SETTINGS_SHIPPING_ADDRESS_EDIT,
  ROUTE_SETTINGS_CONSENT_FORM,
  ROUTE_SETTINGS_PROFILE,
  ROUTE_SETTINGS_ADDRESS_EDIT,
  ROUTE_SETTINGS_CORPORATE_ADDRESS_EDIT,
} from '../../../../config/constants/routing';

// Style
import { Container, MenuTextStyle } from './style';

// Component
import Margin from '../../../atoms/utils/layout/Margin';
import { TextPrimary } from '../../../atoms/text2/Text2';

export type SubMenuItemProps = {
  type: string;
};

const SubMenuItem: FC<SubMenuItemProps> = ({ type }: SubMenuItemProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  let to = '';

  switch (type) {
    case CryptoCurrencyMenuType.cryptoCurrencyWallet:
      to = ROUTE_CRYPTO_CURRENCY_WALLET;
      break;
    // case CryptoCurrencyMenuType.cryptoCurrencyDepositWithdrawal:
    //   to = ROUTE_CRYPTO_CURRENCY_DEPOSITS_WITHDRAWALS;
    //   break;
    // case CryptoCurrencyMenuType.cryptoCurrencyTrade:
    //   to = ROUTE_CRYPTO_CURRENCY_TRADE;
    //   break;
    case DebitCardMenuType.debitCard:
      to = ROUTE_DEBIT_CARD;
      break;
    case InvitationMenuType.invitationCodes:
      to = ROUTE_INVITATION_CODES;
      break;
    case InvitationMenuType.invitations:
      to = ROUTE_INVITATIONS;
      break;
    case InvitationMenuType.referralResults:
      to = ROUTE_REFERRAL_RESULTS;
      break;
    case JointAccountMenuType.jointAccount:
      to = ROUTE_JOINT_ACCOUNT;
      break;
    case JointAccountMenuType.jointAccountAdd:
      to = ROUTE_JOINT_ACCOUNT_ADD;
      break;
    case JointAccountMenuType.jointAccountConfirmationRequest:
      to = ROUTE_JOINT_ACCOUNT_CONFIRMATION_REQUEST;
      break;
    case SavingsAccountMenuType.savingsAccount:
      to = ROUTE_SAVINGS_ACCOUNT;
      break;
    case SettingMenuType.profile:
      to = ROUTE_SETTINGS_PROFILE;
      break;
    case SettingMenuType.twoFactorAuthAppEdit:
      to = ROUTE_SETTINGS_TWO_FACTOR_AUTH_APP_EDIT;
      break;
    case SettingMenuType.emailEdit:
      to = ROUTE_SETTINGS_EMAIL_EDIT;
      break;
    case SettingMenuType.passwordEdit:
      to = ROUTE_SETTINGS_PASSWORD_EDIT;
      break;
    // case SettingMenuType.shippingAddressEdit:
    //   to = ROUTE_SETTINGS_SHIPPING_ADDRESS_EDIT;
    // break;
    case SettingMenuType.consentForm:
      to = ROUTE_SETTINGS_CONSENT_FORM;
      break;
    case SettingMenuType.addressEdit:
      to = ROUTE_SETTINGS_ADDRESS_EDIT;
      break;
    case SettingMenuType.corporateAddressEdit:
      to = ROUTE_SETTINGS_CORPORATE_ADDRESS_EDIT;
      break;
    case TimeDepositMenuType.timeDeposit:
      to = ROUTE_TIME_DEPOSIT;
      break;
    case TimeDepositMenuType.timeDepositAdd:
      to = ROUTE_TIME_DEPOSIT_ADD;
      break;
    case TransferMenuType.transferSavingsAccount:
      to = ROUTE_TRANSFER_SAVING_ACCOUNT;
      break;
    case TransferMenuType.transferDebitCard:
      to = ROUTE_TRANSFER_DEBIT_CARD;
      break;
    default:
      to = '';
  }

  return (
    <Container
      onClick={() => {
        if (location.pathname === to) history.go(0);
        history.push(to);
      }}
    >
      <Margin left={13} />
      <TextPrimary key="menu" theme={MenuTextStyle}>
        {t(`subMenu.${type}`)}
      </TextPrimary>
    </Container>
  );
};
export default SubMenuItem;
