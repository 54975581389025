import {
  FiatCurrencyType,
  FiatCurrencyProps,
} from '../../types/FiatCurrency.type';
import { CryptoCurrencyType } from '../../types/CryptoCurrency.type';

// General
export const NO_DATA = '-';
export const EMAIL_SUPPORT = 'card_support@forbesprivatebank.com';

// ContractTimeDeposit
export const MINIMUM_CONTRACT_TIME_DEPOSIT_AMOUNT_INCREASED = 10000;
export const HOURS_TIME_LIMIT_RESERVATION_CHANGE_CONTRACT_TIME_DEPOSIT = 1 * 24; // 24 Hours

// CryptoCurrency
export const RATE_UPDATE_INTERVAL_MINUTES = 15; // 15 minutes

// Currency
export const BASE_CURRENCY_SYMBOL = FiatCurrencyType.USD;
export const BASE_CRYPTO_CURRENCY_SYMBOL = CryptoCurrencyType.BTC;
export const BASE_CURRENCY_UNIT =
  FiatCurrencyProps[BASE_CURRENCY_SYMBOL].symbol;

// DebitCard
export const MINIMUM_SAVINGS_ACCOUNT_TO_DEBIT_CARD_AMOUNT = 100;
export const MINIMUM_DEBIT_CARD_TO_SAVINGS_ACCOUNT_AMOUNT = 1;
export const MAXIMUM_TOP_UP_AMOUNT = 35000;
export const DEBIT_CARD_STATEMENT_START_YEAR = 2021;
export const DEBIT_CARD_ISSUANCE_FEE = 500;
export const DEBIT_CARD_CANCELLATION_FEE = 50;
export const TIMESTAMP_TOP_UP_FEE_START = 1660514400000; // August 15th 2022, 00:00:00
export const DEBIT_CARD_RENEWAL_CANCEL_REQUEST_ALLOWED_MONTH = 2;

// Meta Information
export const SERVICE_NAME = 'Forbes Private Bank Monaco';

// Google Authenticator
export const ACCOUNT_KEY_LENGTH = 15;

// Personal Information
export const MAX_YEAR_DATE_OF_BIRTH = -10;
export const MIN_YEAR_DATE_OF_BIRTH = -100;
export const MIN_YEAR_PASSPORT_ISSUED = -15;
export const MIN_MONTH_PASSPORT_EXPIRED = 3;
export const MIN_DAYS_PASSPORT_EXPIRED = 120;
export const MAX_YEAR_PASSPORT_EXPIRED = 15;

// Resend Email
export const RESEND_CONFIRMATION_MAIL_TIME = 60;

// SerialNumber (Bank Transaction)
export const DIGIT_TRANSACTION_SERIAL_NUMBER = 6;
export const MAX_NUMBER_TRANSACTION_SERIAL_NUMBER = 999999;
export const MIN_NUMBER_TRANSACTION_SERIAL_NUMBER = 1;

// SavingsAccount
export const LENGTH_SAVINGS_ACCOUNT_NUMBER = 10;
export const LENGTH_FORMATTED_SAVINGS_ACCOUNT_NUMBER = 11;
export const LENGTH_BANK_ACCOUNT_NUMBER_ECB = 13;
export const LENGTH_FORMATTED_BANK_ACCOUNT_NUMBER_ECB = 15;
export const LOWER_LIMIT_BALANCE_SAVINGS_ACCOUNT_TMP = -1427; // Add 2023/07/22

// Request
export const REQUEST_TIMEOUT = 60 * 1000; // 60 seconds
export const FILE_UPLOAD_TIMEOUT = 60 * 1000; // 60 seconds

// SignInDuration
export const SIGN_IN_DURATION = 30 * 60 * 1000; // 30minutes (convert to milliseconds)

// Table
export const HISTORY_TABLE_PAGE_SIZE = 10;
export const DEFAULT_PAGE_SIZE = 10;
export const FILTER_RULE = 'starts-with'; // equals, between, includes, starts-with, ends-with
export const FILTER_RULE_EQUALS = 'equals'; // equals

// TimeDeposit
export const ROUND_DOWN_TIME_DEPOSIT_AMOUNT = 10000;
export const INTEREST_RATE_PRECISION = 2;
export const COMMISSION_RATE_PRECISION = 2;

// Timezone , Datetime
export const TIMEZONE = 'Europe/Monaco';
export const TIMEZONE_SHORT = 'CET';
export const TIMEZONE_SHORT_SUMMER_TIME = 'CEST';
export const DATE_FORMAT = 'YYYY/MM/DD';
