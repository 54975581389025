import React, { FC, useState, useEffect } from 'react';
import storage from 'infrastructure/firebase/cloudStorage/storage';

// Component
import { ImageMagnifyIcon } from 'components/atoms/icon/MagnifyGlassIcon';
import ImageWithMagnifiedIconLayout from './ImageWithMagnifiedIconLayout';

export interface ImageWithMagnifiedIconProps {
  layout: {
    image: {
      width: string | number;
      height: string | number;
    };
    marginBetween?: string | number;
  };
  src: string;
}

const ImageWithMagnifiedIcon: FC<ImageWithMagnifiedIconProps> = ({
  layout,
  src,
}: ImageWithMagnifiedIconProps) => {
  const [url, setUrl] = useState();

  /**
   *
   *  UseEffect
   *
   */

  // getUrl
  useEffect(() => {
    const setDownloadURL = async () => {
      if (src) {
        const pathReference = storage.ref(src);
        const dlUrl = await pathReference.getDownloadURL();
        setUrl(dlUrl);
      }
    };
    setDownloadURL();
  }, [src]);

  /**
   *
   *  Event
   *
   */
  const onClickMagnifiedIcon = () => {
    window.open(url, 'magnifiedImage', 'width=1000, height=1000,toolbar=no');
  };

  return (
    <ImageWithMagnifiedIconLayout layout={layout}>
      <ImageMagnifyIcon key="icon" onClick={onClickMagnifiedIcon} />
      <img
        key="image"
        src={url || ''}
        alt=""
        style={{ maxWidth: '100%', maxHeight: '100%' }}
      />
    </ImageWithMagnifiedIconLayout>
  );
};

export default ImageWithMagnifiedIcon;
