import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// Style
import { TitleBoxStyle } from 'components/organisms/propertyList/user/PropertyListCustomerDetail/style';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from 'components/atoms/utils/layout/Box/FlexBox';
import Margin from 'components/atoms/utils/layout/Margin';

// Common
import {
  commonPropsLabeledTextBox,
  HALF_WIDTH_PROPERTY_LABELED_TEXT,
} from 'components/organisms/propertyList/user/PropertyListCustomerDetail/PropertyListCustomerDetail.type';

export interface PropertyListResidentialProofLayoutProps {
  children: React.ReactElement[];
}

const PropertyListResidentialProofLayout: FC<PropertyListResidentialProofLayoutProps> = ({
  children,
}: PropertyListResidentialProofLayoutProps) => {
  const elements = ['title', 'documentType', 'image1', 'image2'];
  const [title, documentType, image1, image2] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxColumn height="auto">
      <FlexBoxRow
        alignItems="center"
        paddingLeft="15"
        height={50}
        theme={TitleBoxStyle}
      >
        {title}
      </FlexBoxRow>
      <FlexBoxRow
        {...commonPropsLabeledTextBox}
        width={HALF_WIDTH_PROPERTY_LABELED_TEXT}
      >
        {documentType}
      </FlexBoxRow>
      <Margin top={30} />
      <FlexBoxRow height="auto" justifyContent="space-between">
        {image1}
        <Margin left={30} />
        {image2}
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};
export default PropertyListResidentialProofLayout;
