// Lib
import { startsWithHttp } from 'utils/helpers/stringHelper';

// Service
import DocumentTypeQueryService from 'service/settings/documentType/DocumentTypeQueryService';
import KycRequestQueryService from 'service/verification/kycRequest/KycRequestQueryService';
import RegisteredAddressEditRequestQueryService from 'service/verification/registeredAddressEditRequest/RegisteredAddressEditRequestQueryService';
import StorageService from 'service/storage/StorageService';

// IRepository
import { IKycRequestQueryRepository } from 'domain/verification/kycRequest/KycRequest/IKycRequestRepository';
import { IRegisteredAddressEditRequestQueryRepository } from 'domain/verification/registeredAddressEditRequest/IRegisteredAddressEditRequestRepository';
import IDocumentTypeRepository from 'domain/settings/documentType/DocumentType/IDocumentTypeRepository';
import IStorageHandler from 'storage/IStorageHandler';

// Domain
import UserAuthorized from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

class RegisteredAddressCorporateEditViewUseCase {
  private documentTypeQueryService: DocumentTypeQueryService;

  private kycRequestQueryService: KycRequestQueryService;

  private registeredAddressEditRequestQueryService: RegisteredAddressEditRequestQueryService;

  private storageService: StorageService;

  constructor(
    documentTypeRepository: IDocumentTypeRepository,
    kycRequestQueryRepository: IKycRequestQueryRepository,
    registeredAddressEditRequestQueryRepository: IRegisteredAddressEditRequestQueryRepository,
    storageHandler: IStorageHandler,
  ) {
    this.documentTypeQueryService = new DocumentTypeQueryService(
      documentTypeRepository,
    );

    this.kycRequestQueryService = new KycRequestQueryService(
      kycRequestQueryRepository,
    );

    this.registeredAddressEditRequestQueryService = new RegisteredAddressEditRequestQueryService(
      registeredAddressEditRequestQueryRepository,
    );

    this.storageService = new StorageService(storageHandler);
  }

  public open = async (user: UserAuthorized) => {
    const id = user.getId();

    const [
      documentTypes,
      documentTypesIndividual,
      kycRequest,
      registeredAddressEditRequests,
    ] = await Promise.all([
      this.documentTypeQueryService.findCorporateAll(),
      this.documentTypeQueryService.findIndividualAll(),
      this.kycRequestQueryService.findCorporateById(id),
      this.registeredAddressEditRequestQueryService.findAllByUserId(id),
    ]);

    return {
      documentTypes,
      documentTypesIndividual,
      kycRequest,
      registeredAddressEditRequests,
    };
  };

  public downloadDocuments = async (paths: string[]) => {
    const downloadPromises = paths.map((path) => {
      if (startsWithHttp(path)) return this.storageService.download(path);
      return this.storageService.downloadTmpFile(path);
    });
    return Promise.all(downloadPromises);
  };
}

export default RegisteredAddressCorporateEditViewUseCase;
