import React, { FC } from 'react';

// Component
import FormInputAddress from 'components/molecules/form/formInputGroup/FormInputAddress';
import KycFormContainer from 'components/organisms/form/common/KycFormContainer';

const FORM_ID = `organisms.form.settings.FormProofOfResidency.residentialAddress`;

export interface ResidentialAddressInputFormProps {
  isUnderReview: boolean;
}

const ResidentialAddressInputForm: FC<ResidentialAddressInputFormProps> = ({
  isUnderReview,
}: ResidentialAddressInputFormProps) => {
  return (
    <KycFormContainer order={1} formId={FORM_ID}>
      <FormInputAddress
        addressKeys={{
          country: 'country',
          addressLine1: 'addressLine1',
          addressLine2: 'addressLine2',
          city: 'city',
          state: 'state',
          postalCode: 'postalCode',
        }}
        isDisabled={isUnderReview}
      />
    </KycFormContainer>
  );
};

export default ResidentialAddressInputForm;
