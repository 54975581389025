import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import KycRequestContext from 'domain/verification/kycRequest/KycRequest/KycRequest/KycRequestContext';

// Style
import { TitleStyle } from 'components/organisms/propertyList/user/PropertyListCustomerDetail/style';

// Component
import { TextSecondary } from 'components/atoms/text2/Text2';
import LabeledText from 'components/molecules/textGroup/LabeledText';
import LabeledImageWithMagnifiedIcon from 'components/molecules/image/LabeledImageWithMagnifiedIcon';
import {
  commonPropsLabeledText,
  commonPropsLabeledImage,
} from 'components/organisms/propertyList/user/PropertyListCustomerDetail/PropertyListCustomerDetail.type';
import PropertyListResidentialProofLayout from './PropertyListResidentialProofLayout';

// Common

const ORGANISM_ID = `organisms.propertyList.user.PropertyListResidentialProof`;

const PropertyListResidentialProof: FC = () => {
  const { t } = useTranslation();
  const { kycRequest } = useContext(KycRequestContext);

  const proofOfResidency = kycRequest?.getProofOfResidency();

  return (
    <PropertyListResidentialProofLayout>
      <TextSecondary key="title" theme={TitleStyle}>
        {t(`${ORGANISM_ID}.title`)}
      </TextSecondary>
      <LabeledText
        key="documentType"
        {...commonPropsLabeledText}
        label={t(`${ORGANISM_ID}.label.documentType`)}
        value={proofOfResidency?.getProps().document.type || ''}
      />
      <LabeledImageWithMagnifiedIcon
        key="image1"
        {...commonPropsLabeledImage}
        label={t(`${ORGANISM_ID}.label.image1`)}
        src={proofOfResidency?.getProps().document.documentUrl1 || ''}
      />
      <LabeledImageWithMagnifiedIcon
        key="image2"
        {...commonPropsLabeledImage}
        label={t(`${ORGANISM_ID}.label.image2`)}
        src={proofOfResidency?.getProps().document.documentUrl2 || ''}
      />
    </PropertyListResidentialProofLayout>
  );
};
export default PropertyListResidentialProof;
