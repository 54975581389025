/* eslint-disable no-control-regex */

// Type
import { FileType, ExtensionType } from '../../types/File.type';
import {
  KycRequestUploadFileType,
  KycRequestUploadFileSuffix,
} from '../../types/KycRequest.type';

// Constant
import {
  STORAGE_PATH_CORPORATE_REGISTRY,
  STORAGE_PATH_CORPORATE_ADDRESS,
  STORAGE_PATH_PASSPORT,
  STORAGE_PATH_SELFIE,
  STORAGE_PATH_PROOF_OF_RESIDENCY,
  STORAGE_PATH_CONSENT_FORM,
} from '../../config/constants/cloudStorage';

// DomainObject
import User from '../../domain/public/user/User/User/User';

// Error
import InvalidDataFoundError from '../errors/InvalidDataFoundError';

/*
 * function getFilenameFromPath
 *
 * return filename (with extension)
 *
 */
export const getFilenameFromPath = (path: string): string => {
  const filenameMatch = path.match(/([^/]*)\./);

  const filename =
    filenameMatch && filenameMatch.length > 0 ? filenameMatch[1] : '';

  const extension = path.match(/[^.]+$/);

  return `${filename}.${extension}`;
};

/**
 * function getFilenameFromUrl
 *
 * Extracts the filename from a URL and removes any unnecessary parts (like query parameters).
 *
 * @param url - The URL string to extract the filename from.
 * @returns The sanitized filename with extension.
 */
export const getFilenameFromUrl = (url: string): string => {
  const parsedUrl = new URL(url);
  const { pathname } = parsedUrl;
  const filename = pathname.substring(pathname.lastIndexOf('/') + 1);
  return filename.replace(/[^a-zA-Z0-9.-]/g, '');
};

/*
 * function getExtensionFromFilePath
 *
 * return extension
 *
 */
export const getExtensionFromFilePath = (path: string): string => {
  const extensionMatch = path.match(/[^.]+$/);
  return extensionMatch ? extensionMatch[0] : '';
};

/*
 * function getExtensionFromFileType
 *
 * return file extension
 *
 */
export const getExtensionFromFileType = (
  fileType: FileType,
): ExtensionType | undefined => {
  switch (fileType) {
    case FileType.jpeg:
      return ExtensionType.jpeg;
    case FileType.png:
      return ExtensionType.png;
    default:
      throw new InvalidDataFoundError(
        `Unknown FileType '${fileType}' is found. at getExtensionFromFileType`,
      );
  }
};

/*
 * function getUploadFileUrl
 *
 * return fileUrl
 *
 */
export const getUploadFileUrl = (
  user: User,
  fileType: FileType,
  uploadFileType: KycRequestUploadFileType,
): string => {
  const { email, username } = user.getProps();
  const extension = getExtensionFromFilePath(fileType);

  let storageDirectory = '';

  switch (uploadFileType) {
    case KycRequestUploadFileType.corporateRegistry:
      storageDirectory = STORAGE_PATH_CORPORATE_REGISTRY;
      break;
    case KycRequestUploadFileType.proofOfCorporateAddress:
      storageDirectory = STORAGE_PATH_CORPORATE_ADDRESS;
      break;
    case KycRequestUploadFileType.passport:
      storageDirectory = STORAGE_PATH_PASSPORT;
      break;
    case KycRequestUploadFileType.selfie:
      storageDirectory = STORAGE_PATH_SELFIE;
      break;
    case KycRequestUploadFileType.proofOfResidency1:
      storageDirectory = STORAGE_PATH_PROOF_OF_RESIDENCY;
      break;
    case KycRequestUploadFileType.proofOfResidency2:
      storageDirectory = STORAGE_PATH_PROOF_OF_RESIDENCY;
      break;
    case KycRequestUploadFileType.consentForm:
      storageDirectory = STORAGE_PATH_CONSENT_FORM;
      break;
    default:
      throw new InvalidDataFoundError(
        `Unknown KycRequestUploadFileType '${uploadFileType}' is found. at getUploadFileUrl`,
      );
  }

  const suffix = KycRequestUploadFileSuffix[uploadFileType];

  return `${storageDirectory}/${username}_${email}_${suffix}.${extension}`;
};

/*
 * function
 *
 * return fileUrl
 *
 */
export const excerptFileName = (fileName: string, length: number) => {
  if (fileName.length < length) return fileName;

  const extensionMatch = fileName.match(/[^.]+$/);
  const extension = (extensionMatch && extensionMatch[0]) || '';

  const fileNameWithoutExtension = fileName.replace(`.${extension}`, '');
  const fileNameExcerpted = fileNameWithoutExtension.substr(
    0,
    length - extension.length,
  );

  return `${fileNameExcerpted}... .${extension}`;
};

export const generateFileByInteger8Array = (
  fileName: string,
  type: string,
  integer8Array: number[],
) => {
  const uint8Array = new Uint8Array(integer8Array);

  return new File([uint8Array], fileName, { type });
};

/**
 *
 * fileToBuffer
 *
 * @param file
 * @returns Promise<Buffer>
 */
export const fileToBuffer = async (file: File): Promise<Buffer> => {
  const arrayBuffer = await file.arrayBuffer();
  return Buffer.from(arrayBuffer);
};
