import React, { FC, useContext } from 'react';
import UserAuthorizedContext from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Constant

// Component
import { PageTitle } from 'components/atoms/headLine/HeadLine';
import UserTypeBlackIcon from 'components/atoms/icon/UserTypeBlackIcon';
import HeaderProfileLayout from 'components/organisms/header/HeaderProfile/HeaderProfileLayout';

const HeaderProfile: FC = () => {
  const { userAuthorized: user } = useContext(UserAuthorizedContext);

  const userType = user?.getProps().type;
  const displayName = user?.getDisplayName();

  return (
    <HeaderProfileLayout>
      <UserTypeBlackIcon key="icon" type={userType} width="auto" height="80%" />
      <PageTitle key="name">{displayName || ''}</PageTitle>
    </HeaderProfileLayout>
  );
};

export default HeaderProfile;
