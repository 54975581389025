import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

// Redux
import { addRegisteredAddressEditRequest } from 'redux/reducers/registeredAddressEditRequestsReducer';

// UseCase
import RegisteredAddressEditRequestCreateUseCase from 'useCases/verification/registeredAddressEditRequest/RegisteredAddressEditRequestCreateUseCase';

// Error
import ErrorHandler from 'utils/errors/ErrorHandler/ErrorHandler';
import IErrorHandler from 'utils/errors/ErrorHandler/IErrorHandler';

// Lib
import { checkInternetConnection } from 'utils/helpers/connection';

// Type
import { FormInputCorporateAddress } from 'components/organisms/form/verification/kycRequest/FormCorporateAddress/FormCorporateAddress.type';
import { RegisteredAddressEditRequestType } from 'types/RegisteredAddressEditRequest.type';
import { FormInputProofOfResidency } from 'components/organisms/form/verification/kycRequest/FormProofOfResidency/FormProofOfResidency.type';

// Constant
import { INITIAL, IS_LOADING, SUCCESS } from 'config/constants/requestState';

// Repository
import RegisteredAddressEditRequestFunctions from 'repository/verification/registeredAddressEditRequest/RegisteredAddressEditRequestFunctions';
import CloudStorage from 'storage/cloudStorage/CloudStorage';

// DomainObject
import UserAuthorized from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

export const useRegisteredAddressCorporateEditRequestCreateUseCase = (
  userAuthorized?: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);
  const dispatch = useDispatch();

  /* *
   *
   *  Repository
   *
   * */
  const registeredAddressEditRequestRepository = new RegisteredAddressEditRequestFunctions();
  const storageHandler = new CloudStorage();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new RegisteredAddressEditRequestCreateUseCase(
    registeredAddressEditRequestRepository,
    storageHandler,
  );

  /* *
   *
   *  Method
   *
   * */
  const executeCreate = useCallback(
    async (
      formInput: FormInputCorporateAddress | FormInputProofOfResidency,
      addressEditRequestType: RegisteredAddressEditRequestType,
      proofOfDocument1?: File,
      proofOfDocument2?: File,
    ) => {
      if (!userAuthorized) return;

      setState(IS_LOADING);

      try {
        // ---check onLine ---- //
        checkInternetConnection();

        // [Execution]
        const output = await useCase.create(
          formInput,
          addressEditRequestType,
          proofOfDocument1,
          proofOfDocument2,
        );

        dispatch(
          addRegisteredAddressEditRequest({
            registeredAddressEditRequest: output,
          }),
        );

        setState(SUCCESS);
      } catch (error) {
        const handler: IErrorHandler = new ErrorHandler(error, setState);
        handler.setErrorState();
      }
    },
    // eslint-disable-next-line
    [userAuthorized],
  );

  return {
    state,
    setState,
    executeCreate,
  };
};
