import { useState, useMemo } from 'react';

// DomainObject
import User from 'domain/public/user/User/User/User';

// Type
import { UserType } from 'types/User.type';
import {
  ProfileTabType,
  ProfileTabCorporate,
  ProfileTabIndividual,
} from './ProfileTabType.type';

export const useProfileTabTypeOption = (user?: User, type?: ProfileTabType) => {
  const [profileTabTypeOption, setProfileTabTypeOption] = useState<
    ProfileTabType
  >(type || ProfileTabIndividual.basicInformation);

  const profileTabItems = useMemo(() => {
    if (user?.isUserType(UserType.corporate))
      return Object.keys(ProfileTabCorporate);

    return Object.keys(ProfileTabIndividual);
  }, [user]);

  return {
    profileTabItems: profileTabItems as ProfileTabType[],
    profileTabTypeOption,
    setProfileTabTypeOption,
  };
};
