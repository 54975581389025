import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// Style
import { TitleContainerStyle } from 'components/organisms/header/HeaderProfile/style';

// LayoutComponent
import { FlexBoxRow } from 'components/atoms/utils/layout/Box/FlexBox';
import Margin from 'components/atoms/utils/layout/Margin';

export interface HeaderProfileLayoutProps {
  children: React.ReactElement[];
}

const HeaderProfileLayout: FC<HeaderProfileLayoutProps> = ({
  children,
}: HeaderProfileLayoutProps) => {
  const elements = ['icon', 'name'];
  const [icon, name] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxRow
      alignItems="start"
      height={66}
      width={1200}
      justifyContent="space-between"
      theme={TitleContainerStyle}
    >
      <FlexBoxRow alignItems="start" justifyContent="flex-start" width="auto">
        {icon}
        <Margin left={20} />
        {name}
      </FlexBoxRow>
    </FlexBoxRow>
  );
};
export default HeaderProfileLayout;
