import styled from 'styled-components';
import NoImage from '../../../../assets/images/no_image.png';

export const ImageContainer = styled.div`
  position: relative;
  top: 0;
  background-image: url(${NoImage});
  background-size: cover;
  border: ${1 / 16}rem solid #d8d8d8;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const IconContainer = styled.div`
  position: absolute;
  top: ${20 / 16}rem;
  left: ${370 / 16}rem;
`;
