import React, { FC } from 'react';

// Component
import { TextPrimary, TextThemeProps } from 'components/atoms/text2/Text2';
import ImageWithMagnifiedIcon from '../ImageWithMagnifiedIcon';
import LabeledImageWithMagnifiedIconLayout from './LabeledImageWithMagnifiedIconLayout';

export interface LabeledImageWithMagnifiedIconProps {
  label: string;
  marginBetween?: string | number;
  layout: {
    image: {
      width: string | number;
      height: string | number;
    };
    marginBetween?: string | number;
  };
  src: string;
  theme: {
    label: TextThemeProps;
  };
}

const LabeledImageWithMagnifiedIcon: FC<LabeledImageWithMagnifiedIconProps> = ({
  label,
  layout,
  src,
  theme,
}: LabeledImageWithMagnifiedIconProps) => {
  return (
    <LabeledImageWithMagnifiedIconLayout marginBetween={layout.marginBetween}>
      <TextPrimary key="label" theme={theme.label}>
        {label}
      </TextPrimary>
      <ImageWithMagnifiedIcon key="image" layout={layout} src={src} />
    </LabeledImageWithMagnifiedIconLayout>
  );
};

export default LabeledImageWithMagnifiedIcon;
