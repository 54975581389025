import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import UserAuthorizedContext from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';

// Type
import { commonPropsLabeledText } from 'components/organisms/propertyList/user/PropertyListCustomerDetail/PropertyListCustomerDetail.type';

// Style
import { TitleStyle } from 'components/organisms/propertyList/user/PropertyListCustomerDetail/style';

// Component
import { TextSecondary } from 'components/atoms/text2/Text2';
import LabeledText from 'components/molecules/textGroup/LabeledText';
import PropertyListCustomerBasicInformationLayout from './PropertyListCustomerBasicInformationLayout';

const ORGANISM_ID = `organisms.propertyList.user.PropertyListCustomerBasicInformation`;

const PropertyListCustomerBasicInformation: FC = () => {
  const { t } = useTranslation();
  const { userAuthorized: user } = useContext(UserAuthorizedContext);

  return (
    <PropertyListCustomerBasicInformationLayout>
      <TextSecondary key="title" theme={TitleStyle}>
        {t(`${ORGANISM_ID}.title`)}
      </TextSecondary>
      <LabeledText
        key="contractName"
        {...commonPropsLabeledText}
        label={t(`${ORGANISM_ID}.label.contractName`)}
        value={user?.getProps().contractName || ''}
      />
      <LabeledText
        key="email"
        {...commonPropsLabeledText}
        label={t(`${ORGANISM_ID}.label.email`)}
        value={user?.getProps().email || ''}
      />
      <LabeledText
        key="userId"
        {...commonPropsLabeledText}
        label={t(`${ORGANISM_ID}.label.userId`)}
        value={user?.getId() || ''}
      />
      <LabeledText
        key="username"
        {...commonPropsLabeledText}
        label={t(`${ORGANISM_ID}.label.username`)}
        value={user?.getProps().username || ''}
      />
      <LabeledText
        key="userType"
        {...commonPropsLabeledText}
        label={t(`${ORGANISM_ID}.label.userType`)}
        value={user?.getUserTypeCapitalized() || ''}
      />
      <LabeledText
        key="rank"
        {...commonPropsLabeledText}
        label={t(`${ORGANISM_ID}.label.rank`)}
        value={user?.getRawRank() || ''}
      />
      <LabeledText
        key="status"
        {...commonPropsLabeledText}
        label={t(`${ORGANISM_ID}.label.status`)}
        value={user?.getStatusCapitalized() || ''}
      />
    </PropertyListCustomerBasicInformationLayout>
  );
};
export default PropertyListCustomerBasicInformation;
