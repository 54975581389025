// Redux
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Type
import { RegisteredAddressEditRequestRawDataType } from 'domain/verification/registeredAddressEditRequest/RegisteredAddressEditRequest/RegisteredAddressEditRequest.type';

export interface RegisteredAddressEditRequestsState {
  list: RegisteredAddressEditRequestRawDataType[] | undefined;
}

// eslint-disable-next-line
const initialState: any = {
  list: undefined,
};

/* eslint-disable no-param-reassign */
const registeredAddressEditRequestsSlice = createSlice({
  name: 'registeredAddressEditRequests',
  initialState,
  reducers: {
    setRegisteredAddressEditRequests: (
      state: RegisteredAddressEditRequestsState,
      action: PayloadAction<{
        registeredAddressEditRequests: RegisteredAddressEditRequestRawDataType[];
      }>,
    ) => ({
      ...state,
      list: action.payload.registeredAddressEditRequests,
    }),

    addRegisteredAddressEditRequest: (
      state: RegisteredAddressEditRequestsState,
      action: PayloadAction<{
        registeredAddressEditRequest: RegisteredAddressEditRequestRawDataType;
      }>,
    ) => {
      if (state.list)
        state.list.push(action.payload.registeredAddressEditRequest);
    },
  },
});

export const {
  setRegisteredAddressEditRequests,
  addRegisteredAddressEditRequest,
} = registeredAddressEditRequestsSlice.actions;
export default registeredAddressEditRequestsSlice.reducer;
