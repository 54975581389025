// Type
import { KycStatusType } from 'types/KycRequest.type';
import { RegisteredAddressEditRequestStatusType } from 'types/RegisteredAddressEditRequest.type';
import {
  RegisteredAddressEditRequestProps,
  RegisteredAddressEditRequestPropsFormat,
} from '../RegisteredAddressEditRequest/RegisteredAddressEditRequest.type';

export interface RegisteredAddressResidentialEditRequestProps
  extends RegisteredAddressEditRequestProps {
  status: RegisteredAddressEditRequestStatusType & KycStatusType;
}

export const RegisteredAddressResidentialEditRequestPropsFormat = RegisteredAddressEditRequestPropsFormat;
