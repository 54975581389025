import { useCallback, useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { checkInternetConnection } from 'utils/helpers/connection';
import { INITIAL, IS_LOADING, SUCCESS } from 'config/constants/requestState';
import ErrorHandler from 'utils/errors/ErrorHandler/ErrorHandler';

// Redux
import { setRegisteredAddressEditRequests } from 'redux/reducers/registeredAddressEditRequestsReducer';

// Type
import { RegisteredAddressEditRequestType } from 'types/RegisteredAddressEditRequest.type';

// UseCase
import RegisteredAddressIndividualEditViewUseCase from 'useCases/verification/registeredAddressEditRequest/RegisteredAddressIndividualEditViewUseCase';

// Repository
import DocumentTypeFirestore from 'repository/settings/documentType/DocumentTypeFirestore';
import KycRequestFirestore from 'repository/verification/kycRequest/KycRequestFirestore';
import RegisteredAddressEditRequestFunctions from 'repository/verification/registeredAddressEditRequest/RegisteredAddressEditRequestFunctions';
import CloudStorage from 'storage/cloudStorage/CloudStorage';

// DomainObject
import { useKycRequest } from 'domain/verification/kycRequest/KycRequest/KycRequest/useKycRequest';
import { useDocumentTypes } from 'domain/settings/documentType/DocumentType/DocumentTypes/useDocumentTypes';
import { useRegisteredAddressEditRequests } from 'domain/verification/registeredAddressEditRequest/RegisteredAddressEditRequests/useRegisteredAddressEditRequests';
import RegisteredAddressResidentialEditRequest from 'domain/verification/registeredAddressEditRequest/RegisteredAddressResidentialEditRequest/RegisteredAddressResidentialEditRequest';
import UserAuthorized from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

// File
import { useProofOfResidencyFile } from 'enhancers/fileHandler/verification/kycRequest/ProofOfResidencyFileProvider/useProofOfResidencyFile';

export const useRegisteredAddressIndividualEditViewUseCase = (
  userAuthorized?: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);
  const dispatch = useDispatch();

  // File
  const {
    proofOfDocument1,
    setProofOfDocument1,
    proofOfDocument2,
    setProofOfDocument2,
  } = useProofOfResidencyFile();

  /* *
   *
   *  DomainObject
   *
   * */
  const { kycRequest, setKycRequest } = useKycRequest();
  const { documentTypes, setDocumentTypes } = useDocumentTypes();
  const registeredAddressEditRequests = useRegisteredAddressEditRequests();

  // Sort
  const documentTypesSorted = useMemo(() => documentTypes.sortAscByPriority(), [
    documentTypes,
  ]);

  /* *
   *
   *  Repository
   *
   * */
  const kycRequestRepository = new KycRequestFirestore();
  const documentTypeRepository = new DocumentTypeFirestore();
  const registeredAddressEditRequestRepository = new RegisteredAddressEditRequestFunctions();
  const storageHandler = new CloudStorage();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new RegisteredAddressIndividualEditViewUseCase(
    documentTypeRepository,
    kycRequestRepository,
    registeredAddressEditRequestRepository,
    storageHandler,
  );

  /* *
   *
   *  Method
   *
   * */
  const open = useCallback(async () => {
    if (!userAuthorized) return;

    setState(IS_LOADING);
    try {
      // Is ONLINE?
      checkInternetConnection();

      // UseCase
      const output = await useCase.open(userAuthorized);

      setKycRequest(output.kycRequest);
      setDocumentTypes(output.documentTypes);

      dispatch(
        setRegisteredAddressEditRequests({
          registeredAddressEditRequests: output.registeredAddressEditRequests,
        }),
      );

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [userAuthorized]);

  /* *
   *
   *  UseCase
   *
   * */
  useEffect(() => {
    let isMounted = true;

    if (userAuthorized && isMounted) open();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [userAuthorized]);

  // Filter
  const registeredAddressResidentialEditRequest = useMemo(
    () =>
      registeredAddressEditRequests &&
      (registeredAddressEditRequests
        .filterByRequestType(RegisteredAddressEditRequestType.proofOfResidency)
        .head() as RegisteredAddressResidentialEditRequest),
    [registeredAddressEditRequests],
  );

  const isUnderReview = useMemo(
    () => !!registeredAddressResidentialEditRequest,
    [registeredAddressResidentialEditRequest],
  );

  const proofOfResidency = useMemo(
    () =>
      registeredAddressResidentialEditRequest?.getProofOfResidency() ||
      kycRequest?.getProofOfResidency(),
    [kycRequest, registeredAddressResidentialEditRequest],
  );

  // Get Document URL
  const { documentUrl1, documentUrl2 } = useMemo(
    () =>
      proofOfResidency?.getProps().document || {
        documentUrl1: undefined,
        documentUrl2: undefined,
      },
    [proofOfResidency],
  );

  const documentUrls = useMemo(() => {
    const urls = [];
    if (documentUrl1) urls.push(documentUrl1);
    if (documentUrl2) urls.push(documentUrl2);
    return urls;
  }, [documentUrl1, documentUrl2]);

  // Download file
  const downloadDocument = useCallback(async () => {
    if (documentUrls.length === 0) return;
    try {
      // Is ONLINE?
      checkInternetConnection();

      // UseCase
      const [document1, document2] = await useCase.downloadDocuments(
        documentUrls,
      );

      setProofOfDocument1(document1);
      setProofOfDocument2(document2);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [documentUrls]);

  useEffect(() => {
    if (documentUrls.length > 0 && isUnderReview && state === SUCCESS)
      downloadDocument();
    // eslint-disable-next-line
  }, [documentUrls, isUnderReview, state]);

  return {
    state,
    setState,
    kycRequest,
    documentTypes: documentTypesSorted,
    registeredAddressResidentialEditRequest,
    proofOfResidencyFileContext: {
      proofOfDocument1,
      setProofOfDocument1,
      proofOfDocument2,
      setProofOfDocument2,
    },
  };
};
