import { capitalize } from 'lodash';

// Type
import { UserProps } from 'domain/public/user/User/User/User.type';
import { UserRoleType, UserType, UserStatusType } from 'types/User.type';

// ValueObject
import Rank from 'value/invite/rank/Rank';
import RankFactory from 'value/invite/rank/RankFactory';
import UserStatus from 'value/public/user/UserStatus/UserStatus';
import ShippingAddress from 'value/personalInformation/address/Address/ShippingAddress/ShippingAddress';

class User {
  protected id: string;

  protected canInvite: boolean;

  protected contractName?: string;

  protected representativeName?: string;

  protected email: string;

  protected username: string;

  protected isDeleted: boolean;

  protected isVIP: boolean;

  protected role: UserRoleType;

  protected type?: UserType;

  protected rank: Rank;

  protected status: UserStatus;

  protected topLevelInviterId?: string;

  protected secondLevelInviterId?: string;

  private shippingAddress?: ShippingAddress;

  protected createdAt: number | undefined;

  protected updatedAt: number | undefined;

  protected props: UserProps;

  constructor(id: string, props: UserProps) {
    this.props = props;
    this.id = id;
    this.canInvite = props.canInvite;
    this.contractName = props.contractName;
    this.representativeName = props.representativeName;
    this.email = props.email;
    this.username = props.username;
    this.isDeleted = props.isDeleted;
    this.role = props.role;
    this.type = props.type;
    this.isVIP = props.isVIP;
    this.rank = RankFactory.create(props.rank);
    this.topLevelInviterId = props.topLevelInviterId;
    this.secondLevelInviterId = props.secondLevelInviterId;
    this.shippingAddress =
      props.shippingAddress && new ShippingAddress(props.shippingAddress);
    this.status = new UserStatus(props.status);
    this.createdAt = props.createdAt;
    this.updatedAt = props.updatedAt;
  }

  public getProps() {
    return {
      ...this.props,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }

  public getId = () => this.id;

  public getDisplayName = () => this.contractName || this.username;

  public getRawRank = () => this.rank.getRank();

  public getUserTypeCapitalized = () => this.type && capitalize(this.type);

  public getStatusCapitalized = () => capitalize(this.status.getStatus());

  public getShippingAddress = () => this.shippingAddress;

  public isActive = () => !this.isDeleted;

  public isVIPUser = () => this.isVIP;

  public isRole = (role: UserRoleType) => role === this.role;

  public isStatus = (status: UserStatusType) => this.status.isStatus(status);

  public isRankD = () => this.rank.isRankD();

  public isRankA = () => this.rank.isRankA();

  public isRankC = () => this.rank.isRankC();

  public getRawStatus = () => this.status.getStatus();

  public updateUserType = (userType: UserType) => {
    const userUpdated = new User(this.id, {
      ...this.props,
      type: userType,
    });

    return userUpdated;
  };

  public isUserType = (type: UserType) => this.type === type;

  public isIncludedId = (ids: Array<string>) => ids.includes(this.id);
}
export default User;
