// Error
import SystemErrorFactory from 'errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from 'errors/ErrorMessage/ErrorMessage';

// Type
import { RegisteredAddressEditRequestProps } from 'domain/verification/registeredAddressEditRequest/RegisteredAddressEditRequest/RegisteredAddressEditRequest.type';
import { RegisteredAddressEditRequestType } from 'types/RegisteredAddressEditRequest.type';
import { RegisteredAddressCorporateEditRequestProps } from 'domain/verification/registeredAddressEditRequest/RegisteredAddressCorporateEditRequest/RegisteredAddressCorporateEditRequest.type';

// DomainObject
import RegisteredAddressCorporateEditRequestFactory from 'domain/verification/registeredAddressEditRequest/RegisteredAddressCorporateEditRequest/RegisteredAddressCorporateEditRequestFactory';
import RegisteredAddressResidentialEditRequestFactory from 'domain/verification/registeredAddressEditRequest/RegisteredAddressResidentialEditRequest/RegisteredAddressResidentialEditRequestFactory';

class RegisteredAddressEditRequestFactory {
  static create = (id: string, props: RegisteredAddressEditRequestProps) => {
    const { addressEditRequestType } = props;

    switch (addressEditRequestType) {
      case RegisteredAddressEditRequestType.proofOfResidency:
        return RegisteredAddressResidentialEditRequestFactory.create(
          id,
          props as RegisteredAddressCorporateEditRequestProps,
        );
      case RegisteredAddressEditRequestType.corporateAddress:
        return RegisteredAddressCorporateEditRequestFactory.create(
          id,
          props as RegisteredAddressCorporateEditRequestProps,
        );
      default:
        throw SystemErrorFactory.createByErrorId(
          ErrorIdType.INVALID_ARGUMENT_SWITCH,
          {
            value: addressEditRequestType,
            place: `RegisteredAddressEditRequestFactory.create`,
          },
        );
    }
  };
}

export default RegisteredAddressEditRequestFactory;
