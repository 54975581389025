/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

// Type
import { FormInputCorporateAddress } from 'components/organisms/form/settings/FormRegisteredAddressEditRequestAdd/FormCorporateAddress/FormCorporateAddress.type';
import { FormInputProofOfResidency } from 'components/organisms/form/settings/FormRegisteredAddressEditRequestAdd/FormProofOfResidency/FormProofOfResidency.type';
import { RegisteredAddressEditRequestType } from 'types/RegisteredAddressEditRequest.type';

// Constant
import { INITIAL } from 'config/constants/requestState';

export interface RegisteredAddressEditRequestCreateContextProps {
  state: string;
  executeCreate: (
    formInput: FormInputCorporateAddress | FormInputProofOfResidency,
    addressEditRequestType: RegisteredAddressEditRequestType,
    document1?: File,
    document2?: File,
  ) => Promise<void>;
}

const RegisteredAddressEditRequestCreateContext = React.createContext<
  RegisteredAddressEditRequestCreateContextProps
>({
  state: INITIAL,
  executeCreate: () => Promise.resolve(),
});

export default RegisteredAddressEditRequestCreateContext;
