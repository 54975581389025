// Functions
import FirebasePrivateFunctions from 'infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';

// IRepository
import IRegisteredAddressEditRequestRepository, {
  ParametersSubmitCorporateAddress,
} from 'domain/verification/registeredAddressEditRequest/IRegisteredAddressEditRequestRepository';

// Constant
import {
  CREATE_REGISTERED_ADDRESS_EDIT_REQUEST,
  GET_REGISTERED_ADDRESS_EDIT_REQUESTS_PENDING_RAW_ALL,
} from 'infrastructure/firebase/firebaseFunctions/endpoint';

// DomainObject
import RegisteredAddressEditRequestsFactory from 'domain/verification/registeredAddressEditRequest/RegisteredAddressEditRequests/RegisteredAddressEditRequestsFactory';

class RegisteredAddressEditRequestFunctions
  extends FirebasePrivateFunctions
  implements IRegisteredAddressEditRequestRepository {
  public findAllPendingByUserId = async (userId: string) => {
    // Replace userId
    const endpoint = GET_REGISTERED_ADDRESS_EDIT_REQUESTS_PENDING_RAW_ALL.replace(
      /:userId/,
      userId,
    );

    const response = await this.privateGET(endpoint);

    return RegisteredAddressEditRequestsFactory.createRawDataFromRegisteredAddressEditRequestsRawData(
      response.data.data,
    );
  };

  public create = async (params: ParametersSubmitCorporateAddress) => {
    const endpoint = CREATE_REGISTERED_ADDRESS_EDIT_REQUEST;
    const response = await this.privatePOST(endpoint, params);

    return response.data.data;
  };
}
export default RegisteredAddressEditRequestFunctions;
