import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxColumn } from 'components/atoms/utils/layout/Box/FlexBox';
import Margin from 'components/atoms/utils/layout/Margin';

export interface FormCorporateAddressProofUploadLayoutProps {
  children: React.ReactElement[];
}

const FormCorporateAddressProofUploadLayout: FC<FormCorporateAddressProofUploadLayoutProps> = ({
  children,
}: FormCorporateAddressProofUploadLayoutProps) => {
  const elements = ['description', 'dropzone', 'checkPoint'];
  const [description, dropzone, checkPoint] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxColumn width={1200} height="auto">
      {description}
      <Margin top={60} />
      {dropzone}
      <Margin top={40} />
      {checkPoint}
    </FlexBoxColumn>
  );
};
export default FormCorporateAddressProofUploadLayout;
