import Joi from '@hapi/joi';

// Error
import SystemErrorFactory from 'errors/ErrorFactory/SystemErrorFactory';
import { ErrorIdType } from 'errors/ErrorMessage/ErrorMessage';

// // Type
import {
  RegisteredAddressResidentialEditRequestProps,
  RegisteredAddressResidentialEditRequestPropsFormat,
} from 'domain/verification/registeredAddressEditRequest/RegisteredAddressResidentialEditRequest/RegisteredAddressResidentialEditRequest.type';

// // DomainObject
import RegisteredAddressResidentialEditRequest from './RegisteredAddressResidentialEditRequest';

class RegisteredAddressResidentialEditRequestFactory {
  static create = (
    id: string,
    props: RegisteredAddressResidentialEditRequestProps,
  ) => {
    const result = Joi.object(
      RegisteredAddressResidentialEditRequestPropsFormat,
    ).validate({
      ...props,
      id,
    });

    if (result.error)
      throw SystemErrorFactory.createByErrorId(
        ErrorIdType.INVALID_PROPS_DOMAIN_OBJECT_FACTORY,
        {
          domain: RegisteredAddressResidentialEditRequest.name,
          reason: result.error.details[0].message,
        },
      );

    return new RegisteredAddressResidentialEditRequest(id, props);
  };
}

export default RegisteredAddressResidentialEditRequestFactory;
