import React, { FC, useContext, useMemo } from 'react';
import RegisteredAddressEditRequestCreateContext from 'enhancers/useCase/setting/registeredAddressEditRequest/RegisteredAddressEditRequestCreateProvider/RegisteredAddressEditRequestCreateContext';

// Constant
import { SUCCESS } from 'config/constants/requestState';

// Component
import DashboardTemplate from 'components/templates/common/DashboardTemplate';
import RegisteredCorporateAddressEditTemplate from 'components/templates/settings/RegisteredCorporateAddressEditTemplate';
import RegisteredAddressEditPendingTemplate from 'components/templates/settings/RegisteredAddressEditPendingTemplate';

const RegisteredAddressCorporateEditPage: FC = () => {
  const { state } = useContext(RegisteredAddressEditRequestCreateContext)

  const TemplateComponent = useMemo(() => {
    if (state === SUCCESS) return <RegisteredAddressEditPendingTemplate />;

    return <RegisteredCorporateAddressEditTemplate />;
  }, [state]);

  return (
    <DashboardTemplate>
      {TemplateComponent}
    </DashboardTemplate>
  );
};

export default RegisteredAddressCorporateEditPage;
