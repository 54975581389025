import React from 'react';

// Type
import {
  RegisteredAddressTab,
  RegisteredAddressTabType,
} from './RegisteredAddressTabType.type';

export interface RegisteredAddressTabTypeOptionContextProps {
  registeredAddressTabTypeOption: RegisteredAddressTabType;
  setRegisteredAddressTabTypeOption: (type: RegisteredAddressTabType) => void;
}

const RegisteredAddressTabTypeOptionContext = React.createContext<
  RegisteredAddressTabTypeOptionContextProps
>({
  registeredAddressTabTypeOption: RegisteredAddressTab.corporateInformation,
  setRegisteredAddressTabTypeOption: () => undefined,
});

export default RegisteredAddressTabTypeOptionContext;
