import React, { FC } from 'react';

// Component
import HeaderProfile from 'components/organisms/header/HeaderProfile';
import TabBarProfile from 'components/organisms/tabBar/TabBarProfile';
import SwitchProfileContent from './SwitchProfileContent';
import ProfileTemplateLayout from './ProfileTemplateLayout';

const ProfileTemplate: FC = () => {
  return (
    <ProfileTemplateLayout>
      <HeaderProfile key="header" />
      <TabBarProfile key="tab" />
      <SwitchProfileContent key="content" />
    </ProfileTemplateLayout>
  );
};
export default ProfileTemplate;
