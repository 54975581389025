import React, { FC } from 'react';

// Style
import { LabelStyle } from './style';

// DomainObject
import { TextPrimary } from '../../../../../../atoms/text2/Text2';
import DocumentTypeSelectBox from '../../../../../selectBox/selectBox/verification/DocumentTypeSelectBox';
import FormInputDocumentTypeSelectBoxLayout from './FormInputDocumentTypeSelectBoxLayout';

interface FormInputDocumentTypeSelectBoxProps {
  label: string;
  inputWidth: string | number;
  name: string;
  isDisabled?: boolean;
}

const FormInputDocumentTypeSelectBox: FC<FormInputDocumentTypeSelectBoxProps> = ({
  label,
  inputWidth,
  name,
  isDisabled,
}: FormInputDocumentTypeSelectBoxProps) => {
  return (
    <FormInputDocumentTypeSelectBoxLayout inputWidth={inputWidth}>
      <TextPrimary key="label" theme={LabelStyle}>
        {label}
      </TextPrimary>
      <DocumentTypeSelectBox key="input" name={name} isDisabled={isDisabled} />
    </FormInputDocumentTypeSelectBoxLayout>
  );
};

export default FormInputDocumentTypeSelectBox;
