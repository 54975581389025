// Type
import { CorporateAddressProps } from 'value/verification/kycRequest/KycRequestContent/CorporateAddress/CorporateAddress.type';
import { RegisteredAddressCorporateEditRequestProps } from 'domain/verification/registeredAddressEditRequest/RegisteredAddressCorporateEditRequest/RegisteredAddressCorporateEditRequest.type';

// DomainObject
import RegisteredAddressEditRequest from 'domain/verification/registeredAddressEditRequest/RegisteredAddressEditRequest/RegisteredAddressEditRequest';

// ValueObject
import CorporateAddress from 'value/verification/kycRequest/KycRequestContent/CorporateAddress/CorporateAddress';
import CorporateAddressFactory from 'value/verification/kycRequest/KycRequestContent/CorporateAddress/CorporateAddressFactory';

class RegisteredAddressCorporateEditRequest extends RegisteredAddressEditRequest {
  private corporateAddress: CorporateAddress;

  constructor(id: string, props: RegisteredAddressCorporateEditRequestProps) {
    super(id, props);
    this.corporateAddress = CorporateAddressFactory.create({
      country: props.country,
      addressLine1: props.addressLine1,
      addressLine2: props.addressLine2,
      city: props.city,
      state: props.state,
      postalCode: props.postalCode,
      status: props.status,
      document: props.document,
    } as CorporateAddressProps);
  }

  public getCorporateAddress = () => this.corporateAddress;
}
export default RegisteredAddressCorporateEditRequest;
