import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxColumn } from 'components/atoms/utils/layout/Box/FlexBox';
import Margin from 'components/atoms/utils/layout/Margin';

export interface ProfileCorporateInformationTemplateLayoutProps {
  children: React.ReactElement[];
}

const ProfileCorporateInformationTemplateLayout: FC<ProfileCorporateInformationTemplateLayoutProps> = ({
  children,
}: ProfileCorporateInformationTemplateLayoutProps) => {
  const elements = [
    'corporateInformation',
    'corporateRegistry',
    'corporateAddress',
  ];
  const [
    corporateInformation,
    corporateRegistry,
    corporateAddress,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn>
      {corporateInformation}
      <Margin top={80} />
      {corporateRegistry}
      <Margin top={80} />
      {corporateAddress}
    </FlexBoxColumn>
  );
};

export default ProfileCorporateInformationTemplateLayout;
