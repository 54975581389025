import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Type
import { DebitCardRenewalCancelRequestCherryVisa01StepStatusType } from 'types/DebitCardRenewalCancelRequestCherryVisa01.type';

// Constant
import colorUtil from 'components/atoms/utils/const/color';

// Style
import { TextStyle } from 'components/molecules/badge/DebitCardRenewalCancelRequestStatusBadge/style';

// DomainObject
import { TextSecondary } from 'components/atoms/text2/Text2';
import DebitCardRenewalCancelRequestStatusBadgeLayout from 'components/molecules/badge/DebitCardRenewalCancelRequestStatusBadge/DebitCardRenewalCancelRequestStatusBadgeLayout';
import DebitCardRenewalCancelRequestStatusIcon from 'components/atoms/icon/DebitCardRenewalCancelRequestStatusIcon';

export interface DebitCardRenewalCancelRequestStepStatusTypeBadgeProps {
  status?: DebitCardRenewalCancelRequestCherryVisa01StepStatusType;
  layout: {
    height: string | number;
    iconWidth: string | number;
    iconHeight: string | number;
  };
}

const DebitCardRenewalCancelRequestStepStatusTypeBadge: FC<DebitCardRenewalCancelRequestStepStatusTypeBadgeProps> = ({
  status,
  layout,
}: DebitCardRenewalCancelRequestStepStatusTypeBadgeProps) => {
  const { t } = useTranslation();

  const displayBadgeStatus = [
    DebitCardRenewalCancelRequestCherryVisa01StepStatusType.waitingFPBM,
    DebitCardRenewalCancelRequestCherryVisa01StepStatusType.submitted,
    DebitCardRenewalCancelRequestCherryVisa01StepStatusType.rejected,
    DebitCardRenewalCancelRequestCherryVisa01StepStatusType.paid,
  ];

  if (status && !displayBadgeStatus.includes(status)) return <></>;

  let [backgroundColor, color] = ['', ''];

  switch (status) {
    case DebitCardRenewalCancelRequestCherryVisa01StepStatusType.paid:
      [backgroundColor, color] = [
        colorUtil.background.saltpan,
        colorUtil.text.atlantis,
      ];
      break;
    case DebitCardRenewalCancelRequestCherryVisa01StepStatusType.rejected:
      [backgroundColor, color] = [
        colorUtil.background.mistyRose,
        colorUtil.text.cinnabar,
      ];
      break;
    case DebitCardRenewalCancelRequestCherryVisa01StepStatusType.submitted:
      [backgroundColor, color] = [
        colorUtil.background.pattensBlue3,
        colorUtil.text.summerSky,
      ];
      break;
    case DebitCardRenewalCancelRequestCherryVisa01StepStatusType.waitingFPBM:
      [backgroundColor, color] = [
        colorUtil.background.varden,
        colorUtil.text.lightningYellow,
      ];
      break;
    default:
      [backgroundColor, color] = ['', ''];
  }

  return (
    <DebitCardRenewalCancelRequestStatusBadgeLayout
      backgroundColor={backgroundColor}
      height={layout.height}
    >
      <DebitCardRenewalCancelRequestStatusIcon
        key="icon"
        status={status}
        height={layout.iconHeight}
        width={layout.iconWidth}
      />
      <TextSecondary
        key="text"
        theme={{
          ...TextStyle,
          color,
        }}
      >
        {t(`debitCardRenewalCancelRequestStepStatusType.${status}`)}
      </TextSecondary>
    </DebitCardRenewalCancelRequestStatusBadgeLayout>
  );
};

export default DebitCardRenewalCancelRequestStepStatusTypeBadge;
