import fontWeight from 'components/atoms/utils/const/fontWeight';
import color from 'components/atoms/utils/const/color';

export const SelectedBoxStyle = {
  backgroundColor: color.background.white,
  border: `${1 / 16}rem solid ${color.border.lightBlue}`,
  cursor: 'pointer',
};

export const SelectedTextStyle = {
  fontSize: 23,
  color: color.text.primary,
  fontWeight: fontWeight.medium,
  lineHeight: 29,
  textAlign: 'center',
};

export const UnSelectedBoxStyle = {
  backgroundColor: color.background.ghostWhite,
  border: `${1 / 16}rem solid ${color.border.periwinkle}`,
  cursor: 'pointer',
};

export const UnSelectedTextStyle = {
  fontSize: 23,
  color: color.text.primary,
  fontWeight: fontWeight.medium,
  opacity: 0.3,
  lineHeight: 29,
  textAlign: 'center',
};
