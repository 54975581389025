import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Type
import {
  DebitCardRenewalCancelRequestCherryVisa01StepType,
  DebitCardRenewalCancelRequestCherryVisa01StepStatusType,
} from 'types/DebitCardRenewalCancelRequestCherryVisa01.type';

// Style
import {
  TitleStyle,
  DescriptionStyle,
  ButtonTextStyle,
  IconStyle,
} from 'components/organisms/card/verification/DebitCardRenewalCancelRequestStatusCard/style';

// Component
import { TextPrimary, TextSecondary } from 'components/atoms/text2/Text2';
import DebitCardRenewalCancelRequestStatusSquareButton from 'components/atoms/button/DebitCardRenewalCancelRequestStatusSquareButton';
import DebitCardRenewalCancelRequestStepBadge from 'components/molecules/badge/DebitCardRenewalCancelRequestStatusBadge';
import LineBreakReplaced from 'components/atoms/text2/LineBreakReplaced';
import DebitCardRenewalCancelRequestStatusCardLayout from 'components/organisms/card/verification/DebitCardRenewalCancelRequestStatusCard/DebitCardRenewalCancelRequestStatusCardLayout';

const ORGANISM_ID = `organisms.card.DebitCardRenewalCancelRequestStatusCard`;

export interface DebitCardRenewalCancelRequestStatusCardProps {
  debitCardRequestStepType: DebitCardRenewalCancelRequestCherryVisa01StepType;
  status: DebitCardRenewalCancelRequestCherryVisa01StepStatusType;
  onClick: () => void;
}

const DebitCardRenewalCancelRequestStatusCard: FC<DebitCardRenewalCancelRequestStatusCardProps> = ({
  debitCardRequestStepType,
  status,
  onClick,
}: DebitCardRenewalCancelRequestStatusCardProps) => {
  const { t } = useTranslation();

  return (
    <DebitCardRenewalCancelRequestStatusCardLayout
      status={status}
      debitCardRequestStepType={debitCardRequestStepType}
    >
      <TextPrimary key="title" theme={TitleStyle}>
        {t(`${ORGANISM_ID}.stepDescription.${debitCardRequestStepType}.title`)}
      </TextPrimary>
      <DebitCardRenewalCancelRequestStepBadge
        key="statusBadge"
        status={status}
        layout={{
          height: 40,
          iconWidth: 18,
          iconHeight: 18,
        }}
      />
      <TextSecondary key="description" theme={DescriptionStyle}>
        <LineBreakReplaced
          text={t(
            `${ORGANISM_ID}.stepDescription.${debitCardRequestStepType}.description`,
          )}
        />
      </TextSecondary>
      <DebitCardRenewalCancelRequestStatusSquareButton
        key="button"
        height="100%"
        marginBetween={10}
        onClick={onClick}
        status={status}
        theme={{
          text: ButtonTextStyle,
          icon: IconStyle,
        }}
        width="100%"
      />
    </DebitCardRenewalCancelRequestStatusCardLayout>
  );
};
export default DebitCardRenewalCancelRequestStatusCard;
