import { useCallback, useState } from 'react';

// Error
import ErrorHandler from 'utils/errors/ErrorHandler/ErrorHandler';
import IErrorHandler from 'utils/errors/ErrorHandler/IErrorHandler';

// Lib
import { checkInternetConnection } from 'utils/helpers/connection';

// Constant
import { INITIAL, IS_LOADING, SUCCESS } from 'config/constants/requestState';

// Repository
import DebitCardRenewalCancelRequestCherryVisa01Functions from 'repository/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01Functions';

// UseCase
import DebitCardRenewalRequestCherryVisa01CancelConfirmUseCase from 'useCases/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalRequestCherryVisa01CancelConfirmUseCase';

// DomainObject
import DebitCardRenewalRequest from 'domain/debitCard/debitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequest/DebitCardRenewalRequest';
import DebitCardRenewalCancelRequestCherryVisa01 from 'domain/debitCard/debitCardRenewalCancelRequest/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01/DebitCardRenewalCancelRequestCherryVisa01';

export const useDebitCardRenewalRequestCherryVisa01CancelConfirmUseCase = (
  debitCardRenewalRequest: DebitCardRenewalRequest | undefined,
) => {
  const [state, setState] = useState<string>(INITIAL);

  /* *
   *
   *  Repository
   *
   * */
  const debitCardRenewalRequestCherryVisa01Functions = new DebitCardRenewalCancelRequestCherryVisa01Functions();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new DebitCardRenewalRequestCherryVisa01CancelConfirmUseCase(
    debitCardRenewalRequestCherryVisa01Functions,
  );

  /* *
   *
   *  Method
   *
   * */
  const cancel = useCallback(
    async (
      callback?: (
        debitCardRenewalCancelRequest: DebitCardRenewalCancelRequestCherryVisa01,
      ) => void,
    ) => {
      if (!debitCardRenewalRequest) return;

      setState(IS_LOADING);

      try {
        // ---check onLine ---- //
        checkInternetConnection();

        const output = await useCase.cancel(debitCardRenewalRequest);

        setState(SUCCESS);

        if (callback) callback(output);
      } catch (error) {
        const handler: IErrorHandler = new ErrorHandler(error, setState);
        handler.setErrorState();
      }
    },
    // eslint-disable-next-line
    [debitCardRenewalRequest],
  );

  return {
    state,
    setState,
    cancel,
  };
};
