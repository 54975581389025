import React, { FC } from 'react';
import { FlexBoxRow } from 'components/atoms/utils/layout/Box/FlexBox';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexColumn, FlexRow } from '../../../atoms/utils/layout/Flex';
import Margin from '../../../atoms/utils/layout/Margin';

export interface LabeledTextLayoutProps {
  children: React.ReactElement[];
  direction: 'row' | 'column';
  marginBetween: string | number;
  labelWidth?: string | number;
}

const LabeledTextLayout: FC<LabeledTextLayoutProps> = ({
  children,
  direction,
  marginBetween,
  labelWidth,
}: LabeledTextLayoutProps) => {
  const elements = ['label', 'name'];
  const [label, name] = getElementsFromKeys(children, elements);

  const labelBoxWidth = labelWidth ?? 'auto';

  if (direction === 'row')
    return (
      <FlexRow alignItems="center">
        <FlexBoxRow alignItems="center" width={labelBoxWidth}>
          {label}
        </FlexBoxRow>
        <Margin left={marginBetween} />
        {name}
      </FlexRow>
    );

  return (
    <FlexColumn>
      {label}
      <Margin top={marginBetween} />
      {name}
    </FlexColumn>
  );
};
export default LabeledTextLayout;
