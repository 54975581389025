import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import KycRequestContext from 'domain/verification/kycRequest/KycRequest/KycRequest/KycRequestContext';

// Style
import { TitleStyle } from 'components/organisms/propertyList/user/PropertyListCustomerDetail/style';

// Component
import { TextSecondary } from 'components/atoms/text2/Text2';
import LabeledText from 'components/molecules/textGroup/LabeledText';
import PropertyListPersonalInformationLayout from 'components/organisms/propertyList/user/PropertyListPersonalInformation/PropertyListPersonalInformationLayout';

// Common
import { commonPropsLabeledText } from 'components/organisms/propertyList/user/PropertyListCustomerDetail/PropertyListCustomerDetail.type';

const ORGANISM_ID = `organisms.propertyList.user.PropertyListPersonalInformation`;

const PropertyListCustomerBasicInformation: FC = () => {
  const { t } = useTranslation();
  const { kycRequest } = useContext(KycRequestContext);

  const personalInformation = kycRequest?.getPersonalInformation();

  return (
    <PropertyListPersonalInformationLayout>
      <TextSecondary key="title" theme={TitleStyle}>
        {t(`${ORGANISM_ID}.title`)}
      </TextSecondary>
      <LabeledText
        key="contractName"
        {...commonPropsLabeledText}
        label={t(`${ORGANISM_ID}.label.name`)}
        value={personalInformation?.getFullName() || ''}
      />
      <LabeledText
        key="gender"
        {...commonPropsLabeledText}
        label={t(`${ORGANISM_ID}.label.gender`)}
        value={personalInformation?.getGenderCapitalize() || ''}
      />
      <LabeledText
        key="dateOfBirth"
        {...commonPropsLabeledText}
        label={t(`${ORGANISM_ID}.label.dateOfBirth`)}
        value={personalInformation?.getDateOfBirthDDMMMMYYYY() || ''}
      />
      <LabeledText
        key="passportNo"
        {...commonPropsLabeledText}
        label={t(`${ORGANISM_ID}.label.passportNo`)}
        value={personalInformation?.getProps().passport.passportNo || ''}
      />
      <LabeledText
        key="passportExpiredAt"
        {...commonPropsLabeledText}
        label={t(`${ORGANISM_ID}.label.passportExpiredAt`)}
        value={personalInformation?.getPassportExpiredAtDDMMMMYYYY() || ''}
      />
    </PropertyListPersonalInformationLayout>
  );
};
export default PropertyListCustomerBasicInformation;
