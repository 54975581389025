// Function
import FirebasePrivateFunctions from 'infrastructure/firebase/firebaseFunctions/firebaseFunctions/FirebasePrivateFunctions';

// Lib
import {
  generateFileByInteger8Array,
  getExtensionFromFilePath,
  getFilenameFromPath,
  getFilenameFromUrl,
} from 'utils/helpers/fileHelper';
import storage from 'infrastructure/firebase/cloudStorage/storage';
import { executeWithTimeout } from 'utils/helpers/timeout';

// Type
import { ExtensionTypeFileTypeMapper } from 'types/File.type';

// Constant
import { STORAGE_DOWNLOAD_TMP_FILE } from 'infrastructure/firebase/firebaseFunctions/endpoint';
import { FILE_UPLOAD_TIMEOUT } from 'config/constants/business';

// IService
import IStorageHandler from 'storage/IStorageHandler';

class CloudStorage extends FirebasePrivateFunctions implements IStorageHandler {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public upload = async (path: string, f: any) => {
    const ref = storage.ref();
    const fileRef = ref.child(path);

    const promiseUpload = new Promise((resolve, reject) => {
      fileRef
        .put(f)
        .then(() => {
          resolve(undefined);
        })
        .catch((error) => {
          reject(error);
        });
    });

    await executeWithTimeout(promiseUpload, FILE_UPLOAD_TIMEOUT);
  };

  public delete = async (path: string) => {
    const ref = storage.ref();
    const fileRef = ref.child(path);

    const promiseDelete = new Promise((resolve, reject) => {
      fileRef
        .delete()
        .then(() => {
          resolve(undefined);
        })
        .catch((error) => {
          reject(error);
        });
    });

    await executeWithTimeout(promiseDelete, FILE_UPLOAD_TIMEOUT);
  };

  public download = async (path: string) => {
    const fileName = getFilenameFromUrl(path);

    const response = await fetch(path);
    const blob = await response.blob();
    const file = new File([blob], fileName, { type: blob.type });

    return executeWithTimeout(Promise.resolve(file), FILE_UPLOAD_TIMEOUT);
  };

  public downloadTmpFile = async (fileId: string) => {
    const endpoint = STORAGE_DOWNLOAD_TMP_FILE.replace(/:id/, fileId);

    const response = await this.privateGET(endpoint, {
      responseType: 'arraybuffer',
    });

    const type = ExtensionTypeFileTypeMapper[getExtensionFromFilePath(fileId)];
    const fileName = getFilenameFromPath(fileId);
    const file = generateFileByInteger8Array(fileName, type, response.data);

    return file;
  };
}

export default CloudStorage;
