// Type
import { RegisteredAddressEditRequestRawDataType } from '../RegisteredAddressEditRequest/RegisteredAddressEditRequest.type';
import { RegisteredAddressEditRequestsRawDataType } from './RegisteredAddressEditRequests.type';

class RegisteredAddressEditRequestsFactory {
  static createRawDataFromRegisteredAddressEditRequestsRawData = (
    registeredAddressEditRequestsRawData: RegisteredAddressEditRequestsRawDataType,
  ): RegisteredAddressEditRequestRawDataType[] =>
    registeredAddressEditRequestsRawData.map(({ id, props }) => ({
      id,
      props: {
        ...props,
        createdAt: props.createdAt,
        updatedAt: props.updatedAt,
      },
    }));
}

export default RegisteredAddressEditRequestsFactory;
