import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';
import { FlexBoxColumn } from 'components/atoms/utils/layout/Box/FlexBox';
import Margin from 'components/atoms/utils/layout/Margin';

export interface ShippingAddressEditCompleteTemplateLayoutProps {
  children: React.ReactElement[];
}

const ShippingAddressEditCompleteTemplateLayout: FC<ShippingAddressEditCompleteTemplateLayoutProps> = ({
  children,
}: ShippingAddressEditCompleteTemplateLayoutProps) => {
  const elements = ['header', 'notification'];
  const [header, notification] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn width={1200} padding="80 0 80 85">
      {header}
      <Margin top={50} />
      {notification}
    </FlexBoxColumn>
  );
};
export default ShippingAddressEditCompleteTemplateLayout;
