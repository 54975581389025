import React, { FC, useContext } from 'react';
import { useRegisteredAddressCorporateEditViewUseCase } from 'enhancers/useCase/setting/registeredAddressEditRequest/RegisteredAddressCorporateEditViewProvider/useRegisteredAddressCorporateEditViewUseCase';
import KycRequestCorporateContext from 'domain/verification/kycRequest/KycRequest/KycRequestCorporate/KycRequestCorporateContext';
import RegisteredAddressTabTypeOptionContext from 'components/organisms/tabBar/TabBarRegisteredAddress/RegisteredAddressTabTypeOptionContext';
import RegisteredAddressCorporateEditRequestContext from 'domain/verification/registeredAddressEditRequest/RegisteredAddressCorporateEditRequest/RegisteredAddressCorporateEditRequestContext';
import RegisteredAddressResidentialEditRequestContext from 'domain/verification/registeredAddressEditRequest/RegisteredAddressResidentialEditRequest/RegisteredAddressResidentialEditRequestContext';
import DocumentTypesContext from 'domain/settings/documentType/DocumentType/DocumentTypes/DocumentTypesContext';
import UserAuthorizedContext from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import CorporateAddressFileContext from 'enhancers/fileHandler/verification/kycRequest/CorporateAddressFileProvider/CorporateAddressFileContext';
import ProofOfResidencyFileContext from 'enhancers/fileHandler/verification/kycRequest/ProofOfResidencyFileProvider/ProofOfResidencyFileContext';

// Error
import SystemErrorHandler from 'enhancers/errorHandler/SystemErrorHandler';

export type RegisteredAddressCorporateEditViewProviderProps = {
  children: React.ReactNode;
};

const RegisteredAddressCorporateEditViewProvider: FC<RegisteredAddressCorporateEditViewProviderProps> = ({
  children,
}: RegisteredAddressCorporateEditViewProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const {
    state,
    setState,
    kycRequest,
    documentTypes,
    registeredAddressTabTypeOptionContext,
    registeredAddressCorporateEditRequest,
    registeredAddressResidentialEditRequest,
    proofOfResidencyFileContext,
    corporateAddressFileContext,
  } = useRegisteredAddressCorporateEditViewUseCase(userAuthorized);

  return (
    <KycRequestCorporateContext.Provider value={{ kycRequest }}>
      <DocumentTypesContext.Provider value={{ documentTypes }}>
        <RegisteredAddressCorporateEditRequestContext.Provider
          value={{ registeredAddressCorporateEditRequest }}
        >
          <RegisteredAddressResidentialEditRequestContext.Provider
            value={{ registeredAddressResidentialEditRequest }}
          >
            <RegisteredAddressTabTypeOptionContext.Provider
              value={registeredAddressTabTypeOptionContext}
            >
              <ProofOfResidencyFileContext.Provider
                value={proofOfResidencyFileContext}
              >
                <CorporateAddressFileContext.Provider
                  value={corporateAddressFileContext}
                >
                  <SystemErrorHandler state={state} setState={setState}>
                    {children}
                  </SystemErrorHandler>
                </CorporateAddressFileContext.Provider>
              </ProofOfResidencyFileContext.Provider>
            </RegisteredAddressTabTypeOptionContext.Provider>
          </RegisteredAddressResidentialEditRequestContext.Provider>
        </RegisteredAddressCorporateEditRequestContext.Provider>
      </DocumentTypesContext.Provider>
    </KycRequestCorporateContext.Provider>
  );
};

export default RegisteredAddressCorporateEditViewProvider;
