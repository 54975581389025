import React, { FC, useContext } from 'react';
import ProfileTabTypeOptionContext from 'components/organisms/tabBar/TabBarProfile/ProfileTabTypeOptionContext';
import KycRequestContext from 'domain/verification/kycRequest/KycRequest/KycRequest/KycRequestContext';

// Error
import UserAuthorizedContext from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import { useProfileViewUseCase } from './useProfileViewUseCase';

export type ProfileViewProviderProps = {
  children: React.ReactNode;
};

const ProfileViewProvider: FC<ProfileViewProviderProps> = ({
  children,
}: ProfileViewProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const {
    kycRequest,
    customerDetailTabTypeOptionContext,
  } = useProfileViewUseCase(userAuthorized);

  return (
    <KycRequestContext.Provider value={{ kycRequest }}>
      <ProfileTabTypeOptionContext.Provider
        value={customerDetailTabTypeOptionContext}
      >
        {children}
      </ProfileTabTypeOptionContext.Provider>
    </KycRequestContext.Provider>
  );
};
export default ProfileViewProvider;
