import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import KycRequestContext from 'domain/verification/kycRequest/KycRequest/KycRequest/KycRequestContext';

// Style
import { TitleStyle } from 'components/organisms/propertyList/user/PropertyListCustomerDetail/style';

// Component
import { TextSecondary } from 'components/atoms/text2/Text2';
import PDFPageViewer from 'components/organisms/viewer/PDFPageViewer/PDFPageViewer';
import KycRequestCorporate from 'domain/verification/kycRequest/KycRequest/KycRequestCorporate/KycRequestCorporate';
import PropertyListCorporateRegistryLayout from './PropertyListCorporateRegistryLayout';

// DomainObject

const ORGANISM_ID = `organisms.propertyList.user.PropertyListCorporateRegistry`;

const PropertyListCorporateRegistry: FC = () => {
  const { t } = useTranslation();
  const { kycRequest } = useContext(KycRequestContext);

  if (kycRequest && !(kycRequest instanceof KycRequestCorporate)) return <></>;

  const corporateInformation = kycRequest?.getCorporateInformation();

  return (
    <PropertyListCorporateRegistryLayout>
      <TextSecondary key="title" theme={TitleStyle}>
        {t(`${ORGANISM_ID}.title`)}
      </TextSecondary>
      <PDFPageViewer
        key="corporateRegistry"
        filepath={corporateInformation?.getProps().corporateRegistryUrl || ''}
      />
    </PropertyListCorporateRegistryLayout>
  );
};
export default PropertyListCorporateRegistry;
