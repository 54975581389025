import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// Style
import { TitleBoxStyle } from 'components/organisms/propertyList/user/PropertyListCustomerDetail/style';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from 'components/atoms/utils/layout/Box/FlexBox';
import Margin from 'components/atoms/utils/layout/Margin';

export interface PropertyListPassportImageLayoutProps {
  children: React.ReactElement[];
}

const PropertyListPassportImageLayout: FC<PropertyListPassportImageLayoutProps> = ({
  children,
}: PropertyListPassportImageLayoutProps) => {
  const elements = ['title', 'passport', 'selfie'];
  const [title, passport, selfie] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn alignItems="center" height="auto">
      <FlexBoxRow
        alignItems="center"
        paddingLeft="15"
        height={50}
        theme={TitleBoxStyle}
      >
        {title}
      </FlexBoxRow>
      <Margin top={30} />
      <FlexBoxRow height="auto" justifyContent="space-between">
        {passport}
        <Margin left={30} />
        {selfie}
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};
export default PropertyListPassportImageLayout;
