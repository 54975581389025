import Joi from '@hapi/joi';

// Type
import {
  AddressDocumentType,
  RegisteredAddressEditRequestStatusType,
  RegisteredAddressEditRequestType,
} from 'types/RegisteredAddressEditRequest.type';
import {
  AddressProps,
  AddressPropsFormat,
} from 'value/personalInformation/address/Address/Address/Address.type';
import {
  CorporateAddressDocumentType,
  ProofOfResidencyDocumentType,
} from 'types/KycRequest.type';

export interface RegisteredAddressEditRequestProps extends AddressProps {
  status: RegisteredAddressEditRequestStatusType;

  rejectedReason?: string;

  kycRequestId: string;

  document: {
    type: AddressDocumentType;

    documentUrl1: string;

    documentUrl2?: string;
  };

  addressEditRequestType: RegisteredAddressEditRequestType;

  createdAt?: number;

  updatedAt?: number;
}

export interface RegisteredAddressEditRequestRawDataType {
  id: string;

  props: RegisteredAddressEditRequestProps;
}

export const RegisteredAddressEditRequestPropsFormat = {
  ...AddressPropsFormat,

  id: Joi.string().required(),

  status: Joi.string()
    .valid(...Object.values(RegisteredAddressEditRequestStatusType))
    .required(),

  rejectedReason: Joi.string().empty(''),

  kycRequestId: Joi.string().required(),

  document: Joi.object({
    type: Joi.string()
      .valid(
        ...Object.values(CorporateAddressDocumentType),
        ...Object.values(ProofOfResidencyDocumentType),
      )
      .required(),

    documentUrl1: Joi.string().required().allow(''),

    documentUrl2: Joi.string().empty(''),
  }),

  addressEditRequestType: Joi.string()
    .valid(...Object.values(RegisteredAddressEditRequestType))
    .required(),

  createdAt: Joi.number().empty(''),

  updatedAt: Joi.number().empty(''),
};
