import React, { FC } from 'react';

// Component
import { FlexBoxColumn } from 'components/atoms/utils/layout/Box/FlexBox';
import PropertyListCustomerBasicInformation from 'components/organisms/propertyList/user/PropertyListCustomerBasicInformation';

const CustomerDetailBasicInformationTemplate: FC = () => {
  return (
    <FlexBoxColumn>
      <PropertyListCustomerBasicInformation key="basicInformation" />
    </FlexBoxColumn>
  );
};
export default CustomerDetailBasicInformationTemplate;
