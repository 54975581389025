import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxColumn } from '../../../atoms/utils/layout/Box/FlexBox';
import Margin from '../../../atoms/utils/layout/Margin';

export interface LabeledImageLayoutProps {
  children: React.ReactElement[];
  marginBetween?: string | number;
}

const LabeledImageLayout: FC<LabeledImageLayoutProps> = ({
  children,
  marginBetween,
}: LabeledImageLayoutProps) => {
  const elements = ['label', 'image'];
  const [label, image] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn height="auto">
      {label}
      <Margin top={marginBetween} />
      {image}
    </FlexBoxColumn>
  );
};
export default LabeledImageLayout;
