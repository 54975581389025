// Type
import {
  ProofOfResidencyDocumentType,
  CorporateAddressDocumentType,
} from './KycRequest.type';

export type AddressDocumentType =
  | ProofOfResidencyDocumentType
  | CorporateAddressDocumentType;

export enum RegisteredAddressEditRequestType {
  proofOfResidency = 'proofOfResidency',
  corporateAddress = 'corporateAddress',
}

export enum RegisteredAddressEditRequestStatusType {
  approved = 'approved',
  rejected = 'rejected',
  reviewing = 'reviewing',
}
