import React from 'react';

// Domain
import RegisteredAddressResidentialEditRequest from './RegisteredAddressResidentialEditRequest';

export interface RegisteredAddressResidentialEditRequestContextProps {
  registeredAddressResidentialEditRequest?: RegisteredAddressResidentialEditRequest;
}

const RegisteredAddressResidentialEditRequestContext = React.createContext<
  RegisteredAddressResidentialEditRequestContextProps
>({
  registeredAddressResidentialEditRequest: undefined,
});

export default RegisteredAddressResidentialEditRequestContext;
