import React, { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import KycRequestCorporateContext from 'domain/verification/kycRequest/KycRequest/KycRequestCorporate/KycRequestCorporateContext';
import RegisteredAddressCorporateEditRequestContext from 'domain/verification/registeredAddressEditRequest/RegisteredAddressCorporateEditRequest/RegisteredAddressCorporateEditRequestContext';
import RegisteredAddressResidentialEditRequestContext from 'domain/verification/registeredAddressEditRequest/RegisteredAddressResidentialEditRequest/RegisteredAddressResidentialEditRequestContext';
import RegisteredAddressTabTypeOptionContext from 'components/organisms/tabBar/TabBarRegisteredAddress/RegisteredAddressTabTypeOptionContext';

// Component
import { PageTitle } from 'components/atoms/headLine/HeadLine';
import TabBarRegisteredAddress from 'components/organisms/tabBar/TabBarRegisteredAddress';
import FormCorporateAddress from 'components/organisms/form/settings/FormRegisteredAddressEditRequestAdd/FormCorporateAddress';
import FormProofOfResidency from 'components/organisms/form/settings/FormRegisteredAddressEditRequestAdd/FormProofOfResidency';
import RegisteredCorporateAddressEditTemplateLayout from './RegisteredCorporateAddressEditTemplateLayout';

const TEMPLATE_ID = `templates.userSettings.RegisteredCorporateAddressEditTemplate`;

const RegisteredCorporateAddressEditTemplate: FC = () => {
  const { t } = useTranslation();
  const { registeredAddressTabTypeOption } = useContext(
    RegisteredAddressTabTypeOptionContext,
  );
  const { kycRequest } = useContext(KycRequestCorporateContext);
  const { registeredAddressCorporateEditRequest } = useContext(
    RegisteredAddressCorporateEditRequestContext,
  );
  const { registeredAddressResidentialEditRequest } = useContext(
    RegisteredAddressResidentialEditRequestContext,
  );

  const corporateAddress = useMemo(() => {
    if (typeof registeredAddressCorporateEditRequest !== 'undefined') {
      return registeredAddressCorporateEditRequest.getCorporateAddress();
    }
    return kycRequest?.getCorporateAddress();
  }, [registeredAddressCorporateEditRequest, kycRequest]);

  const proofOfResidency = useMemo(() => {
    if (typeof registeredAddressResidentialEditRequest !== 'undefined') {
      return registeredAddressResidentialEditRequest.getProofOfResidency();
    }
    return kycRequest?.getProofOfResidency();
  }, [registeredAddressResidentialEditRequest, kycRequest]);

  return (
    <RegisteredCorporateAddressEditTemplateLayout
      registeredAddressTabTypeOption={registeredAddressTabTypeOption}
    >
      <PageTitle key="title">{t(`${TEMPLATE_ID}.title`)}</PageTitle>
      <TabBarRegisteredAddress key="tab" />
      <FormCorporateAddress
        key="tabCorporateAddress"
        corporateAddress={corporateAddress}
        isUnderReview={!!registeredAddressCorporateEditRequest}
      />
      <FormProofOfResidency
        key="tabProofOfAddress"
        proofOfResidency={proofOfResidency}
        isUnderReview={!!registeredAddressResidentialEditRequest}
      />
    </RegisteredCorporateAddressEditTemplateLayout>
  );
};
export default RegisteredCorporateAddressEditTemplate;
