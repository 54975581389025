// Type
import { RegisteredAddressEditRequestProps } from 'domain/verification/registeredAddressEditRequest/RegisteredAddressEditRequest/RegisteredAddressEditRequest.type';

class RegisteredAddressEditRequest {
  private id: string;

  private createdAt: number;

  private updatedAt: number;

  protected props: RegisteredAddressEditRequestProps;

  constructor(id: string, props: RegisteredAddressEditRequestProps) {
    this.id = id;
    this.createdAt = props.createdAt ?? Date.now();
    this.updatedAt = props.updatedAt ?? Date.now();
    this.props = props;
  }

  public getId = () => this.id;

  public getProps() {
    return {
      ...this.props,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }

  public getStatus = () => this.props.status;
}
export default RegisteredAddressEditRequest;
