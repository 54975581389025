import React, { FC } from 'react';

// Style
import { ContainerStyle } from 'components/molecules/tabBar/TabBarSquareItems/style';

// LayoutComponent
import { FlexBoxRow } from 'components/atoms/utils/layout/Box/FlexBox';
import Margin from 'components/atoms/utils/layout/Margin';

// Component
import TabItemSquare from 'components/molecules/item/TabItem/TabItemSquare';

export interface TabBarSquareItemsProps {
  items: Array<string>;
  itemHeight: string | number;
  itemWidth: string | number;
  marginBetween?: string | number;
  selected: string;
  onClickItem: (type: string) => void;
}

const TabBarSquareItems: FC<TabBarSquareItemsProps> = ({
  items,
  itemHeight,
  itemWidth,
  marginBetween = 0,
  selected,
  onClickItem,
}: TabBarSquareItemsProps) => {
  return (
    <FlexBoxRow height={itemHeight} theme={ContainerStyle} width="auto">
      {items.map((item, i) => {
        return (
          <FlexBoxRow key={`${i}`} width="auto">
            <TabItemSquare
              key={`items${i}`}
              item={item as string}
              onClick={onClickItem}
              selected={selected}
              width={itemWidth}
              height={itemHeight}
            />
            <Margin right={marginBetween} />
          </FlexBoxRow>
        );
      })}
    </FlexBoxRow>
  );
};

export default TabBarSquareItems;
