// Type
import { CorporateAddressProps } from './CorporateAddress.type';
import { CorporateAddressDocumentType } from '../../../../../types/KycRequest.type';

// ValueObject
import Address from '../../../../personalInformation/address/Address/Address/Address';
import KycRequestContent from '../KycRequestContent/KycRequestContent';

class CorporateAddress extends KycRequestContent {
  private address: Address;

  private document: {
    type: CorporateAddressDocumentType;
    documentUrl1: string;
  };

  constructor(props: CorporateAddressProps) {
    super(props);
    this.address = new Address(props);
    this.document = props.document;
  }

  public getProps = () => ({
    ...super.getProps(),
    ...this.address.getProps(),
    document: this.document,
  });

  public getCommandParameter = () => ({
    ...super.getCommandParameter(),
    ...this.address.getProps(),
    document: this.document,
  });

  public getAddress = () => this.address;

  public updateProps = (props: CorporateAddressProps) => {
    return new CorporateAddress({ ...this.getProps(), ...props });
  };
}

export default CorporateAddress;
