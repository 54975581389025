import styled from 'styled-components';

import color from 'components/atoms/utils/const/color';

export const Container = styled.div`
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: ${60 / 16}rem;
`;

export const ContentBoxStyle = {
  backgroundColor: color.background.white,
};
