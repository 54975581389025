import { useCallback, useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { checkInternetConnection } from 'utils/helpers/connection';
import { INITIAL, IS_LOADING, SUCCESS } from 'config/constants/requestState';
import ErrorHandler from 'utils/errors/ErrorHandler/ErrorHandler';
// Redux
import { setRegisteredAddressEditRequests } from 'redux/reducers/registeredAddressEditRequestsReducer';

// Type
import { RegisteredAddressEditRequestType } from 'types/RegisteredAddressEditRequest.type';
import { RegisteredAddressTab } from 'components/organisms/tabBar/TabBarRegisteredAddress/RegisteredAddressTabType.type';

// UseCase
import RegisteredAddressCorporateEditViewUseCase from 'useCases/verification/registeredAddressEditRequest/RegisteredAddressCorporateEditViewUseCase';

// Repository
import DocumentTypeFirestore from 'repository/settings/documentType/DocumentTypeFirestore';
import KycRequestFirestore from 'repository/verification/kycRequest/KycRequestFirestore';
import RegisteredAddressEditRequestFunctions from 'repository/verification/registeredAddressEditRequest/RegisteredAddressEditRequestFunctions';
import CloudStorage from 'storage/cloudStorage/CloudStorage';

// DomainObject
import { useKycRequestCorporate } from 'domain/verification/kycRequest/KycRequest/KycRequestCorporate/useKycRequestCorporate';
import { useDocumentTypes } from 'domain/settings/documentType/DocumentType/DocumentTypes/useDocumentTypes';
import { useRegisteredAddressEditRequests } from 'domain/verification/registeredAddressEditRequest/RegisteredAddressEditRequests/useRegisteredAddressEditRequests';
import UserAuthorized from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';
import RegisteredAddressCorporateEditRequest from 'domain/verification/registeredAddressEditRequest/RegisteredAddressCorporateEditRequest/RegisteredAddressCorporateEditRequest';
import RegisteredAddressResidentialEditRequest from 'domain/verification/registeredAddressEditRequest/RegisteredAddressResidentialEditRequest/RegisteredAddressResidentialEditRequest';

// Options
import { useRegisteredAddressTabTypeOption } from 'components/organisms/tabBar/TabBarRegisteredAddress/useRegisteredAddressTabTypeOption';

// File
import { useCorporateAddressFile } from 'enhancers/fileHandler/verification/kycRequest/CorporateAddressFileProvider/useCorporateAddressFile';
import { useProofOfResidencyFile } from 'enhancers/fileHandler/verification/kycRequest/ProofOfResidencyFileProvider/useProofOfResidencyFile';

export const useRegisteredAddressCorporateEditViewUseCase = (
  userAuthorized?: UserAuthorized,
) => {
  const [state, setState] = useState<string>(INITIAL);
  const dispatch = useDispatch();

  // File
  const {
    proofOfDocument1: corporateAddressDocument1,
    setProofOfDocument1: setCorporateAddressDocument1,
  } = useCorporateAddressFile();
  const {
    proofOfDocument1,
    setProofOfDocument1,
    proofOfDocument2,
    setProofOfDocument2,
  } = useProofOfResidencyFile();

  /* *
   *
   *  DomainObject
   *
   * */
  const {
    kycRequestCorporate,
    setKycRequestCorporate,
  } = useKycRequestCorporate();
  const {
    documentTypes: documentTypesCorporate,
    setDocumentTypes: setDocumentTypesCorporate,
  } = useDocumentTypes();
  const {
    documentTypes: documentTypesIndividual,
    setDocumentTypes: setDocumentTypesIndividual,
  } = useDocumentTypes();
  const registeredAddressEditRequests = useRegisteredAddressEditRequests();

  const {
    registeredAddressTabTypeOption,
    setRegisteredAddressTabTypeOption,
  } = useRegisteredAddressTabTypeOption();

  /* *
   *
   *  Repository
   *
   * */
  const kycRequestRepository = new KycRequestFirestore();
  const documentTypeRepository = new DocumentTypeFirestore();
  const registeredAddressEditRequestRepository = new RegisteredAddressEditRequestFunctions();
  const storageHandler = new CloudStorage();

  /* *
   *
   *  UseCase
   *
   * */
  const useCase = new RegisteredAddressCorporateEditViewUseCase(
    documentTypeRepository,
    kycRequestRepository,
    registeredAddressEditRequestRepository,
    storageHandler,
  );

  /* *
   *
   *  Method
   *
   * */
  const open = useCallback(async () => {
    if (!userAuthorized) return;

    setState(IS_LOADING);
    try {
      // Is ONLINE?
      checkInternetConnection();

      // UseCase
      const output = await useCase.open(userAuthorized);

      setKycRequestCorporate(output.kycRequest);
      setDocumentTypesCorporate(output.documentTypes);
      setDocumentTypesIndividual(output.documentTypesIndividual);

      dispatch(
        setRegisteredAddressEditRequests({
          registeredAddressEditRequests: output.registeredAddressEditRequests,
        }),
      );

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [userAuthorized]);

  /* *
   *
   *  UseCase
   *
   * */
  useEffect(() => {
    let isMounted = true;

    if (userAuthorized && isMounted) open();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [userAuthorized]);

  /**
   * Filter
   */
  const isCorporateAddressTab = useMemo(
    () =>
      registeredAddressTabTypeOption ===
      RegisteredAddressTab.corporateInformation,
    [registeredAddressTabTypeOption],
  );
  const isPersonalInformationTab = useMemo(
    () =>
      registeredAddressTabTypeOption ===
      RegisteredAddressTab.personalInformation,
    [registeredAddressTabTypeOption],
  );

  const registeredAddressCorporateEditRequest = useMemo(() => {
    if (!isCorporateAddressTab) return undefined;
    return (
      registeredAddressEditRequests &&
      (registeredAddressEditRequests
        .filterByRequestType(RegisteredAddressEditRequestType.corporateAddress)
        .head() as RegisteredAddressCorporateEditRequest)
    );
  }, [registeredAddressEditRequests, isCorporateAddressTab]);
  const registeredAddressResidentialEditRequest = useMemo(() => {
    if (!isPersonalInformationTab) return undefined;
    return (
      registeredAddressEditRequests &&
      (registeredAddressEditRequests
        .filterByRequestType(RegisteredAddressEditRequestType.proofOfResidency)
        .head() as RegisteredAddressResidentialEditRequest)
    );
  }, [registeredAddressEditRequests, isPersonalInformationTab]);

  const isUnderReview = useMemo(
    () =>
      !!registeredAddressCorporateEditRequest ||
      !!registeredAddressResidentialEditRequest,
    [
      registeredAddressCorporateEditRequest,
      registeredAddressResidentialEditRequest,
    ],
  );

  // Get Address Object
  const corporateAddress = useMemo(() => {
    if (!isCorporateAddressTab) return undefined;
    return (
      registeredAddressCorporateEditRequest?.getCorporateAddress() ||
      kycRequestCorporate?.getCorporateAddress()
    );
  }, [
    kycRequestCorporate,
    registeredAddressCorporateEditRequest,
    isCorporateAddressTab,
  ]);

  const proofOfResidency = useMemo(() => {
    if (!isPersonalInformationTab) return undefined;
    return (
      registeredAddressResidentialEditRequest?.getProofOfResidency() ||
      kycRequestCorporate?.getProofOfResidency()
    );
  }, [
    kycRequestCorporate,
    registeredAddressResidentialEditRequest,
    isPersonalInformationTab,
  ]);

  // Get Document URL
  const documentUrl1 = useMemo(
    () =>
      corporateAddress?.getProps().document.documentUrl1 ||
      proofOfResidency?.getProps().document.documentUrl1,
    [proofOfResidency, corporateAddress],
  );
  const documentUrl2 = useMemo(
    () => proofOfResidency?.getProps().document.documentUrl2,
    [proofOfResidency],
  );

  const documentUrls = useMemo(() => {
    const urls = [];
    if (documentUrl1) urls.push(documentUrl1);
    if (documentUrl2) urls.push(documentUrl2);
    return urls;
  }, [documentUrl1, documentUrl2]);

  // Download file
  const downloadDocument = useCallback(async () => {
    if (documentUrls.length === 0) return;

    try {
      // Is ONLINE?
      checkInternetConnection();

      // UseCase
      const [document1, document2] = await useCase.downloadDocuments(
        documentUrls,
      );

      if (corporateAddress) setCorporateAddressDocument1(document1);
      if (proofOfResidency) {
        setProofOfDocument1(document1);
        setProofOfDocument2(document2);
      }
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [documentUrls, proofOfResidency, corporateAddress]);

  useEffect(() => {
    if (documentUrls.length > 0 && isUnderReview && state === SUCCESS)
      downloadDocument();

    // eslint-disable-next-line
  }, [documentUrls, isUnderReview, state]);

  // Document Type
  const documentTypes = useMemo(
    () =>
      isCorporateAddressTab ? documentTypesCorporate : documentTypesIndividual,
    [documentTypesCorporate, documentTypesIndividual, isCorporateAddressTab],
  );
  const documentTypesSorted = useMemo(() => documentTypes.sortAscByPriority(), [
    documentTypes,
  ]);

  return {
    state,
    setState,
    kycRequest: kycRequestCorporate,
    documentTypes: documentTypesSorted,
    registeredAddressTabTypeOptionContext: {
      registeredAddressTabTypeOption,
      setRegisteredAddressTabTypeOption,
    },
    registeredAddressCorporateEditRequest,
    registeredAddressResidentialEditRequest,
    corporateAddressFileContext: {
      proofOfDocument1: corporateAddressDocument1,
      setProofOfDocument1: setCorporateAddressDocument1,
    },
    proofOfResidencyFileContext: {
      proofOfDocument1,
      setProofOfDocument1,
      proofOfDocument2,
      setProofOfDocument2,
    },
  };
};
