import { useSelector } from 'react-redux';

// Type
import { GlobalStateType } from 'redux/store';
import { RegisteredAddressEditRequestProps } from 'domain/verification/registeredAddressEditRequest/RegisteredAddressEditRequest/RegisteredAddressEditRequest.type';

// Domain
import RegisteredAddressEditRequests from 'domain/verification/registeredAddressEditRequest/RegisteredAddressEditRequests/RegisteredAddressEditRequests';
import RegisteredAddressEditRequestFactory from 'domain/verification/registeredAddressEditRequest/RegisteredAddressEditRequest/RegisteredAddressEditRequestFactory';

export const useRegisteredAddressEditRequests = ():
  | RegisteredAddressEditRequests
  | undefined => {
  const state = useSelector(
    (globalState: GlobalStateType) =>
      globalState.registeredAddressEditRequestsStore,
  );

  return state?.list?.reduce(
    (
      objs: RegisteredAddressEditRequests,
      item: { id: string; props: RegisteredAddressEditRequestProps },
    ) => {
      objs.add(RegisteredAddressEditRequestFactory.create(item.id, item.props));
      return objs;
    },
    new RegisteredAddressEditRequests(),
  );
};
