import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from 'components/atoms/utils/layout/Box/FlexBox';
import Margin from 'components/atoms/utils/layout/Margin';

export interface RegisteredIndividualAddressEditTemplateLayoutProps {
  children: React.ReactElement[];
}

const RegisteredIndividualAddressEditTemplateLayout: FC<RegisteredIndividualAddressEditTemplateLayoutProps> = ({
  children,
}: RegisteredIndividualAddressEditTemplateLayoutProps) => {
  const elements = ['title', 'proofOfAddress'];
  const [title, proofOfAddress] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn
      width="1360"
      padding="85 80 0 80"
      height="auto"
      style={{ minWidth: `${1200 / 16}rem` }}
    >
      {title}
      <Margin top={60} />
      <FlexBoxRow>{proofOfAddress}</FlexBoxRow>
      <Margin top={100} />
    </FlexBoxColumn>
  );
};

export default RegisteredIndividualAddressEditTemplateLayout;
