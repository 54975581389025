import React, { FC } from 'react';
import { getElementsFromKeys } from '../../../../../utils/helpers/reactHelper';
import ContentBox from '../../../../atoms/utils/layout/Box/Box/ContentBox';
import Margin from '../../../../atoms/utils/layout/Margin';
import { FlexRow, FlexColumn } from '../../../../atoms/utils/layout/Flex';
import { FlexBoxRow } from '../../../../atoms/utils/layout/Box/FlexBox';
import { UpperContainer, LowerContainer } from './style';

export interface CryptoCurrencyWalletCardLayoutProps {
  children: React.ReactElement[];
}

const CryptoCurrencyWalletCardLayout: FC<CryptoCurrencyWalletCardLayoutProps> = ({
  children,
}: CryptoCurrencyWalletCardLayoutProps) => {
  const elements = [
    'currencyIcon',
    'currencyName',
    'rate',
    'balance',
    'accountHolder',
    'depositButton',
    'withdrawalButton',
    'tradeButton',
  ];
  const [
    currencyIcon,
    currencyName,
    rate,
    balance,
    accountHolder,
    depositButton,
    withdrawalButton,
    tradeButton,
  ] = getElementsFromKeys(children, elements);

  return (
    <ContentBox padding={'35 50 50 50'} height="100%">
      <UpperContainer>
        <FlexRow alignItems="center">
          {currencyIcon}
          <Margin left={20}>{currencyName}</Margin>
        </FlexRow>
        {rate}
      </UpperContainer>
      <LowerContainer>
        <FlexColumn>
          {balance}
          <Margin top={25}>{accountHolder}</Margin>
        </FlexColumn>
        <FlexColumn justifyContent="flex-start">
          <FlexBoxRow justifyContent="space-between" width={510} height={50}>
            {depositButton}
            {withdrawalButton}
          </FlexBoxRow>
          <Margin top={30} />
          {tradeButton}
        </FlexColumn>
      </LowerContainer>
    </ContentBox>
  );
};

export default CryptoCurrencyWalletCardLayout;
