import React, { FC, useContext } from 'react';
import TabBarSquareItems from 'components/molecules/tabBar/TabBarSquareItems';
import ProfileTabTypeOptionContext from './ProfileTabTypeOptionContext';

// Component

const TabBarProfile: FC = () => {
  const {
    profileTabItems,
    profileTabTypeOption,
    setProfileTabTypeOption,
  } = useContext(ProfileTabTypeOptionContext);

  return (
    <TabBarSquareItems
      items={profileTabItems}
      itemHeight={76}
      itemWidth={188.66}
      marginBetween={10.34}
      selected={profileTabTypeOption as string}
      onClickItem={setProfileTabTypeOption as (type: string) => void}
    />
  );
};

export default TabBarProfile;
