import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthenticatedPageVewProvider from 'enhancers/useCase/authentication/AuthenticatedPageViewProvider';
import AccessHandlerUserStatusProvider from 'enhancers/routing/AccessHandlerUserStatusProvider';

// Type
import { MenuType } from 'config/menu/main/Menu.type';

// Constant
import { ALLOWED_USER_STATUS } from 'routers/accessHandler/userStatusPageAccessMapper';
import {
  ROUTE_SETTINGS_TWO_FACTOR_AUTH_APP_EDIT,
  ROUTE_SETTINGS_EMAIL_EDIT,
  ROUTE_SETTINGS_PASSWORD_EDIT,
  // ROUTE_SETTINGS_SHIPPING_ADDRESS_EDIT,
  ROUTE_SETTINGS_CONSENT_FORM,
  ROUTE_SETTINGS_PROFILE,
  ROUTE_SETTINGS_CORPORATE_ADDRESS_EDIT,
  ROUTE_SETTINGS_ADDRESS_EDIT,
} from 'config/constants/routing';

// Component
import { EnhancedTwoFactorAuthAppEditPage } from 'containers/settings/TwoFactorAuthAppEditPage';
import { EnhancedEmailEditPage } from 'containers/settings/EmailEditPage';
import { EnhancedPasswordEditPage } from 'containers/settings/PasswordEditPage';
// import { EnhancedShippingAddressEditPage } from 'containers/settings/ShippingAddressEditPage';
import { EnhancedConsentFormSettingPage } from 'containers/settings/ConsentFormSettingPage';
import { EnhancedProfilePage } from 'containers/settings/ProfilePage';
import { EnhancedRegisteredAddressCorporateEditPage } from 'containers/settings/RegisteredAddressCorporateEditPage';
import { EnhancedRegisteredAddressIndividualEditPage } from 'containers/settings/RegisteredAddressIndividualEditPage';

const UserSettingsRouter: FC = () => {
  return (
    <Switch>
      <AuthenticatedPageVewProvider>
        <Route exact path={ROUTE_SETTINGS_EMAIL_EDIT}>
          <EnhancedEmailEditPage />
        </Route>
        <Route exact path={ROUTE_SETTINGS_PASSWORD_EDIT}>
          <EnhancedPasswordEditPage />
        </Route>
        <AccessHandlerUserStatusProvider
          statusAllowed={ALLOWED_USER_STATUS[MenuType.settings]}
        >
          <Route exact path={ROUTE_SETTINGS_TWO_FACTOR_AUTH_APP_EDIT}>
            <EnhancedTwoFactorAuthAppEditPage />
          </Route>
          {/* <Route exact path={ROUTE_SETTINGS_SHIPPING_ADDRESS_EDIT}>
            <EnhancedShippingAddressEditPage />
          </Route> */}
          <Route exact path={ROUTE_SETTINGS_CONSENT_FORM}>
            <EnhancedConsentFormSettingPage />
          </Route>
          <Route exact path={ROUTE_SETTINGS_PROFILE}>
            <EnhancedProfilePage />
          </Route>
          <Route exact path={ROUTE_SETTINGS_CORPORATE_ADDRESS_EDIT}>
            <EnhancedRegisteredAddressCorporateEditPage />
          </Route>
          <Route exact path={ROUTE_SETTINGS_ADDRESS_EDIT}>
            <EnhancedRegisteredAddressIndividualEditPage />
          </Route>
        </AccessHandlerUserStatusProvider>
      </AuthenticatedPageVewProvider>
    </Switch>
  );
};

export default UserSettingsRouter;
