import React, { FC } from 'react';
import FormInputAddress from 'components/molecules/form/formInputGroup/FormInputAddress';
import KycFormContainer from 'components/organisms/form/common/KycFormContainer';

const FORM_ID = `organisms.form.settings.FormCorporateAddress.corporateAddress`;

export interface FormCorporateAddressInputProps {
  isUnderReview: boolean;
}

const FormCorporateAddressInput: FC<FormCorporateAddressInputProps> = ({
  isUnderReview,
}: FormCorporateAddressInputProps) => {
  return (
    <KycFormContainer order={1} formId={FORM_ID}>
      <FormInputAddress
        addressKeys={{
          country: 'country',
          addressLine1: 'addressLine1',
          addressLine2: 'addressLine2',
          city: 'city',
          state: 'state',
          postalCode: 'postalCode',
        }}
        isDisabled={isUnderReview}
      />
    </KycFormContainer>
  );
};

export default FormCorporateAddressInput;
