import {
  BankWithdrawalType,
  BankFutureDepositType,
  BankDepositType,
} from '../../../../types/BankAccountTransaction.type';
import { FiatCurrencyType } from '../../../../types/FiatCurrency.type';
import { TransactionIdProps } from '../TransactionId/TransactionId.type';

export interface BankTransactionIdProps extends TransactionIdProps {
  currency: FiatCurrencyType;
}

export const PrefixBankTransactionId: { [key: string]: string } = {
  [BankWithdrawalType.additionalPostage]: 'WDAP',

  [BankWithdrawalType.cancelFeeCard]: 'WCFC',

  [BankWithdrawalType.cancellationFeeTimeDeposit]: 'WCFT',

  [BankWithdrawalType.changeCardShippingAddressFee]: 'WCSA',

  [BankWithdrawalType.chargeDebitCard]: 'WTDC',

  [BankWithdrawalType.chargeDebitCardNoActivation]: 'WCNA',

  [BankWithdrawalType.chargeFeeDebitCard]: 'WTFC',

  [BankWithdrawalType.createContractTimeDeposit]: 'WNTD',

  [BankWithdrawalType.cryptoCurrencyBuy]: 'WBCC',

  [BankWithdrawalType.documentIssuanceFee]: 'WDIF',

  [BankWithdrawalType.fpbmMsModificationWithdrawal]: 'WFMM',

  [BankWithdrawalType.fpbmMsWithdrawal]: 'WFMW',

  [BankWithdrawalType.issueDebitCard]: 'WIDC',

  [BankWithdrawalType.issueDebitCardNoActivation]: 'WIDN',

  [BankWithdrawalType.issueDebitCardAgain]: 'WICA',

  [BankWithdrawalType.issueDebitCardPIN]: 'WIDP',

  [BankWithdrawalType.renewFeeDebitCard]: 'WRNC',

  [BankWithdrawalType.reshippingDebitCard]: 'WSCA',

  [BankWithdrawalType.savingsAccountMonthlyMaintenance]: 'WSMF',

  [BankWithdrawalType.sendFundsByTransfer]: 'WTSA',

  [BankWithdrawalType.sendFundsToECBByTransfer]: 'WTSE',

  [BankWithdrawalType.sendFundsToECBByTransferFee]: 'WTEF',

  [BankWithdrawalType.transferFee]: 'WTSF',

  [BankWithdrawalType.withdrawFeeDebitCard]: 'WRFC',

  [BankWithdrawalType.withdrawFundsByReimbursement]: 'WRTL',

  [BankDepositType.commissionTimeDeposit]: 'DCTD',

  [BankDepositType.cryptoDirectDeposit]: 'DCDD',

  [BankDepositType.cryptoCurrencySell]: 'DSCC',

  [BankDepositType.fpbmMsDeposit]: 'DFMD',

  [BankDepositType.fpbmMsModificationDeposit]: 'DFMM',

  [BankDepositType.interestTimeDeposit]: 'DITD',

  [BankDepositType.principalTimeDeposit]: 'DPTD',

  [BankDepositType.receiveFundsByTransfer]: 'DTSA',

  [BankDepositType.receiveFundsByTemporaryLoan]: 'DRTL',

  [BankDepositType.receiveFundsFromECBByTransfer]: 'DTSE',

  [BankDepositType.refundShippingFee]: 'DRSF',

  [BankDepositType.refundTransferSavingsToCard]: 'DRTD',

  [BankDepositType.refundDebitCardTopUpFee]: 'DRTF',

  [BankDepositType.withdrawDebitCard]: 'DTDC',

  [BankFutureDepositType.futureInterestTimeDeposit]: 'DFIT',

  [BankFutureDepositType.futureCommissionTimeDeposit]: 'DFCT',
};
