import React, { FC, useContext } from 'react';
import { useRegisteredAddressIndividualEditViewUseCase } from 'enhancers/useCase/setting/registeredAddressEditRequest/RegisteredAddressIndividualEditViewProvider/useRegisteredAddressIndividualEditViewUseCase';
import KycRequestContext from 'domain/verification/kycRequest/KycRequest/KycRequest/KycRequestContext';
import RegisteredAddressResidentialEditRequestContext from 'domain/verification/registeredAddressEditRequest/RegisteredAddressResidentialEditRequest/RegisteredAddressResidentialEditRequestContext';
import DocumentTypesContext from 'domain/settings/documentType/DocumentType/DocumentTypes/DocumentTypesContext';
import UserAuthorizedContext from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorizedContext';
import ProofOfResidencyFileContext from 'enhancers/fileHandler/verification/kycRequest/ProofOfResidencyFileProvider/ProofOfResidencyFileContext';

// Error
import SystemErrorHandler from 'enhancers/errorHandler/SystemErrorHandler';

export type RegisteredAddressIndividualEditViewProviderProps = {
  children: React.ReactNode;
};

const RegisteredAddressIndividualEditViewProvider: FC<RegisteredAddressIndividualEditViewProviderProps> = ({
  children,
}: RegisteredAddressIndividualEditViewProviderProps) => {
  const { userAuthorized } = useContext(UserAuthorizedContext);

  const {
    state,
    setState,
    kycRequest,
    documentTypes,
    registeredAddressResidentialEditRequest,
    proofOfResidencyFileContext,
  } = useRegisteredAddressIndividualEditViewUseCase(userAuthorized);

  return (
    <KycRequestContext.Provider value={{ kycRequest }}>
      <DocumentTypesContext.Provider value={{ documentTypes }}>
        <RegisteredAddressResidentialEditRequestContext.Provider
          value={{ registeredAddressResidentialEditRequest }}
        >
          <ProofOfResidencyFileContext.Provider
            value={proofOfResidencyFileContext}
          >
            <SystemErrorHandler state={state} setState={setState}>
              {children}
            </SystemErrorHandler>
          </ProofOfResidencyFileContext.Provider>
        </RegisteredAddressResidentialEditRequestContext.Provider>
      </DocumentTypesContext.Provider>
    </KycRequestContext.Provider>
  );
};

export default RegisteredAddressIndividualEditViewProvider;
