export enum ProfileTabIndividual {
  basicInformation = 'basicInformation',
  personalInformation = 'personalInformation',
}

export enum ProfileTabCorporate {
  basicInformation = 'basicInformation',
  corporateInformation = 'corporateInformation',
  personalInformation = 'personalInformation',
}

export type ProfileTabType = ProfileTabIndividual | ProfileTabCorporate;
