import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// LayoutComponent
import {
  FlexBoxColumn,
  FlexBoxRow,
} from 'components/atoms/utils/layout/Box/FlexBox';
import Display from 'components/atoms/div/Display';
import Margin from 'components/atoms/utils/layout/Margin';

export interface FormResidencyProofUploadLayoutProps {
  children: React.ReactElement[];
  isDisplaySecondDropzone: boolean;
}

const FormResidencyProofUploadLayout: FC<FormResidencyProofUploadLayoutProps> = ({
  children,
  isDisplaySecondDropzone,
}: FormResidencyProofUploadLayoutProps) => {
  const elements = ['description', 'dropzone1', 'dropzone2', 'checkPoint'];
  const [description, dropzone1, dropzone2, checkPoint] = getElementsFromKeys(
    children,
    elements,
  );

  return (
    <FlexBoxColumn>
      {description}
      <Margin top={60} />
      <FlexBoxRow>
        {dropzone1}
        <Margin left={51} />
        <Display isDisplay={isDisplaySecondDropzone}>{dropzone2}</Display>
      </FlexBoxRow>
      <Margin top={40} />
      {checkPoint}
    </FlexBoxColumn>
  );
};
export default FormResidencyProofUploadLayout;
