import React, { FC } from 'react';
import IndividualIconImage from 'assets/images/individual_black.svg';
import CorporateIconImage from 'assets/images/corporate_black.svg';

// Type
import { UserType } from 'types/User.type';

// Component
import Box from 'components/atoms/utils/layout/Box/Box';

export interface UserTypeBlackIconProps {
  type?: UserType;
  width?: string | number;
  height?: string | number;
}

const UserTypeBlackIcon: FC<UserTypeBlackIconProps> = ({
  type,
  width = '100%',
  height = '100%',
}: UserTypeBlackIconProps) => {
  let icon = '';

  switch (type) {
    case UserType.individual:
      icon = IndividualIconImage;
      break;
    case UserType.corporate:
      icon = CorporateIconImage;
      break;
    default:
      return <></>;
  }

  return (
    <Box width={width} height={height}>
      <img src={icon} alt="type" width="100%" height="100%" />
    </Box>
  );
};
export default UserTypeBlackIcon;
