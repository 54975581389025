import React, { FC } from 'react';
import DashboardTemplate from 'components/templates/common/DashboardTemplate';
import ProfileTemplate from 'components/templates/settings/ProfileTemplate';

const ProfilePage: FC = () => {
  return (
    <DashboardTemplate>
      <ProfileTemplate />
    </DashboardTemplate>
  );
};

export default ProfilePage;
