import { isEmpty } from 'lodash';

// Type
import { TypeOfErrors, TypeOfWatch } from 'types/typeof/ReactHookForm.type';

// ValueObject
import ProofOfResidency from 'value/verification/kycRequest/KycRequestContent/ProofOfResidency/ProofOfResidency';

export const isValid = (errors: TypeOfErrors, watch: TypeOfWatch) => {
  return (
    isEmpty(errors) &&
    !isEmpty(watch('country')) &&
    !isEmpty(watch('addressLine1')) &&
    !isEmpty(watch('city')) &&
    !isEmpty(watch('state')) &&
    !isEmpty(watch('postalCode')) &&
    !isEmpty(watch('documentType')) &&
    !isEmpty(watch('proofOfDocumentUrl1'))
  );
};

export const getDefaultValues = (proofOfResidency: ProofOfResidency) => {
  const proofOfResidencyProps = proofOfResidency.getProps();

  return {
    country: proofOfResidencyProps.country,
    addressLine1: proofOfResidencyProps.addressLine1,
    addressLine2: proofOfResidencyProps.addressLine2,
    city: proofOfResidencyProps.city,
    state: proofOfResidencyProps.state,
    postalCode: proofOfResidencyProps.postalCode,
    documentType: proofOfResidencyProps.document.type,
    proofOfDocumentUrl1: proofOfResidencyProps.document.documentUrl1,
    proofOfDocumentUrl2: proofOfResidencyProps.document.documentUrl2,
  };
};
