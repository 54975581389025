import { isEmpty } from 'lodash';

// Type
import { TypeOfErrors, TypeOfWatch } from 'types/typeof/ReactHookForm.type';

// ValueObject
import CorporateAddress from 'value/verification/kycRequest/KycRequestContent/CorporateAddress/CorporateAddress';

export const isValid = (errors: TypeOfErrors, watch: TypeOfWatch) => {
  return (
    isEmpty(errors) &&
    !isEmpty(watch('country')) &&
    !isEmpty(watch('addressLine1')) &&
    !isEmpty(watch('city')) &&
    !isEmpty(watch('state')) &&
    !isEmpty(watch('postalCode')) &&
    !isEmpty(watch('documentType')) &&
    !isEmpty(watch('proofOfDocumentUrl1'))
  );
};

export const getDefaultValues = (corporateAddress: CorporateAddress) => {
  const corporateAddressProps = corporateAddress.getProps();

  return {
    country: corporateAddressProps.country,
    addressLine1: corporateAddressProps.addressLine1,
    addressLine2: corporateAddressProps.addressLine2,
    city: corporateAddressProps.city,
    state: corporateAddressProps.state,
    postalCode: corporateAddressProps.postalCode,
    documentType: corporateAddressProps.document.type,
    proofOfDocumentUrl1: corporateAddressProps.document.documentUrl1,
  };
};
