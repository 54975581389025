// React
import { useState, useEffect, useCallback } from 'react';

// ErrorHandler
import ErrorHandler from 'utils/errors/ErrorHandler/ErrorHandler';

// Lib
import { checkInternetConnection } from 'utils/helpers/connection';

// Constant
import { INITIAL, IS_LOADING, SUCCESS } from 'config/constants/requestState';

// UseCase
import ProfileViewUseCase from 'useCases/settings/customer/ProfileViewUseCase';

// Repository
import KycRequestFirestore from 'repository/verification/kycRequest/KycRequestFirestore';

// DomainObject
import { useKycRequest } from 'domain/verification/kycRequest/KycRequest/KycRequest/useKycRequest';

// TabOption
import { useProfileTabTypeOption } from 'components/organisms/tabBar/TabBarProfile/useProfileTabTypeOption';
import UserAuthorized from 'domain/public/user/User/UserAuthorized/UserAuthorized/UserAuthorized';

export const useProfileViewUseCase = (user?: UserAuthorized) => {
  const [state, setState] = useState<string>(INITIAL);

  /**
   *
   *  DomainObject
   *
   */
  const { kycRequest, setKycRequest } = useKycRequest();

  // TabOption
  const customerDetailTabTypeOptionContext = useProfileTabTypeOption(user);

  /**
   *
   *  Repository
   *
   */
  const kycRequestRepository = new KycRequestFirestore();

  /**
   *
   *  UseCase
   *
   */
  const useCase = new ProfileViewUseCase(kycRequestRepository);

  /**
   *
   *  Methods
   *
   */

  const openProfile = useCallback(async () => {
    if (!user) return;

    setState(IS_LOADING);

    try {
      // Is ONLINE?
      checkInternetConnection();

      // Execute
      const outputs = await useCase.open(user.getId());

      if (outputs.kycRequest) {
        setKycRequest(outputs.kycRequest);
      }

      setState(SUCCESS);
    } catch (error) {
      const handler = new ErrorHandler(error, setState);
      handler.setErrorState();
    }
    // eslint-disable-next-line
  }, [user]);

  /**
   *
   *  UseEffect
   *
   */

  // UseCase.open
  useEffect(() => {
    let isMounted = true;

    if (user && isMounted) openProfile();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [user]);

  return {
    state,
    setState,
    kycRequest,
    customerDetailTabTypeOptionContext,
  };
};
