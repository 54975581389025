import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import { TitleStyle, DescriptionStyle } from 'components/templates/settings/RegisteredAddressEditPendingTemplate/style';

// Component
import NotificationPending from 'components/organisms/notification/NotificationPending';
import HeaderRegisteredAddressEditPending from 'components/organisms/header/HeaderContentTitle/settings/HeaderRegisteredAddressEditPending';
import RegisteredAddressEditPendingTemplateLayout from 'components/templates/settings/RegisteredAddressEditPendingTemplate/RegisteredAddressEditPendingTemplateLayout';

const TEMPLATE_ID = `templates.userSettings.RegisteredAddressEditPendingTemplate`;

const RegisteredAddressEditPendingTemplate: FC = () => {
  const { t } = useTranslation();

  return (
    <RegisteredAddressEditPendingTemplateLayout>
      <HeaderRegisteredAddressEditPending key="header" />
      <NotificationPending
        key="notification"
        title={t(`${TEMPLATE_ID}.notification.title`)}
        titleStyle={TitleStyle}
        descriptionStyle={DescriptionStyle}
        description={t(
          `${TEMPLATE_ID}.notification.description`,
        )}
      />
    </RegisteredAddressEditPendingTemplateLayout>
  );
};

export default RegisteredAddressEditPendingTemplate;
