import React, { FC } from 'react';

// Component
import { PageTitle } from 'components/atoms/headLine/HeadLine';
import { TextSecondary, TextThemeProps } from 'components/atoms/text2/Text2';
import LineBreakReplaced from 'components/atoms/text2/LineBreakReplaced';
import IconPending from 'components/atoms/icon/IconPending';
import NotificationPendingLayout from './NotificationPendingLayout';

export interface NotificationPendingProps {
  title: string;
  titleStyle: { color: string };
  description: string;
  descriptionStyle: TextThemeProps;
}

const NotificationPending: FC<NotificationPendingProps> = ({
  title,
  titleStyle,
  description,
  descriptionStyle,
}: NotificationPendingProps) => {
  return (
    <NotificationPendingLayout>
      <IconPending key="icon" width={73} height={73} />
      <PageTitle key="title" theme={titleStyle}>
        {title}
      </PageTitle>
      <TextSecondary key="description" theme={descriptionStyle}>
        <LineBreakReplaced text={description} />
      </TextSecondary>
    </NotificationPendingLayout>
  );
};

export default NotificationPending;
