import React, { FC } from 'react';
import { getElementsFromKeys } from 'utils/helpers/reactHelper';

// LayoutComponent
import { FlexBoxColumn } from 'components/atoms/utils/layout/Box/FlexBox';
import Margin from 'components/atoms/utils/layout/Margin';

export interface ProfilePersonalInformationTemplateLayoutProps {
  children: React.ReactElement[];
}

const ProfilePersonalInformationTemplateLayout: FC<ProfilePersonalInformationTemplateLayoutProps> = ({
  children,
}: ProfilePersonalInformationTemplateLayoutProps) => {
  const elements = [
    'personalInformation',
    'residentialInformation',
    'passport',
    'residentialProof',
  ];
  const [
    personalInformation,
    residentialInformation,
    passport,
    residentialProof,
  ] = getElementsFromKeys(children, elements);

  return (
    <FlexBoxColumn>
      {personalInformation}
      <Margin top={80} />
      {residentialInformation}
      <Margin top={80} />
      {passport}
      <Margin top={80} />
      {residentialProof}
    </FlexBoxColumn>
  );
};

export default ProfilePersonalInformationTemplateLayout;
