import React, { FC } from 'react';
import { FaSearchPlus } from 'react-icons/fa';
import { Container } from './style';

export interface MagnifyGlassIconProps {
  size: string;
  iconColor: string;
  backgroundColor: string;
  onClick: () => void;
}

const MagnifyGlassIcon: FC<MagnifyGlassIconProps> = ({
  size,
  iconColor,
  backgroundColor,
  onClick,
}: MagnifyGlassIconProps) => {
  return (
    <Container style={{ backgroundColor }} onClick={() => onClick()}>
      <FaSearchPlus color={iconColor} size={size} />
    </Container>
  );
};
export default MagnifyGlassIcon;
