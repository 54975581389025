import React, { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import KycRequestContext from 'domain/verification/kycRequest/KycRequest/KycRequest/KycRequestContext';
import RegisteredAddressResidentialEditRequestContext from 'domain/verification/registeredAddressEditRequest/RegisteredAddressResidentialEditRequest/RegisteredAddressResidentialEditRequestContext';

// Type
import { UserType } from 'types/User.type';

// Component
import { PageTitle } from 'components/atoms/headLine/HeadLine';
import FormProofOfResidency from 'components/organisms/form/settings/FormRegisteredAddressEditRequestAdd/FormProofOfResidency';
import RegisteredIndividualAddressEditTemplateLayout from './RegisteredIndividualAddressEditTemplateLayout';

const TEMPLATE_ID = `templates.userSettings.RegisteredIndividualAddressEditTemplate`;

const RegisteredIndividualAddressEditTemplate: FC = () => {
  const { t } = useTranslation();
  const { kycRequest } = useContext(KycRequestContext);
  const { registeredAddressResidentialEditRequest } = useContext(
    RegisteredAddressResidentialEditRequestContext,
  );

  const proofOfResidency = useMemo(() => {
    if (typeof registeredAddressResidentialEditRequest !== 'undefined') {
      return registeredAddressResidentialEditRequest.getProofOfResidency();
    }
    return kycRequest?.getProofOfResidency();
  }, [registeredAddressResidentialEditRequest, kycRequest]);

  return (
    <RegisteredIndividualAddressEditTemplateLayout>
      <PageTitle key="title">{t(`${TEMPLATE_ID}.title`)}</PageTitle>
      <FormProofOfResidency
        key="proofOfAddress"
        proofOfResidency={proofOfResidency}
        isUnderReview={!!registeredAddressResidentialEditRequest}
        userType={UserType.individual}
      />
    </RegisteredIndividualAddressEditTemplateLayout>
  );
};
export default RegisteredIndividualAddressEditTemplate;
