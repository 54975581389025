// Style
import { LabeledTextBoxStyle, LabeledTextStyle, LabelTextStyle } from './style';

export const commonPropsLabeledTextBox = {
  alignItems: 'center' as const,
  theme: LabeledTextBoxStyle,
  height: 80,
};

export const commonPropsLabeledText = {
  direction: 'row' as 'row' | 'column',
  marginBetween: 0,
  theme: LabeledTextStyle,
  labelWidth: 220,
};

export const commonPropsImage = {
  layout: {
    image: {
      width: 475,
      height: 475,
    },
  },
};

export const commonPropsLabeledImage = {
  layout: {
    ...commonPropsImage.layout,
    marginBetween: 30,
  },
  theme: {
    label: LabelTextStyle,
  },
};

export const HALF_WIDTH_PROPERTY_LABELED_TEXT = 500;
