import React, { FC, useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import CorporateAddressFileContext from 'enhancers/fileHandler/verification/kycRequest/CorporateAddressFileProvider/CorporateAddressFileContext';

// Cloud Storage
import { useStorageImage } from 'infrastructure/firebase/cloudStorage/useStorageImage';

// Type
import { FileType } from 'types/File.type';

// Style
import { DescriptionStyle } from 'components/organisms/form/settings/FormRegisteredAddressEditRequestAdd/FormCorporateAddress/FormCorporateAddressProofUpload/style';

// Component
import { TextSecondary } from 'components/atoms/text2/Text2';
import CheckPointList from 'components/organisms/list/CheckPointList';
import DropzoneArea from 'components/molecules/dropzone2/DropzoneArea';
import KycFormContainer from 'components/organisms/form/common/KycFormContainer';
import FormCorporateAddressProofUploadLayout from 'components/organisms/form/settings/FormRegisteredAddressEditRequestAdd/FormCorporateAddress/FormCorporateAddressProofUpload/FormCorporateAddressProofUploadLayout';

// ValueObject
import CorporateAddress from 'value/verification/kycRequest/KycRequestContent/CorporateAddress/CorporateAddress';

const FORM_ID = `organisms.form.settings.FormCorporateAddress.residencyProofUpload`;

export interface FormCorporateAddressProofUploadProps {
  corporateAddress?: CorporateAddress;
}

const FormCorporateAddressProofUpload: FC<FormCorporateAddressProofUploadProps> = ({
  corporateAddress,
}: FormCorporateAddressProofUploadProps) => {
  const { t } = useTranslation();

  // Form
  const { setValue } = useFormContext();

  // FileHandler
  const { proofOfDocument1, setProofOfDocument1 } = useContext(
    CorporateAddressFileContext,
  );

  // Storage
  const proofOfDocumentStorageUrl1 = useStorageImage(
    corporateAddress?.getProps().document.documentUrl1,
  ).storageUrl;

  const proofOfDocumentUrl1 = useMemo(() => {
    if (proofOfDocumentStorageUrl1) return proofOfDocumentStorageUrl1;
    return proofOfDocument1 && URL.createObjectURL(proofOfDocument1);
  }, [proofOfDocument1, proofOfDocumentStorageUrl1]);

  /* *
   *
   *  UseEffect
   *
   * */

  // Set corporateRegistryUrl when File is uploaded
  useEffect(() => {
    if (proofOfDocumentUrl1) {
      setValue('proofOfDocumentUrl1', proofOfDocumentUrl1);
    }
    // eslint-disable-next-line
  }, [proofOfDocumentUrl1]);

  return (
    <KycFormContainer order={3} formId={FORM_ID}>
      <FormCorporateAddressProofUploadLayout>
        <TextSecondary key="description" theme={DescriptionStyle}>
          {t(`${FORM_ID}.description`)}
        </TextSecondary>
        <DropzoneArea
          key="dropzone"
          name="proofOfDocument1"
          acceptFileTypes={[FileType.png, FileType.jpeg]}
          defaultFilePath={proofOfDocumentUrl1 || ''}
          maxFileSize={2}
          setFile={setProofOfDocument1}
          width={667}
        />
        <CheckPointList
          key="checkPoint"
          title={t(`organisms.CheckPointList.title`)}
          checkPoints={[
            t(`organisms.CheckPointList.text.imageIsComplete`),
            t(`organisms.CheckPointList.text.clear`),
            t(`organisms.CheckPointList.text.issueDate`),
            t(`organisms.CheckPointList.text.documentIsIssuedWithin90days`),
          ]}
        />
      </FormCorporateAddressProofUploadLayout>
    </KycFormContainer>
  );
};

export default FormCorporateAddressProofUpload;
