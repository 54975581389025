import React from 'react';

// Domain
import RegisteredAddressCorporateEditRequest from './RegisteredAddressCorporateEditRequest';

export interface RegisteredAddressCorporateEditRequestContextProps {
  registeredAddressCorporateEditRequest?: RegisteredAddressCorporateEditRequest;
}

const RegisteredAddressCorporateEditRequestContext = React.createContext<
  RegisteredAddressCorporateEditRequestContextProps
>({
  registeredAddressCorporateEditRequest: undefined,
});

export default RegisteredAddressCorporateEditRequestContext;
